import React from 'react';
import styles from './GearSelector.module.scss';

interface GearSelectorProps {
  selectedNumber: number | null;
  numberOfGears: number;
}

/**
 * GearSelector component displays a row of gear numbers (1-6)
 * with visual feedback for the selected gear.
 */
const GearSelector: React.FC<GearSelectorProps> = ({
  selectedNumber,
  numberOfGears
}) => {
  /**
   * Generates an array of gear numbers (1-6)
   * @returns {number[]} Array of gear numbers
   */
  const getGearNumbers = (): number[] => {
    return Array.from({ length: numberOfGears }, (_, i) => i + 1);
  };

  return (
    <div className={styles.container}>
      {getGearNumbers().map((number) => {
        const isSelected = selectedNumber === number;
        
        return (
          <div 
            key={number}
            className={`${styles.gearButton} ${isSelected ? styles.selected : ''}`}
            aria-selected={isSelected}
          >
            <span className={`${styles.gearNumber} ${isSelected ? styles.selected : ''}`}>
              {number}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default GearSelector;
