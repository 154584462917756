import VideoPlayerRightWindow from "../../pages/RightWindow/VideoPlayerRightWindow/VideoPlayerRightWindow"
import OrderSummary from "../OrderSummary/OrderSummary"
import { useRightWindowStore } from "../../pages/RightWindow/RightWindowStore";

const CreatePoRightWindow = ({orderSummaryProps}:any) => {
  const {showVideo} = useRightWindowStore();
  return (
    <>
      <OrderSummary {...orderSummaryProps} />
      {showVideo && <VideoPlayerRightWindow />}
    </>
  )
}

export default CreatePoRightWindow
