import React, { useEffect, useState } from 'react';
import PricingBracket from './PricingBracket';
import styles from './OrderSummary.module.scss';
import clsx from 'clsx';
import { Fade, Tooltip } from '@mui/material';
import { ReactComponent as DropdownIcon } from '../../assets/New-images/StateIconDropDpown.svg';
import { formatToTwoDecimalPlaces, purchaseOrder, useGlobalStore } from '@bryzos/giss-ui-library';
import { CustomMenu } from 'src/renderer2/pages/buyer/CustomMenu';
import { useRightWindowStore } from 'src/renderer2/pages/RightWindow/RightWindowStore';

interface WeightRange {
  min: number;
  max: number;
  gear: number;
}
interface OrderSummaryProps {
  numberOfBrackets?: number;
  bracketDivider?: number;
  minWeight?: number;
  animationTime?: number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ numberOfBrackets=12, bracketDivider=10, animationTime=1000}) => {
  const [weight, setWeight] = useState(0);
  const { setCreatePoData } = useGlobalStore();
  const [totalNumberOfBarsFilled, setTotalNumberOfBarsFilled] = useState(0);
  const [weightRanges, setWeightRanges] = useState<any[]>([]);
  const [minWeight, setMinWeight] = useState(500);
  const { props } = useRightWindowStore();
  const {watch, control, paymentMethods, setValue, getValues, navigateToSettings, saveUserActivity, disablePlaceOrderButton, onSubmit, pricingBrackets} = props;
  const [numberOfGears, setNumberOfGears] = useState(6);
  useEffect(() => {
    if (pricingBrackets.length > 0) {
      const transformedRanges = (pricingBrackets).map((range:any, index: number) => ({
        min: Number(range.min_weight),
        max: Number(range.max_weight),
        gear: index + 1
      }));
      setMinWeight(transformedRanges[0]?.min);
      setWeightRanges(transformedRanges);
      setNumberOfGears(transformedRanges.length);
    }
  }, [pricingBrackets]);


  const calculateBracket = (weightRange: WeightRange, weight: number): void => {
    const totalNumberOfBars = numberOfBrackets * bracketDivider;
    const perBar = (weightRange.max - weightRange.min + 1) / totalNumberOfBars;
    const barToBeFilled = Math.ceil((weight - weightRange.min + 1) / perBar);
    let totalBars = (weightRange.gear - 1) * numberOfBrackets * bracketDivider + barToBeFilled;
    if(totalBars <= 0){
      totalBars = 0;
    }
    if(totalBars >= (numberOfBrackets * bracketDivider * numberOfGears))
      totalBars = numberOfBrackets * bracketDivider * numberOfGears;
    setTotalNumberOfBarsFilled(totalBars);
  };

  const calculateGearPosition = (weight: number): void => {
    if(weightRanges.length > 0 &&  weight >= weightRanges[0]?.min) {
      const _weightRange = weightRanges?.find(range => weight >= range.min && weight <= range.max) ?? weightRanges[numberOfGears - 1];
      calculateBracket(_weightRange,weight);
    }
    else {
      setTotalNumberOfBarsFilled(0);
    }
  };
  useEffect(() => {
    const weight = Math.floor(watch('totalWeight'));
    setWeight(weight);
    calculateGearPosition(weight);
  }, [watch('totalWeight')]);

  // Generate bracket values with increasing increments

  const handlePlaceOrder = () => {
    onSubmit(watch());
    // const button = document.getElementById('place-order-btn');
    // if (button) {
    //   button.classList.add(styles.scale);
    //   setTimeout(() => {
    //     button.classList.remove(styles.scale);
    //     // Show success message or navigate to confirmation page
    //   }, 150);
    // }
  };

  return (
    <div className={clsx(styles.container,'orderSummaryContainer')}>
      <div className='bgEllips'></div>
      <div className='bgEllips1'></div>
      <div className={styles.fadeIn}>
        <PricingBracket 
          currentWeight={weight} 
          minWeight={minWeight} 
          numberOfBrackets={numberOfBrackets}
          bracketDivider={bracketDivider}
          numberOfGears={numberOfGears}
          totalNumberOfBarsFilled={totalNumberOfBarsFilled}
          animationTime={animationTime}
        />
        
        {/* Order Summary */}
        <div className={styles.slideUp} style={{ animationDelay: '100ms' }}>
          <div className={styles.summarySection}>
            <div className={styles.summaryRow}>
              <div className={styles.summaryRowLbl}>Material Total</div>
              <div className={styles.summaryRowNum}>$ {formatToTwoDecimalPlaces(watch(`price`))}</div>
            </div>
            <div className={`${styles.summaryRow} ${styles.muted}`}>
              <div className={styles.summaryRowLbl}>Sales Tax</div>
              <div className={styles.summaryRowNum}>$ {formatToTwoDecimalPlaces(watch(`sales_tax`))}</div>
            </div>
            <div className={`${styles.summaryRow} ${styles.muted}`}>
              <div className={styles.summaryRowLbl}>Deposit</div>
              <div className={styles.summaryRowNum}>$ {formatToTwoDecimalPlaces(watch(`depositAmount`))}</div>
            </div>
            <div className={`${styles.summaryRow} ${styles.muted}`}>
              <div className={styles.summaryRowLbl}>Subscription</div>
              <div className={styles.summaryRowNum}>$ {formatToTwoDecimalPlaces(watch(`subscriptionAmount`))}</div>
            </div>
          </div>
          <div className={clsx(styles.summaryRow,styles.totalPurchase)}>
            <div className={styles.totalPurchaseLbl}>Total Purchase</div>
            <div className={styles.totalPurchaseNum}>$ {formatToTwoDecimalPlaces(watch(`totalPurchase`))}</div>
          </div>
          
          <div className={styles.disclaimer}>
            If you make an error, you will have an opportunity<br />
            to change or cancel your order via "Disputes."
          </div>

          <Tooltip
            title={(watch('totalPurchase') > watch('availableCreditLimit') && watch('payment_method') === purchaseOrder.paymentMethodBNPL) ? "Not enough available credit to complete this purchase" : ""}
            arrow
            placement="bottom"
            classes={{
              tooltip: styles.creditLimitTooltip,
              arrow: styles.tooltipArrow
            }}
          >
            <div>
              <CustomMenu
                control={control}
                name="payment_method"
                placeholder="Method of Payment"
                className={clsx((watch('totalPurchase') > watch('availableCreditLimit') && watch('payment_method') === purchaseOrder.paymentMethodBNPL) ? 'netTermsDropdown net30TermError' : 'netTermsDropdown')}
                MenuProps={{
                  TransitionComponent: Fade,
                  classes: {
                    paper: `${styles.dropdownList} ${styles.menuSlideUp}`,
                    list: styles.muiMenuList,
                  },
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  id: `payment-method-menu`
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Tab' && document.activeElement?.closest(`#payment-method-menu`)) { // Update selector to match new ID
                    const value = document.activeElement?.getAttribute('data-value');
                    if(value === purchaseOrder.paymentMethodBNPL){
                      setValue('payment_method', purchaseOrder.paymentMethodBNPL);
                    }else{
                      setValue('payment_method', purchaseOrder.paymentMethodACH);
                    }
                    saveUserActivity();
                  }
                }}
                items={paymentMethods}
                IconComponent={DropdownIcon}
                onChange={() => saveUserActivity()}
                disableAutoFocusItem={watch('payment_method') ? false : true}
                renderValue={(value: string) => {
                  const selectedItem = paymentMethods.find((item: {value: string; title: string}) => item.value === value);
                  const title = selectedItem?.title || value;

                  const hasCreditLimit = Boolean(watch('availableCreditLimit'));
                  const hasRequestedLimit = Boolean(watch('requestedCreditLimit')); 
                  const needsSetup = !hasCreditLimit && !hasRequestedLimit;
                  const totalPurchase = watch('totalPurchase');
                  const exceedsCreditLimit = totalPurchase > (watch('availableCreditLimit') || 0);

                  const handleMethodClick = () => {
                    if (needsSetup && value === purchaseOrder.paymentMethodBNPL) {
                      setValue('payment_method', purchaseOrder.paymentMethodBNPL);
                      setCreatePoData(getValues());
                      navigateToSettings();
                    }
                  };

                  const getPaymentMethodText = () => {
                    if (!title) return 'Method of Payment';

                    if (value === purchaseOrder.paymentMethodBNPL) {
                      if (hasRequestedLimit) return `${title} (Pending)`;
                      if (needsSetup) return `${title} (Setup)`;
                    }
                    return title;
                  };

                  return (
                    <div className={clsx(
                      styles.dropdownDataMain,
                      exceedsCreditLimit && value === purchaseOrder.paymentMethodBNPL && styles.exceedsCreditLimit
                    )}>
                      <span
                        className={clsx(styles.paymentMethod,'paymentMethod')}
                        onClick={handleMethodClick}
                      >
                        {getPaymentMethodText()}
                      </span>

                      {((hasCreditLimit || hasRequestedLimit) && value === purchaseOrder.paymentMethodBNPL) && (
                        <span className={clsx(styles.paymentValue,'paymentValue')}>
                          $ {formatToTwoDecimalPlaces(
                            hasCreditLimit
                              ? watch('availableCreditLimit')
                              : watch('requestedCreditLimit')
                          )}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </Tooltip>
          
          {/* Place Order Button */}
          <button 
            id="place-order-btn"
            className={`${styles.orderButton} ${styles.slideUp}`}
            style={{ animationDelay: '500ms' }}
            disabled={disablePlaceOrderButton}
            onClick={handlePlaceOrder}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
