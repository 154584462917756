
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import styles from './renderOdometer.module.scss';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

type OdometerProps = {
    previous: string,
    current: string,
    maxUnits?: number,
    maxPrecision?: number
    isDecimal?: boolean
};

const RenderOdometers: React.FC<OdometerProps> = ({ previous, current, maxUnits= 7, maxPrecision= 4, isDecimal= true }) => {
    const[odometers, setOdometers] = useState<JSX.Element[]>();

    const renderAnimationElement = (_odometers:JSX.Element[], previous:string, current:string, maxCount: number, isDecimal:boolean) => {
        let prevDigits = previous.split('');
        if (previous.length < maxCount) {
            const diffLen = maxCount - previous.length;
            const fillArray =  new Array(diffLen).fill('x');
            if(isDecimal)
            prevDigits = prevDigits.concat(fillArray);
            else
            prevDigits = fillArray.concat(prevDigits);
        }
        let currentDigits = current.split('');
        if (current.length < maxCount) {
            const diffLen = maxCount - current.length;
            const fillArray =  new Array(diffLen).fill('x');
            if(isDecimal)
            currentDigits = currentDigits.concat(fillArray);
            else
            currentDigits = fillArray.concat(currentDigits);
        }

        for (let index = 0; index < maxCount; index++) {
            const isDigitChanged = prevDigits[index] !== currentDigits[index];
            const digit = currentDigits[index];
            if (digit === '.' || digit === ',') 
                _odometers.push(<span key={_odometers.length}>{digit}</span>); // Render decimal point as static, no odometer needed
            else
            _odometers.push(
                <div key={_odometers.length} className={clsx(digit === 'x' && styles.displayNone)}> 
                    <Odometer
                        value={digit === 'x' ? 0 : Number(digit)}
                        format='d'
                        duration={isDigitChanged ? 1000 : 0}
                    />
                </div>
            );
        }
    }
    
    useEffect(()=>{
        if(previous && current){
            const previousValueSplitDecimal = previous.split('.');
            const currentValueSplitDecimal = current.split('.');
            const _odometers: JSX.Element[] = [];
            renderAnimationElement(_odometers, previousValueSplitDecimal[0] + '.', currentValueSplitDecimal[0] + '.', maxUnits, false);
            if(isDecimal)
            renderAnimationElement(_odometers, previousValueSplitDecimal[1], currentValueSplitDecimal[1], maxPrecision, true);
            setOdometers(_odometers);
        }
    },[previous,current])

    // console.log('previous',previous, current)
    // useEffect(()=>{console.log("RENDERED")},[])

    return <>{odometers}</>
}

export default RenderOdometers;