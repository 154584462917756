import { AuthError, reactQueryKeys, routes } from '../common';
import { useEventListener } from '@mantine/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from 'react';
import { OTPInputComponent } from '../component/OTPInput';
import { emojiRemoverRegex, useGlobalStore, getChannelWindow, useAuthStore } from '@bryzos/giss-ui-library';
import { useNavigate } from 'react-router';
import useOnSubmitLogin from '../hooks/useOnSubmitLogin';
import { ReactComponent as ShowPassIcon } from '../assets/images/show-pass.svg';
import { ReactComponent as HidePassIcon } from '../assets/images/hide-pass.svg';
import InputWrapper from '../component/InputWrapper';
import CustomTextField from '../component/CustomTextField';
import CustomPasswordField from '../component/CustomPasswordField';
import { ReactComponent as BLogo } from '../assets/New-images/New-Bryzos-Logo.svg';
import { ReactComponent as SubmitIcon } from '../assets/New-images/Login-Icon.svg';
import { ReactComponent as SubmitIconActive } from '../assets/New-images/Login-Icon-Active.svg';
import clsx from 'clsx';
import { navigatePage } from '../helper';
import { useMenuStore } from '../component/Menu/MenuStore';
import NewBryzosLogo from "../assets/New-images/New-Logo.png";

const Login = () => {
    const channelWindow =  getChannelWindow();
    const navigate = useNavigate();
    const signupUser = useGlobalStore(state => state.signupUser);
    const setIsManualLogin = useGlobalStore(state => state.setIsManualLogin);
    const {loginError, setLoginError, submitLogin} = useAuthStore()
    const {resetMenuStore} = useMenuStore()
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const { register, watch, handleSubmit, setError, clearErrors, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(
            yup.object({
                email: yup.string().required('Email is required'),
                password: yup.string().trim().min(6).required()
            }).required()
        ),
        mode: 'onSubmit',
    });
    const onSubmitLogin =  useOnSubmitLogin(setLoginError);
    

    const password = watch('password');

    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const handleFocus = () => {
        setShowPasswordInput(true);
    };

    const handleEmailFocus = () => {
        setShowPasswordInput(false);
    };
    useEffect(()=>{
        resetMenuStore()
        return () => {
            setLoginError()
        }
    }, [])

    useEffect(() => {
        if (errors.root?.serverError)
            clearErrors('root.serverError')
    }, [watch('email'), watch('password')])

    useEffect(()=>{
        if(signupUser){
            submitLogin(signupUser)
        }
    },[signupUser])


    const keydownRef = useEventListener('keydown', function (event) {
        if (event.key === 'Enter') {
            handleOnClickLogin()
        }
    }
    );
    const handleOnClickLogin = () => {
        console.log("Lognin button clicked")
        setIsManualLogin(true)
        handleSubmit(submitLogin)()
    } 

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
      };
    return (
       
        <div className={'loginWrapper'} ref={keydownRef}>
            <span className='logoLogin'><img className="bryzosLogo" src={NewBryzosLogo} alt="Logo" /></span>
            <span className='dragArea'></span>
            <span className='emailInput'>
            <InputWrapper>
                           <CustomTextField type='email' register={register("email")}  autoFocus onFocus={handleEmailFocus}
                            onChange={(e) => setLoginError()}
                            errorInput={loginError === AuthError.EmailNotFound && 'Email not Found'}
                            placeholder='Login Email'
                            setError={setEmailError}
                            className={'loginInput'}
                            />
            </InputWrapper>

                {(loginError === AuthError.EmailNotFound) &&
                    <button className={`joinBtn`} onClick={()=>{navigatePage(location.pathname, {path:routes.onboardingDetails})}} type="button">JOIN BRYZOS</button>
                    // :
                    // <p className='errorText errorFont'>{errors.root?.serverError?.message}</p>
                } 

            </span>
            <span className='togglePassWrapper'>
            <InputWrapper>
                            <CustomPasswordField register={register("password")} placeholder='Password'
                                onChange={(e) => {
                                    setLoginError();
                                    register("password").onChange(e);
                                }} 
                                errorInput={loginError === AuthError.PasswordMismatch && 'Password Miss match'}
                                />
            </InputWrapper>
                 {(channelWindow?.getLoginCredential && loginError === AuthError.PasswordMismatch) && 
                        <button onClick={()=>navigate(routes.forgotPassword)} className='forgot-password-btn'>Reset Password</button>
                } 

            </span>
            <span className='submitArrow'><button onClick={handleOnClickLogin} disabled={!isValid || !emailError}>{!isValid || !emailError ? <SubmitIcon/> : <SubmitIconActive/> }</button></span>
            <div className='bgEllips'></div>
            <div className='bgEllips1'></div>
        </div>
    );
};

export default Login;