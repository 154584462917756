// @ts-nocheck
import React, { memo,  useEffect, useState } from 'react';
import styles from './orderConfirmation.module.scss'
import { ReactComponent as QuestionIcon } from '../../assets/images/question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { commomKeys, fileType, orderConfirmationConst, prefixUrl, routes } from '../../common';
import { useLocation, useNavigate } from 'react-router';
import { Dialog, Fade, Tooltip } from '@mui/material';
import { BUploadYourSOTooltip } from '../../tooltip';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { downloadFiles, navigatePage } from '../../helper';
import DialogPopup from '../../component/DialogPopup/DialogPopup';
import { useGlobalStore } from '@bryzos/giss-ui-library';
import SearchHeader from '../SearchHeader';
import { ReactComponent as ThumbsUpIcon } from '../../assets/New-images/ThumbsUp.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/New-images/ThumbsDown.svg';
import { ReactComponent as ThumbsUpIconHover } from '../../assets/New-images/thumbs-up-hover.svg';
import { ReactComponent as ThumbsDownIconHover } from '../../assets/New-images/thumbs-down-hover.svg';
import { ReactComponent as EmailIcon } from '../../assets/New-images/EmailIcon.svg';
import useDialogStore from 'src/renderer2/component/DialogPopup/DialogStore';

const OrderConfirmationBuyer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {setShowLoader, userData} = useGlobalStore();
    const poData = location.state;
    const selectedOptionPayment = poData.selectedOptionPayment;
    const PAYMENT_TYPE_ACH_CREDIT= "ach_credit";
    const [rating, setRating] = useState(0);
    const [disableUploadYourPo, setDisableUploadYourPo] = useState(false);
    const [showDialogConfirmationPopup, setShowDialogConfirmationPopup] = useState(false);
    const [uploadPoProgress, setUploadPoProgress] = useState(null);
    const [cancelOrderDisableBtn, setCancelOrderDisableBtn] = useState(false);
    const [showDialogUploadPoPopup, setShowDialogUploadPoPopup] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogBtnTitle, setDialogBtnTitle] = useState('');
    const [dialogType, setDialogType] = useState('');
    const [activeRating, setActiveRating] = useState('');
    const { showCommonDialog, resetDialogStore } = useDialogStore();

    useEffect(()=>{
        setShowLoader(false);
    },[])

    const handleCheckboxClick = (value) => {
        setRating(value);
        setActiveRating(value === 5 ? 'thumbs-up' : 'thumbs-down');
        const poRatingPayload = {
            "data": {
                "po_number": poData.poNumber,
                "rating": value.toString()
            }
        }
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/purchaseOrderRatings', poRatingPayload)
            .then(res => {})
            .catch(err => console.error(err))
    };

    useEffect(() => {
        function goToMainPage(event) {
            if (event.key === 'Enter') {
                navigate(routes.homePage);

            }
        }

        document.addEventListener('keydown', goToMainPage)
        return (() => {
            document.removeEventListener('keydown', goToMainPage)
        })
    }, [navigate])
    const uploadPoFile = async (event) => {
        const file = event.target.files[0];

        if (event.target.files.length !== 0) {
            setDisableUploadYourPo(true);
            setUploadPoProgress(true);
            let index = file.name.length - 1;
            for (; index >= 0; index--) {
                if (file.name.charAt(index) === '.') {
                    break;
                }
            }
            const ext = file.name.substring(index + 1, file.name.length);

            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + userData.data.id + '/' + prefixUrl.buyerPo + '-' + uuidv4() + '.' + ext;
            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_SETTINGS_PO_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300

                }
            }
            let buyerPoUrl = 'https://' + payload.data.bucket_name + ".s3.amazonaws.com/" + payload.data.object_key;

            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    axios.put(signedUrl, file)
                        .then(response => {
                            if (response.status === 200) {
                                const payload = {
                                    "data": {
                                        "po_number": poData.poNumber,
                                        "upload_po_s3_urls": [
                                            buyerPoUrl
                                        ]
                                    }
                                }
                                axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/uploadPoS3Url', payload)
                                    .then(response => {
                                        setShowDialogUploadPoPopup(true);
                                        setDialogTitle(commomKeys.uploadSuccessful)
                                        setDialogContent(orderConfirmationConst.uploadPoDialogContent)
                                        setDialogBtnTitle(commomKeys.successBtnTitle)
                                        setDialogType(commomKeys.actionStatus.success)
                                        setUploadPoProgress(false);
                                    })
                                    .catch(err => {
                                        setDisableUploadYourPo(false);
                                        setUploadPoProgress(null);
                                        setShowDialogUploadPoPopup(true);
                                        setDialogContent(commomKeys.errorContent)
                                        setDialogBtnTitle(commomKeys.errorBtnTitle)
                                        setDialogType(commomKeys.actionStatus.error)
                                        console.error(err)

                                    })

                            }
                        })
                        .catch(error => {
                            setDisableUploadYourPo(false);
                            setUploadPoProgress(null);
                            setShowDialogUploadPoPopup(true);
                            setDialogContent(commomKeys.errorContent)
                            setDialogBtnTitle(commomKeys.errorBtnTitle)
                            setDialogType(commomKeys.actionStatus.error)
                            console.error(error)
                        })
                })
                .catch(error => {
                    setDisableUploadYourPo(false);
                    setUploadPoProgress(null);
                    setShowDialogUploadPoPopup(true);
                    setDialogContent(commomKeys.errorContent)
                    setDialogBtnTitle(commomKeys.errorBtnTitle)
                    setDialogType(commomKeys.actionStatus.error)
                    console.error(error)
                })

        }
    }

    const cancelOrder = () => {
        setCancelOrderDisableBtn(true)
        const payload = {
            data: {
                po_number: poData.poNumber,
                type: orderConfirmationConst.buyerCancel
            }
        }
        axios.post(import.meta.env.VITE_API_ADMIN_SERVICE_NODE + '/cancel_order/user', payload)
            .then(res => {
                resetDialogStore();
                navigate(-1);
                setDisableUploadYourPo(false);
            })
            .catch(err => {setCancelOrderDisableBtn(false);console.error(err)});
    };

    
    const downloadCertificate = async()=>{
        try{
            setShowLoader(true)
            const res = await axios.get(import.meta.env.VITE_API_SERVICE + '/user/buyer/w9form')
            if(res.data.data){
                const url = res.data.data
                const fileName = url.split('/')
                await downloadFiles(url, fileName[fileName.length -1], fileType.pdf)
            }
        }catch(e) {
            setShowDialogUploadSoPopup(true);
            setDialogContent(commomKeys.errorContent)
            setDialogBtnTitle(commomKeys.errorBtnTitle)
            setDialogType(commomKeys.actionStatus.error)
        } finally {
            setShowLoader(false);
        }
    }


    return (
        <>
            <SearchHeader />
            <div className={styles.orderConfirmationContainer}>
                <div className={styles.orderConfirmationContent}>
                    <div className={styles.orderConfirmationPoDetails}>
                        <span className={styles.orderConfirmdText}>ORDER CONFIRMED</span>
                        <span className={styles.poNumberText}>
                            <span className={styles.poText}>PO#</span>
                            <span className={styles.jobText}>{poData.jobNumber}</span>
                            <span className={styles.poNumber}>({poData.poNumber})</span>
                        </span>
                    </div>
                    <div className={styles.purchaseRating}>
                        <span>
                            <p>Was that purchase easy,</p>
                            <p> or what? <span className={styles.GiveUsthumbsUp}> Give us a thumbs up!</span> </p>
                        </span>
                        <div className={styles.purchaseRatingBtn}>
                            <button onClick={() => handleCheckboxClick(5)}>
                                <span className={clsx(styles.thumbsImages, activeRating === 'thumbs-up'  && styles.thumbsImagesActive)}>
                                    <ThumbsUpIcon className={styles.img1} />
                                    <ThumbsUpIconHover className={styles.img2} />
                                </span>
                            </button>
                            <button onClick={() => handleCheckboxClick(0)}>
                                <span className={clsx(styles.thumbsImages, activeRating === 'thumbs-down' && styles.thumbsImagesActive)}>
                                    <ThumbsDownIcon className={styles.img1} />
                                    <ThumbsDownIconHover className={styles.img2} />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className={styles.purchaseEmailDiv}>
                        <EmailIcon className={styles.purchaseEmailIcon} />
                        <span className={styles.purchaseEmailTextDiv}>
                            <span className={styles.purchaseEmailText}>We’ve emailed the order confirmation to</span>
                            <span className={styles.emailIdInvoice}>{userData?.data?.email_id}</span>
                        </span>
                    </div>
                </div>
                <div className={styles.orderConfirmationDefaultText}>
                    This order is in the queue for supplier’s to claim for fulfillment. <br /> You will be able to chat directly with the supplier on this order.
                </div>
                <div className={styles.orderConfirmationButtons}>
                    <div className={styles.orderConfirmationButtonBg}>
                        <label>
                            <div className={clsx(styles.uploadYourPoDiv, (disableUploadYourPo) && styles.disabled)}>
                                {uploadPoProgress === true ?
                                    <span className={styles.orderConfirmationButtonText}>Uploading </span> :
                                    uploadPoProgress === false ?
                                        <span className={styles.orderConfirmationButtonText}>PO Uploaded </span> :
                                        <>
                                            <span className={styles.orderConfirmationButtonText}>Upload Your PO </span>
                                            <span className={styles.emailIdInter}>or, email to po@bryzos.com</span>
                                        </>
                                }
                            </div>
                            <input type='file' onChange={uploadPoFile}  disabled={disableUploadYourPo} />
                        </label>
                        <DialogPopup
                            dialogTitle={dialogTitle}
                            dialogContent={dialogContent}
                            dialogBtnTitle={dialogBtnTitle}
                            type={dialogType}
                            open={showDialogUploadPoPopup}
                            onClose={() => setShowDialogUploadPoPopup(false)}
                        />
                    </div>
                    <div className={styles.orderConfirmationButtonBg}>
                        <span className={styles.orderConfirmationButtonText}>Download Confirmation & <button onClick={downloadCertificate}>W-9</button></span>
                    </div>
                    <div disabled={cancelOrderDisableBtn} onClick={() => { showCommonDialog(null, 'Do you want to continue?', null, resetDialogStore, [{name: 'Yes', action: cancelOrder}, {name: 'No', action: resetDialogStore}]) }} className={clsx(styles.orderConfirmationButtonBg, styles.pointer)}>
                        <span className={styles.orderConfirmationButtonText} >Modify or Cancel Order</span>
                    </div>
                </div>
                <div onClick={() => { navigatePage(location.pathname, {path: routes.createPoPage}) }} className={clsx(styles.createAnotherPurchase, styles.pointer)}>
                    <span className={styles.poNumber}>Create Another Purchase</span>
                </div>
            </div>
        </>
    
    )
}

export default OrderConfirmationBuyer;
