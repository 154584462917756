import { Box, styled } from '@mui/material';
import styles from "./Menu.module.scss";
import { ReactComponent as PinIcon } from '../../assets/New-images/Side-Menu-Pin-42.svg';
import { ReactComponent as PinActiveIcon } from '../../assets/New-images/Side-Menu-Pin-42-Active.svg';
import { ReactComponent as HomeIcon } from '../../assets/New-images/Side-Menu-Home-42.svg';
import { ReactComponent as HomeActiveIcon } from '../../assets/New-images/Side-Menu-Home-42-Active.svg';
import { ReactComponent as NotificationIcon } from '../../assets/New-images/Side-Menu-Notification-42.svg';
import { ReactComponent as NotificationActiveIcon } from '../../assets/New-images/Side-Menu-Notification-42-Active.svg';
import { ReactComponent as QuestionIcon } from '../../assets/New-images/Side-Menu-Question-42.svg';
import { ReactComponent as QuestionActiveIcon } from '../../assets/New-images/Side-Menu-Question-42-Active.svg';
import { ReactComponent as ChatIcon } from '../../assets/New-images/Side-Menu-Chat-42.svg';
import { ReactComponent as ChatActiveIcon } from '../../assets/New-images/Side-Menu-Chat-42-Active.svg';
import { ReactComponent as VideoIcon } from '../../assets/New-images/Side-Menu-Video-42.svg';
import { ReactComponent as VideoActiveIcon } from '../../assets/New-images/Side-Menu-Video-42-Active.svg';
import { ReactComponent as PinIcons } from '../../assets/New-images/Side-Menu-Pin-36.svg';
import { ReactComponent as PinActiveIcons } from '../../assets/New-images/Side-Menu-Pin-36-Active.svg';
import { ReactComponent as HomeIcons } from '../../assets/New-images/Side-Menu-Home-36.svg';
import { ReactComponent as HomeActiveIcons } from '../../assets/New-images/Side-Menu-Home-36-Active.svg';
import { ReactComponent as NotificationIcons } from '../../assets/New-images/Side-Menu-Notification-36.svg';
import { ReactComponent as NotificationActiveIcons } from '../../assets/New-images/Side-Menu-Notification-36-Active.svg';
import { ReactComponent as QuestionIcons } from '../../assets/New-images/Side-Menu-Question-36.svg';
import { ReactComponent as QuestionActiveIcons } from '../../assets/New-images/Side-Menu-Question-36-Active.svg';
import { ReactComponent as ChatIcons } from '../../assets/New-images/Side-Menu-Chat-36.svg';
import { ReactComponent as ChatActiveIcons } from '../../assets/New-images/Side-Menu-Chat-36-Active.svg';
import { ReactComponent as VideoIcons } from '../../assets/New-images/Side-Menu-Video-36.svg';
import { ReactComponent as VideoActiveIcons } from '../../assets/New-images/Side-Menu-Video-36-Active.svg';
import { ReactComponent as SearchIcon } from '../../assets/New-images/Search.svg';
import clsx from "clsx";
import { useMenuStore } from "./MenuStore";
import { routes } from "src/renderer2/common";
import { getChannelWindow, useAuthStore, localStorageStickyItemKey } from "@bryzos/giss-ui-library";
import { navigatePage } from "src/renderer2/helper";
import { useState } from 'react';

// Create a styled component for the main content
const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    marginLeft: '240px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface MenuProps {
  routerContentRef: React.RefObject<HTMLDivElement>;
  isMenuOpen: boolean;
}

const menuItems = [
  {
    section: 'ESTIMATING',
    items: [
      { label: 'Price Search', route: routes.homePage },
      { label: 'Saved Searches', route: '' },
      { label: 'Shared Pricing', route: '' }
    ]
  },
  {
    section: 'PURCHASING',
    items: [
      { label: 'Create New PO (Blank)', route: routes.createPoPage },
      { label: 'Saved BOM', route: '' },
      { label: 'Upload New BOM', route: routes.bomUpload }
    ]
  },
  {
    section: 'ORDER MANAGEMENT',
    items: [
      { label: 'View PO History', route: '' },
      { label: 'Edit an Active Order', route: '' },
      { label: 'Create a Material Dispute', route: '' },
      { label: 'Request an Invoice Adjustment', route: '' }
    ]
  },
  {
    section: 'MY ACCOUNT',
    items: [
      { label: 'Profile Settings', route: routes.buyerSettingPage },
      { label: 'Subscription - Billing', route: '' }
    ]
  }
];

const Menu: React.FC<MenuProps> = ({ routerContentRef, isMenuOpen }) => {
  const { openMenu, setCloseWithoutAnimation, setOpenMenu } = useMenuStore();
  const {initiateLogout} = useAuthStore();
  const channelWindow = getChannelWindow();
  const [searchQuery, setSearchQuery] = useState(''); // Add this state
  const [isSticky, setIsSticky] = useState(false);

  const filteredMenuItems = menuItems.map(section => ({
    ...section,
    items: section.items.filter(item =>
      item.label.toLowerCase().includes(searchQuery.toLowerCase())
    )
  })).filter(section => section.items.length > 0);

  const toggleAlwaysOnTop = () => {
      if(channelWindow?.sticky){
          window.electron.sendSync({ channel: channelWindow.sticky, data: null });
      }
      sessionStorage.setItem(localStorageStickyItemKey, !isSticky ? localStorageStickyItemKey : '');
      setIsSticky(!isSticky);
  }

  const handleLogout = () => {
      setCloseWithoutAnimation(true);
      setOpenMenu(false);
      initiateLogout(false, false, true);
  }

  const ignoreMouseEvents = () => {
      if(!openMenu){
          setTimeout(() => {
              handleMouseLeave();
          }, 1000);
      }
  }

  const handleMouseEnter = () => {
      if(channelWindow?.ignoreMouseEvents && !openMenu){
          window.electron.send({ channel: channelWindow.ignoreMouseEvents, data:true})
      }
  }

  const handleMouseLeave = () => {
      if(channelWindow?.ignoreMouseEvents && !openMenu){
          window.electron.send({ channel: channelWindow.ignoreMouseEvents, data:false})
      }
  }

  const handleHomeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter' || e.key === ' '){
      e.preventDefault();
      handleHomePageClick()
    }
  }

  const handleHomePageClick = () => {
    navigatePage(location.pathname, {path:routes.homePage})
  }

  const handlePinKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter' || e.key === ' '){
      e.preventDefault();
      toggleAlwaysOnTop()
    }
  }

  return (
    <div className={clsx(styles.menuParentContainer)}
      onClick={ignoreMouseEvents}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {(location.pathname !== routes.loginPage && location.pathname !== routes.forgotPassword) && <div className={clsx(styles.slideInContainer, openMenu ? styles.slideIn : styles.slideOut)}>
      <div className={clsx('wrapperOverlay', styles.wrapperOverlay )}></div>
    <div className={clsx(styles.menuContainer, openMenu ? styles.openMenu : styles.closeMenu)} style={{ height: routerContentRef.current?.offsetHeight }}>
      {isMenuOpen && <div className={styles.sideMenu}>
        { location.pathname === routes.homePage ?
          <div className={styles.sideMenuRow1Tall}>
          <div className={styles.sideMenuHeader}>
              <div className={clsx(styles.sideMenuHeaderIcon,location.pathname === routes.homePage && styles.active)} onClick={handleHomePageClick} 
              onKeyDown={handleHomeKeyDown}
              tabIndex={1}>
                <span className={styles.iconDivImg1}><HomeIcon /></span>
                <span className={styles.iconDivImg2}><HomeActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={2}>
                <span className={styles.iconDivImg1}><NotificationIcon /></span>
                <span className={styles.iconDivImg2}><NotificationActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={3}>
                <span className={styles.iconDivImg1}><ChatIcon /></span>
                <span className={styles.iconDivImg2}><ChatActiveIcon /></span>
              </div>
              <div className={clsx(styles.sideMenuHeaderIcon, isSticky && styles.active)} onClick={() => toggleAlwaysOnTop()} onKeyDown={handlePinKeyDown} tabIndex={4}>
                <span className={styles.iconDivImg1}><PinIcon /></span>
                <span className={styles.iconDivImg2}><PinActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={5}>
                <span className={styles.iconDivImg1}><VideoIcon /></span>
                <span className={styles.iconDivImg2}><VideoActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={6}>
                <span className={styles.iconDivImg1}><QuestionIcon /></span>
                <span className={styles.iconDivImg2}><QuestionActiveIcon /></span>
              </div>
          </div>
          <div className={styles.searchBoxDiv}>
            <div className={styles.searchBox}>
                <SearchIcon />
                <input 
                  placeholder="Search Menu" 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  tabIndex={7}
                />
            </div>
          </div>
          <div className={styles.sideMenuBgEllips}></div>
          <div className={styles.sideMenuBgEllips1}></div>
        </div>
          
        :
        <div className={styles.sideMenuRow1Small}>
          <div className={styles.sideMenuHeader}>
              <div className={clsx(styles.sideMenuHeaderIcon,location.pathname === routes.homePage && styles.active)} onClick={handleHomePageClick} onKeyDown={handleHomeKeyDown} tabIndex={1}>
                <span className={styles.iconDivImg1}><HomeIcons /></span>
                <span className={styles.iconDivImg2}><HomeActiveIcons /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={2}>
                <span className={styles.iconDivImg1}><NotificationIcons /></span>
                <span className={styles.iconDivImg2}><NotificationActiveIcons /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={3}>
                <span className={styles.iconDivImg1}><ChatIcons /></span>
                <span className={styles.iconDivImg2}><ChatActiveIcons /></span>
              </div>
              <div className={clsx(styles.sideMenuHeaderIcon, isSticky && styles.active)} onClick={() => toggleAlwaysOnTop()} onKeyDown={handlePinKeyDown} tabIndex={4}>
                <span className={styles.iconDivImg1}><PinIcons /></span>
                <span className={styles.iconDivImg2}><PinActiveIcons /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={5}>
                <span className={styles.iconDivImg1}><VideoIcons /></span>
                <span className={styles.iconDivImg2}><VideoActiveIcons /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} tabIndex={6}>
                <span className={styles.iconDivImg1}><QuestionIcons /></span>
                <span className={styles.iconDivImg2}><QuestionActiveIcons /></span>
              </div>
          </div>
          <div className={styles.searchBoxDiv}>
            <div className={styles.searchBox}>
                <SearchIcon />
                <input 
                  placeholder="Search Menu" 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  tabIndex={7}
                />
            </div>
          </div>
          <div className={styles.sideMenuBgEllips}></div>
          <div className={styles.sideMenuBgEllips1}></div>
        </div>
        }
        <div className={styles.sideMenuRow2}>
          <span className={styles.freeTrialText}>FREE TRIAL EXPIRES IN 14 DAYS</span>
          <span className={styles.subscribeButton}><button tabIndex={8}>SUBSCRIBE</button></span>
          <span className={styles.learnMoreText}>LEARN MORE</span>
        </div>
        <div className={styles.sideMenuRow3}>
          <div className={styles.sideMenu3Scroller}>
            {filteredMenuItems.map((section, index) => (
              <div key={index} className={styles.sideMenuDiv}>
                <span className={clsx(styles.lineBackground, styles.firstLine)}>
                  {section.section}
                </span>
                {section.items.map((item, itemIndex) => (
                  <button
                    key={itemIndex}
                    className={clsx(
                      styles.lineBackground,
                      location.pathname === item.route && styles.lineBackgroundActive,
                      itemIndex === section.items.length - 1 && styles.lastLine
                    )}
                    onClick={() => item.route && navigatePage(location.pathname, { path: item.route })}
                    tabIndex={9}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.sideMenuRow4}>
            <button onClick={handleLogout} tabIndex={10}>Logout</button>
        </div>
        {<>
        {/* <div className={styles.sideMenuRow1Tall}>
          <div className={styles.sideMenuHeader}>
              <div className={clsx(styles.sideMenuHeaderIcon,location.pathname === routes.homePage && styles.active)} onClick={() => navigatePage(location.pathname, {path:routes.homePage})} >
                <span className={styles.iconDivImg1}><HomeIcon /></span>
                <span className={styles.iconDivImg2}><HomeActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon}>
                <span className={styles.iconDivImg1}><NotificationIcon /></span>
                <span className={styles.iconDivImg2}><NotificationActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon}>
                <span className={styles.iconDivImg1}><ChatIcon /></span>
                <span className={styles.iconDivImg2}><ChatActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon} id='toggle-sticky-btn' >
                <span className={styles.iconDivImg1}><PinIcon /></span>
                <span className={styles.iconDivImg2}><PinActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon}>
                <span className={styles.iconDivImg1}><VideoIcon /></span>
                <span className={styles.iconDivImg2}><VideoActiveIcon /></span>
              </div>
              <div className={styles.sideMenuHeaderIcon}>
                <span className={styles.iconDivImg1}><QuestionIcon /></span>
                <span className={styles.iconDivImg2}><QuestionActiveIcon /></span>
              </div>
          </div>
          <div className={styles.searchBoxDiv}>
            <div className={styles.searchBox}>
                <SearchIcon />
                <input 
                  placeholder="Search Menu" 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
          </div>
          <div className={styles.sideMenuBgEllips}></div>
          <div className={styles.sideMenuBgEllips1}></div>
        </div>  */}
        </>}
      </div>}
    </div>
    </div>}
    </div>
  );
}

export default Menu

