import React from 'react';
import './StatesList.scss';

const StatesList = ({ states, onStateClick, inputValue, selectedIndex }) => {
  
  const handleClick = (state) => {
    onStateClick(state);
  };
  
  return (
    <div className="states-list">
      {states.map((state, index) => (
        <button
          key={state.id}
          className={`state-button ${
            (selectedIndex !== -1 && index === selectedIndex) ? 'hover' : ''
          } ${
            (inputValue && state.code.toLowerCase().startsWith(inputValue.toLowerCase())) ? 'active' : inputValue ? 'unactive' : ''
          }`}
          onClick={() => handleClick(state)}
        >
          {state.code}
        </button>
      ))}
    </div>
  );
};

export default StatesList; 