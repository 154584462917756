export const toggleStickyBtnId = 'toggle-sticky-btn';
export const localStorageStickyItemKey = 'isSticky';
export const SHOW_NOTIFICATION = "show-notification";
export const httpsOrigin = 'https://';
export const refererSuffix = '/';

export const userRole = {
  buyerUser : 'BUYER',
  sellerUser : 'SELLER',
  neutralUser : 'NEUTRAL'
}

export const chatUserRole = {
  USER: 'User',
  GUEST: 'Guest',
  MODERATOR: 'Moderator',
  ADMIN: 'Admin',
}

export const EmptyString = '';
export const MinSearchDataLen = 2;
export const keyUpCode = 38;
export const keyDownCode = 40;
export const keyEnterCode = 13;
export const keyUp = "ArrowUp";
export const keyDown = "ArrowDown";
export const keyEnter = "Enter";
export const commomKeys = {
  countryCode: '+1',
  uploadSuccessful: 'Upload Successful',
  error: 'Error',
  errorContent: 'Something went wrong. Please try again in sometime',
  errorBtnTitle: 'Ok',
  successBtnTitle: 'Got it',
  actionStatus: {
    success: 'success',
    error: 'error'
  },
  info: 'Info',
  refresh: 'Refresh',
  tryAgain: 'Try Again',
  downloadPath: 'downloads',
  continue: 'Continue'
}

export const snackbarSeverityType = {
  alert: 'alert',
  warning: 'warning',
  success: 'success'
}

export const snackbarMessageContent = {
  socketAuthExpiryMessage: "<p>Session expired. Please refresh the app or click on 'Try Again'</p>",
  socketRefreshMessage: "<p>We seem to have disconnected. Please click on 'Try Again'</p>",
  productReferenceDataChanged: "<p>Please refresh the app to get updated Products & Pricing</p>",
  discountPriceChanged: "<p>App improvements have been made that require an update.</p>"
}

export const referenceDataKeys = {
  domesticMaterialTextKey: "DOMESTIC_MATERIAL_ONLY_TEXT",
  sellerAvailInMinKey: "SELLER_AVAIL_IN_MINUTES"
}

export const routes = {
  loginPage : '/',
  homePage : '/home',
  forgotPassword : '/forgot-password',
  tempPage : '/share-widget',
  TnCPage : '/tnc',
  successPage : '/success',
  buyerSettingPage : '/buyer-setting',
  sellerSettingPage : '/seller-setting',
  createPoPage : '/create-po',
  orderConfirmationPage : '/order-confirmation',
  orderConfirmationPageSeller : '/order-confirmation-seller',
  acceptOrderPage : '/accept-order',
  disputePage : '/dispute',
  orderPage : '/order',
  newUpdate : '/new-update',
  onboardingWelcome:'/onboarding-welcome',
  onboardingTnc:'/onboarding-tnc',
  onboardingDetails:'/onboarding-details',
  onboardingThankYou:'/onboarding-thank-you',
  acceptedOrders: 'acceptedOrders',
  chat: '/chat',
  impersonateList: '/impersonate-list',
  changePassword: '/change-Password',
  videoLibrary:'/video-library'
}


export const DeliveryDates = [
    { title: 'Order Date + 2 Days', value: 2 },
    { title: 'Order Date + 3 Days', value: 3 },
    { title: 'Order Date + 4 Days', value: 4 },
    { title: 'Order Date + 5 Days', value: 5 },
    { title: 'Order Date + 6 Days', value: 6 },
    { title: 'Order Date + 7 Days', value: 7 },
    { title: 'Order Date + 8 Days', value: 8 },
    { title: 'Order Date + 9 Days', value: 9 },
    { title: 'Order Date + 10 Days', value: 10 },
    { title: 'Order Date + 11 Days', value: 11 },
    { title: 'Order Date + 12 Days', value: 12 },
    { title: 'Order Date + 13 Days', value: 13 },
    { title: 'Order Date + 14 Days', value: 14 },
    { title: 'Order Date + 15 Days', value: 15 },
    { title: 'Order Date + 16 Days', value: 16 },
  ];

  export const States = [
    { title: 'AK', value: 27 },
    { title: 'WD', value: 28 },
    { title: 'NY', value: 29 },
    { title: 'NE', value: 30 },
  ];
  
  
  export const reactQueryKeys = {
    cognitoUser: 'cognitoUser',
    getUserPartData: 'getUserPartData',
    getCassData: "getCassData",
    createCassSupplier: "createCassSupplier",
    getForbiddenTooltips: "getForbiddenTooltips",
    getCompanyLists: "getCompanyLists",
    getAcceptedOrders: "getAcceptedOrders",
    getSecurityData: "getSecurityData"
  }


  export const RecevingHoursFrom = [
    {title:'3am', value: 3, disabled: false},
    {title:'4am', value: 4, disabled: false},
    {title:'5am', value: 5, disabled: false},
    {title:'6am', value: 6, disabled: false},
    {title:'7am', value: 7, disabled: false},
    {title:'8am', value: 8, disabled: false},
    {title:'9am', value: 9, disabled: false},
    {title:'10am', value: 10, disabled: false},
    {title:'11am', value: 11, disabled: false},
    {title:'12pm', value: 12, disabled: false},
    {title:'1pm', value: 13, disabled: false},
    {title:'Closed', value: 'closed', disabled: false},
  ];

  export const RecevingHoursTo = [
    {title:'10am', value: 10, disabled: false},
    {title:'11am', value: 11, disabled: false},
    {title:'12pm', value: 12, disabled: false},
    {title:'1pm', value: 13, disabled: false},
    {title:'2pm', value: 14, disabled: false},
    {title:'3pm', value: 15, disabled: false},
    {title:'4pm', value: 16, disabled: false},
    {title:'5pm', value: 17, disabled: false},
    {title:'6pm', value: 18, disabled: false},
    {title:'Closed', value: 'closed', disabled: false},
  ]

  export const ExpirationDate1 = [
    {title:'6 months', value: 12 },
    {title:'1 year', value: 13 },
    {title:'2 year', value: 14 },
    {title:'3 year', value: 15 },
    {title:'4 year', value: 16 },
    {title:'5 year', value: 17 },
    {title:'Never Expires', value: 18 }
  ]

  export const ExpirationDate2 = [
    {title:'6 months', value: 12 },
    {title:'1 year', value: 13 },
    {title:'2 year', value: 14 },
    {title:'3 year', value: 15 },
    {title:'4 year', value: 16 },
    {title:'5 year', value: 17 },
    {title:'Never Expires', value: 18 }
  ]


 export const prefixUrl = {
    resaleCertPrefix : 'resalecert',
    irsW9Prefix : 'irsw9',
    lineCard : 'linecard',
    buyerPo : 'BuyerPO',
    sellerSo : 'SellerSO',
    uiLogs:'ui-logs'
  }

  export const fileType = {
    excelSheet : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf'

  }

export const buyerSettingConst = {
  buyerCreditLineLimit : 200000,
  creditLimitErrorMessage: 'Currently credit lines up to $200,000 are supported.',
  uploadCertDialogContent: 'Your Resale Certificate has been uploaded successfully'
}
export const purchaseOrder = {
  readyToClaim: "READY_TO_CLAIM", 
  pending: "PENDING",
  paymentMethodACH: "ach_credit",
  paymentMethodBNPL: "bryzos_pay"
}

export const disputeConst = {
  searchPlaceholder: "Search Orders to Create Dispute",
}
export const orderConfirmationConst = {
  buyerCancel: "BUYER_ORDER_CANCEL",
  sellerCancel: "SELLER_ORDER_CANCEL",
  uploadPoDialogContent: 'Your Purchase Order has been uploaded successfully',
  uploadSoDialogContent: 'Your Sales Order has been uploaded successfully',
  getResaleListOfBuyerUseEffect: 'getResaleListOfBuyerUseEffect',
  handleCheckboxClick: 'handleCheckboxClick',
  uploadSoFile1: 'uploadSoFile1',
  uploadSoFile2: 'uploadSoFile2',
  uploadSoFile3: 'uploadSoFile3',
  downloadCertificate: 'downloadCertificate',
  cancelOrder: 'cancelOrder',
}

export const orderPageConst = {
  orderNotAvaialableMsg: "The order is no longer available.",

}

export const userTypes = [
  { name: 'Buyer', value: 'BUYER'},
  { name: 'Seller', value: 'SELLER'},
];

export const supplier = "Supplier";
export const cassErrorMessage = "Cass - ";

export const CUSTOM_NOTIFICATION_PRIORTY = ["LOW", "MEDIUM", "HIGH"];
export const CUSTOM_NOTIFICATION_ACTION = ["REFRESH", "CLOSE"];

export const PDF_TEMPLATE_EXPORT = 'TemplateExportPdf';
export const pdfMakeData = {
  codes: {
    success: 'success',
    cancel: 'cancel',
    ebusy: 'EBUSY',
  },
  message: {
    success: 'PDF successfully generated and saved.',
    error: 'Error in generating PDF'
  },
  pdfName: 'order_review',
}

export const raygunKeys = {
  socketInvalidToken: {
    tag: "socket-invalid-token",
    errorMsg : "Error in socket connection with invalid token."
  },
  buyerPriceNull: {
    tag: "GI-I1741",
  }
}

export const changePasswordConst = {
  onSuccess: 'Successfully changed password',
  onError: 'Error changing password',
  noUserCredentialFound: 'No Credentials Found'
}

export const defaultResaleCertificateLine = {state_id: "", expiration_date: "", uploadCertProgress: null, is_deletable: true };

export const CASS_INVALID_ACCOUNT_DETAILS_ERROR = "bankName or disbursementRoutingNumber or disbursementAccountNumber is not valid";

export const sellerSettingConstant = {
  sellerSettingUseEffect: 'sellerSettingUseEffect',
  uploadIRSW9File1:'uploadIRSW9File1',
  uploadIRSW9File2:'uploadIRSW9File2',
  uploadLineCardFile1:'uploadLineCardFile1',
  uploadLineCardFile2:'uploadLineCardFile2',
  getTruevaultData1:'getTruevaultData1',
  getTruevaultData2:'getTruevaultData2',
  submitData:'submitData'
}

export const acceptOrderConst = {
  acceptOrderPoIndexUseEffect: 'acceptOrderPoIndexUseEffect'
}

export const shareProductPricingOrAppConst = {
  handleSubmitData: 'handleSubmitData'
}

export const productSearchConst = {
  searchAnalyticsApi: 'searchAnalyticsApi'
}

export const homePageConst = {
  onShareApp: 'onShareApp',
  onShareProductPricing: 'onShareProductPricing'

}

export enum AuthError {
  EmailNotFound = "EmailNotFound",
  PasswordMismatch = "PasswordMismatch",
  LoginFailed = "LoginFailed",
  LoginApprovalError = "LoginApprovalError",
}

export const options = [
  {
      title: "YES",
      value: true
  }, 
  {
      title: "NO",
      value: false
  }];

export const units = [
      {
          title: "FT",
          value: "ft"
      }, 
      {
          title: "PC",
          value: "ea"
      },
      {
          title: "LB",
          value: "lb"
      },
      {
          title: "CWT",
          value: "cwt"
      },
  ];

  
export const feedbackUnits = [
  {
      title: "PC",
      value: "ea"
  },
  {
    title: "LB",
    value: "lb"
  },
  {
    title: "CWT",
    value: "cwt"
  },
  {
      title: "FT",
      value: "ft"
  }
];

export const cognitoCookiePrefix = 'CognitoIdentityServiceProvider';
export const systemVersionWebConst = 'CLOUDFRONT WEB';
