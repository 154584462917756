import { create } from 'zustand';

const commonStore = {
    loadComponent:null,
    showVideo: true,
    props: null,
}
  
interface RightWindowStore {
    loadComponent: React.ReactNode;
    showVideo: boolean;
    props: any;
    setProps: (props: any) => void;
    setLoadComponent: (loadComponent: React.ReactNode) => void;
    setShowVideo: (showVideo: boolean) => void;
    resetMenuStore: () => void;
}
  
export const useRightWindowStore = create<RightWindowStore>((set, get) => ({
    ...commonStore,
    setLoadComponent: (loadComponent: React.ReactNode) => set({ loadComponent }),
    setProps: (props: any) => set({ props }),
    setShowVideo: (showVideo: boolean) => set({ showVideo }),
    resetMenuStore: () => set(() => ({
        ...commonStore
    })),
}));