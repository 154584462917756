import { axios, getChannelWindow, useGlobalStore } from '@bryzos/giss-ui-library';
import { useMutation } from '@tanstack/react-query';
import { prefixUrl } from 'src/renderer2/common';
import { v4 as uuidv4 } from 'uuid';

const useGetSignedUrl = () => {
    const store = useGlobalStore.getState();
    const channelWindow = getChannelWindow();

    return useMutation(({ data, deviceId }) => {
        try {
            const fileName = `${deviceId}(${uuidv4()}).${data.isOld ? "old.log" :"log"}`
            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + store.userData?.data?.id + '/' + prefixUrl.uiLogs + '/' + fileName;
            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_UI_LOGS_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300
                }
            }
            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    if(channelWindow?.uploadLogs){
                        window.electron.send({
                            channel: channelWindow.uploadLogs, data: {
                                signedUrl, fileName, pusherData: data
                            }
                        })
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    },
        {
            retry: 4,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 0
        }
    );
};

export default useGetSignedUrl;