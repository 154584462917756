// @ts-nocheck
import { useQuery } from "@tanstack/react-query";
import { reactQueryKeys } from "../common";
import axios from "axios";
import { Auth } from "aws-amplify";

const useGetForbiddenTooltips = () => {
  return useQuery(
    [reactQueryKeys.getForbiddenTooltips],
    async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_SERVICE}/user/getTooltip`
        );

        if (response.data && response.data.data) {
          if (
            typeof response.data.data === "object" &&
            "err_message" in response.data.data
          ) {
            throw new Error(response.data.data.err_message);
          } else {
            return response.data.data.tooltips;
          }
        } else {
          return null;
        }
      } catch (error) {
        throw new Error(error?.message ?? error);
      }
    },
    {
      enabled: false,
    }
  );
};

export default useGetForbiddenTooltips;
