// @ts-nocheck 
import * as yup from "yup";
export const schema = yup.object({
  companyName: yup.string().trim().required('Company Name is not valid'),
  yourCompany: yup.string().trim().required('Your Company is not valid'),
  companyAddressLine: yup.string().trim().required('Company Address is not valid'),
  companyCity:yup.string().trim().required('Company Address is not valid'),
  companyState:yup.number().required('Company Address is not valid'),
  companyZipCode:yup.string().required('Company Address is not valid').min(5,'Company Address is not valid'),
  firstName:yup.string().trim().required('Your Name is not valid'),
  lastName:yup.string().trim().required('Your Name is not valid'),
  emailAddress:yup.string().required('Email/Phone is not valid').email('Email/Phone is not valid').max(50,'please do not enter more than 50 characters'),
  phoneNo:yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Email/Phone is not valid").required("Phone number is required"),
  stockingLocation: yup.string().trim(),
  sendInvoicesTo: yup.string(),
  shippingDocsTo: yup.string(),
  IRSW9FormUpload: yup.string(),
  productsUploadUrl: yup.string(),
  wireCheckBox: yup.boolean(),
  bankName: yup.string().trim().test("isRequired", "Wire is not valid", function (value) {
    const wireCheckBox = this.parent.wireCheckBox;
    if(wireCheckBox === false) return true;
    return !!value;
  }),
  routingNumber: yup.string().trim().test("isRequired", "Wire is not valid", function(value) {
    const wireCheckBox = this.parent.wireCheckBox;
    if(wireCheckBox === false) return true;
    if(!/^x{5}\d{4}$|^\d{9}$/.test(value)){
      return false
    }
    return !!value;
  }),
  accountNumber: yup.string().trim().test("isRequired", "Wire is not valid", function(value) {
    const wireCheckBox = this.parent.wireCheckBox;
    if(wireCheckBox === false) return true;
    if(!/^x+\d{4}$|^\d+$/.test(value)){
      return false
    }
    return !!value;
  }),
  achCheckBox: yup.boolean(),
  bankName1: yup.string().trim().test("isRequired", "ACH Credit is not valid", function(value){
    const achCheckBox = this.parent.achCheckBox;
    if(achCheckBox === false) return true;
    return !!value;
  }),
  routingNo: yup.string().test("isRequired", "ACH Credit is not valid", function(value){
    
    const achCheckBox = this.parent.achCheckBox;
    if(achCheckBox === false) return true;
      if(!/^x{5}\d{4}$|^\d{9}$/.test(value)){
      return false
    }
    return !!value;
  }),
  accountNo: yup.string().test("isRequired", "ACH Credit is not valid", function(value){
    const achCheckBox = this.parent.achCheckBox;
    if(achCheckBox === false) return true;
    if(!/^x+\d{4}$|^\d+$/.test(value)){
      return false
    }
    return !!value;
  })
})