// @ts-nocheck
import { routes } from "../common";
import axios from 'axios';

const clearPageSessionData = async(pageName, pageData) => {
    
    
    if(pageName === routes.createPoPage){
        if(pageData?.createPoSessionId){
            const payload = {
            "data": {
                "session_id": pageData?.createPoSessionId,
                "close_status": "CANCEL"
            }
        }
            await axios.post(import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close', payload)
            .catch(err => console.error(err))
        }
    }
}

export default clearPageSessionData ;