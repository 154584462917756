// @ts-nocheck
import React, {  useEffect, useRef, useState } from 'react';
import { Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import Login from './pages';
import Home from './pages/search/home';
import Tnc from './pages/tnc';
import Success from './pages/success';
import CreatePo from './pages/buyer/createPo';
import BuyerSetting from './pages/buyer/buyerSetting';
import SellerSetting from './pages/seller/sellerSetting';
import OrderConfirmation from './pages/buyer/orderConfirmation';
import AcceptOrder from './pages/seller/acceptOrder';
import Dispute from './pages/dispute/dispute';
import Order from './pages/dispute/order';
import {  
  CUSTOM_NOTIFICATION_ACTION,
  CUSTOM_NOTIFICATION_PRIORTY,
    MAX_APP_HEIGHT,
    MAX_APP_WIDTH,
    chatUserRole,
    commomKeys,
    purchaseOrder,
    raygunKeys,
    routes,
    snackbarMessageContent,
    snackbarSeverityType,
    systemVersionWebConst,
    userRole
} from './common';
import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import useCognitoUser from './hooks/useCognitoUser';
import { MENU_ANIMATION_DURATION, VERSION_NUMBER, dispatchRaygunError, getProductMapping, mainConfig, navigatePage, setNavigate, updatedAllProductsData } from './helper';
import Header from './pages/header';
import Loader from './Loader/Loader';
import rg4js from "raygun4js";
import ForgotPassword from './pages/forgotPassword';
import { useWindowEvent } from '@mantine/hooks';
import UpdatePopup from './component/UpdatePopup/updatePopup';
import MatPopup from './component/MatPopup/MatPopup';
import OnboardingWelcome from './pages/Onboarding/onboardingWelcome';
import OnboardingTnc from './pages/Onboarding/onboardingTnC';
import OnboardingDetails from './pages/Onboarding/onboardingDetails';
import OnboardingThankYou from './pages/Onboarding/onboardingThankyou';
import ErrorBoundary from './component/Error/ErrorBoundary';
import ToastSnackbar from './component/Snackbar/ToastSnackbar';
import useSnackbarStore from './component/Snackbar/snackbarStore';
import { ReactComponent as CloseIcon } from '../../public/asset/Icon_Close.svg';
import useGetUserDiscountData from './hooks/useGetUserDiscountData';
import { ReferenceDataProduct } from './types/ReferenceDataProduct';
import { useSellerOrderStore, useGlobalStore, createSocket, addAxiosInterceptor, initializeAxiosResponseInterceptor, useGetSecurityData, generateHashFromEncryptedData, excludeSecurityHashApiList, CustomSocketProp, setEnvironment, getChannelWindow, setChannelWindow, steg, RequestInterceptorParams, refereshImpersonatedUserToken, useBuyerSettingStore, ueGetBuyingPreference } from '@bryzos/giss-ui-library';
import useDialogStore from './component/DialogPopup/DialogStore';
import DialogBox from './component/DialogPopup/Dialog';
import { Chat } from './component/chat/Chat';
import { useImmer } from 'use-immer';
import Impersonate from './component/Impersonate/Impersonate';
import addErrorInterceptors from './utility/ErrorInterceptor';
import NoInternet from './component/NoInternet/NoInternet';
import VideoLibrary from './pages/VideoLibrary/videoLibrary';
import ChangePassword from './component/changePassword/changePassword';
import useGetReferenceData from './hooks/useGetReferenceData';
import useGetAllProducts from './hooks/useGetAllProducts';
import AuthenticationWrapper from './component/AuthenticationWrapper/AuthenticationWrapper';
import { useHeightListener } from './hooks/useHeightListener';
import HeaderTop from './component/Header/Header';
import Search2 from './pages/Search-2';
import BomUpload from './pages/buyer/bom-upload/BomUpload';

import multipliers from './multipliers.json';
import EventLogger from './component/EventLogger/EventLogger';
import useGetSignedUrl from './hooks/useGetSignedUrl';
import packageConfig from '../../package.json';
import Menu from './component/Menu/Menu';
import { useMenuStore } from './component/Menu/MenuStore';
import RightWindow from './pages/RightWindow/RightWindow';
import clsx from 'clsx';
import { useRightWindowStore } from './pages/RightWindow/RightWindowStore';

rg4js("apiKey", import.meta.env.VITE_RAYGUN_KEY);
rg4js("enableCrashReporting", true);
rg4js('setVersion', VERSION_NUMBER);
rg4js('ignore', 'web_request_timing');
rg4js('options', {
  ignore3rdPartyErrors: true
});

// Save original console methods
// const originalConsoleMethods = { ...console };

if (!window.electron) {
    window.electron = {
        send: () => { },
        sendSync: () => { },
        receive: ()=>{},
        handleZoom: ()=>{},
        isWeb:true
    }
}

Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env
      .VITE_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain: import.meta.env.VITE_AWS_COGNITO_DOMAIN,
      path: import.meta.env.VITE_AWS_COGNITO_PATH,
      expires: Number(import.meta.env.VITE_AWS_COGNITO_EXPIRES),
      sameSite: import.meta.env.VITE_AWS_COGNITO_SAME_SITE,
      secure: Boolean(import.meta.env.VITE_AWS_COGNITO_SECURE),
    }
  },
  Analytics: { disabled: Boolean(import.meta.env.VITE_AWS_COGNITO_ANALYTICS_DISABLED) },
});

const listChannelWindow = window.electron.sendSync({ channel: 'setChannelWindows' });
setChannelWindow(listChannelWindow);

function App2() {
    const navigate = useNavigate();
    const location = useLocation();
    if(location.pathname === routes.newUpdate){
      return <UpdatePopup />
    }
    const [user, setUser] = useImmer({'data': null, "zip": null });
    const [openMatPopup, setOpenMatPopup] = useState(false);
    const [disableInitialSecurityApiCall, setDisableInitialSecurityApiCall] = useState(false);
    const foregroundBackgroundActivityPayloadRef = useRef<CustomSocketProp>();
    const setSellerSettingsData = useGlobalStore(state => state.setSellerSettingsData);
    const { userData, setUserData, setSecurityHash, showErrorPopup, setShowErrorPopup, sdk, setSdk, setShowChatIcon, noInternetAccessibility, setDecryptionEntity, currentAccessToken, originalLoggedInUserData,  isImpersonatedUserLoggedIn,hasLoginProcessCompleted, setStartLoginProcess, apiFailureDueToNoInternet } = useGlobalStore();
    const {viewedOrdersListForBadgeCount,backdropOverlay, setBackdropOverlay, setSellerCompanyName, isUserLoggedIn, discountData, forceLogout, setForceLogout , appVersion,setAppVersion,setSystemVersion , currentUser,setCurrentUser,productData, setProductData,setProductMapping,setReferenceData, onlineStatus, securityHash} = useGlobalStore();
    const { showCommonDialog, resetDialogStore } = useDialogStore();

    const {
      data: cognitoUser,
    } = useCognitoUser(!window.electron?.isWeb ? isUserLoggedIn : true, securityHash);

    const getUserDiscountData = useGetUserDiscountData();
    const getReferenceData = useGetReferenceData();
    const getAllProductsHook = useGetAllProducts();
    const routerContentRef = useRef(null);

    const {
      refetch: refetchGetSecurity
    } = useGetSecurityData(cognitoUser);
    
    const [socket, setSocket] = useState(null);
    const resetHeaderConfig = useGlobalStore(state => state.resetHeaderConfig);
    const showLoader = useGlobalStore(state => state.showLoader);
    const setShowLoader = useGlobalStore(state => state.setShowLoader);
    const disableBryzosNavigation = useGlobalStore(state => state.disableBryzosNavigation);
    const setDisableBryzosNavigation = useGlobalStore(state => state.setDisableBryzosNavigation);
    const channelWindow =  getChannelWindow()  ;
    const setNavigationStateForNotification = useGlobalStore(state => state.setNavigationStateForNotification);
    const purchaseOrdersList = useSellerOrderStore(state => state.ordersCart);
    const {showToastSnackbar, resetSnackbarStore, setSnackbarOpen, openSnackbar} = useSnackbarStore();
    const [pusherId, setPusherId] = useState(0);
    const [resumeApp, setResumeApp] = useState(false);
    const deviceId = useRef<string | null>(null);
    const ref = useHeightListener();
    const getSignedUrl = useGetSignedUrl();
    const {openMenu, closeWithoutAnimation} = useMenuStore();
    const routerContainerRef = useRef<HTMLDivElement>(null);
    const rightContentRef = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [wrapperContentWidth, setWrapperContentWidth] = useState(0);
    const {showVideo, loadComponent} = useRightWindowStore();
    const rightWindowRef = useRef<HTMLDivElement>(null);
    const [areRightWindowHeightsEqual, setAreRightWindowHeightsEqual] = useState(true);
    const [isMacDevice, setIsMacDevice] = useState(false);
    const mainWrapperRef = useRef<HTMLDivElement>(null);
    
    const getBuyingPreference = ueGetBuyingPreference();
    const { setBuyerSettingInfo } = useBuyerSettingStore();

    useWindowEvent('plugin_web_update_notice',  (e) => {
      const { version } = e.detail;
    });

  useEffect(() => {
    if (userData?.data && discountData) {
      userData.data.disc_is_discounted = discountData.isDiscounted;
      userData.data.disc_discount_rate = discountData.discountRate;
      userData.data.disc_discount_pricing_column = discountData.discountPricingColumn;
      setUserData({...userData});
      setProductMapping(getProductMapping(productData, userData.data));
    }
  }, [discountData]);

  useEffect(() => {
    setEnvironment(import.meta.env);
    const steg_fun = steg(document);
    steg_fun.decode("/asset/space_5.png", (_data) => {
      setDecryptionEntity(JSON.parse(_data));
    })
    addErrorInterceptors();
    if(!disableInitialSecurityApiCall){
      addSecurityHashInInterceptor(true);
    }
    if(window.electron?.isWeb) setStartLoginProcess(true);
    if(channelWindow?.resizeScreen){
      window.electron.send({ channel: channelWindow.resizeScreen, data: { width: MAX_APP_WIDTH, height: MAX_APP_HEIGHT }});
    }
    if(channelWindow?.electronVersion){
      setAppVersion(window.electron.sendSync({ channel: channelWindow.electronVersion }));
    }else{
      setAppVersion(packageConfig.version);
    }
    initializeAxiosResponseInterceptor(setForceLogout, async(response)=>{
      await handleAxiosResponseData(response);
    });
    if(channelWindow?.systemVersion){
      const os = window.electron.sendSync({ channel: channelWindow.systemVersion, data: null});
      setIsMacDevice(os.includes('Mac'))
      setSystemVersion(os);
    }else{
      setSystemVersion(systemVersionWebConst)
    }
    if(window.electron.handleZoom)
    window.electron.handleZoom();

    if(channelWindow?.setCofigFromRenderer){
      window.electron.send({ channel: channelWindow.setCofigFromRenderer, data:mainConfig})
    }

    if(channelWindow?.logData){
      window.electron.receive(channelWindow.logData, (data)=>{
      })
    }

    if(channelWindow?.handleURI){
      window.electron.receive(channelWindow.handleURI,(uri) => {
        const [protocol, path] = uri.split('//');      
        if(path){
            const isMail = path.split("/")[0] === "mail";
          if(isMail){
            openAppUsingLinkHandler(path);
          }else{
            notificationUriHandler(path);
          }
        }
      })
    }
    if(channelWindow?.markAsReadNotification){
      window.electron.receive(channelWindow.markAsReadNotification,(notificationList) => {
        notificationList = JSON.parse(notificationList);
        markNotificationAsRead(notificationList);
      });
    }
    if(channelWindow?.forceLogout) {
      window.electron.receive(channelWindow.forceLogout,() => {
        if(currentUser)
        setForceLogout(true);
      });
    }
    if(channelWindow?.discountPriceChanged) {
      window.electron.receive(channelWindow.discountPriceChanged,() => {
        if(currentUser){
          showOverlayToaster(snackbarMessageContent.discountPriceChanged, snackbarSeverityType.warning, [{name:commomKeys.refresh, handler: handleSnackbarClose}], null, true);
        }
      });
    }
    if(channelWindow?.productReferenceChanged){
      window.electron.receive(channelWindow.productReferenceChanged,() => {
        showOverlayToaster(snackbarMessageContent.productReferenceDataChanged, snackbarSeverityType.warning, [{name:commomKeys.tryAgain, handler: handleSnackbarClose}], null, true);
      });
    }
    if (channelWindow?.customNotification) {
      window.electron.receive(channelWindow.customNotification, (data) => {
        if (data?.notification) {
          showCustomNotification(data, true);
        }
      });
    }
    if(channelWindow?.getAccessToken){
      window.electron.receive(channelWindow.getAccessToken,(channelId) => {
        setPusherId(channelId);
      })
    }

    if(channelWindow?.resumeApp){
      window.electron.receive(channelWindow.resumeApp,()=>{
        setResumeApp(true);
      })
    }
    if(channelWindow?.getSignedUrl){
      window.electron.receive(channelWindow.getSignedUrl,(data)=>{
        getSignedUrlForLogger(data)
      })
    }
  
    getDeviceId();
    setNavigate(navigate);

    return () => {
      socket?.disconnect();
      setSocket(null);
    };
  }, []);

  useEffect(()=>{
    if(resumeApp && currentUser){
      checkDiscountPricing();
      setResumeApp(false);
    }
  },[resumeApp, currentUser])

  useEffect(() => {
    let badgeCount = 0;
    if(viewedOrdersListForBadgeCount && purchaseOrdersList){
      const viewedOrderSet = new Set([...viewedOrdersListForBadgeCount]);
      badgeCount = purchaseOrdersList.reduce((count, order) => {
        if(!viewedOrderSet.has(order.buyer_po_number) && order.claimed_by === purchaseOrder.readyToClaim) count++;
        return count;
      }, 0);
    }
    if(channelWindow?.badgeCountHandler)
    window.electron.send({ channel: channelWindow.badgeCountHandler, data:{type: 'set', count:badgeCount }});
  }, [purchaseOrdersList, viewedOrdersListForBadgeCount]);

  useEffect(() => {
    if(pusherId && channelWindow?.refreshPrivateChannel && cognitoUser){
      reconnectPusher()
    }
  },[pusherId])

  useEffect(()=>{
    if(showErrorPopup){
      showCommonDialog(null, commomKeys.errorContent, null, handleCloseErrorPopup, [{name: commomKeys.errorBtnTitle, action: handleCloseErrorPopup}])
    }
  },[showErrorPopup])

  const handleAxiosResponseData = async(response) => {
    const urlSplit = response.config.url.split("/");
      const pathName = urlSplit[urlSplit.length - 1];
      if((!excludeSecurityHashApiList.find((excludeSecurityHashApi) => pathName.startsWith(excludeSecurityHashApi)) && response?.data?.data === 'Success' && response.status === 200)){
        showCommonDialog(null, commomKeys.errorContent, null, handlePopupClose, [{name: commomKeys.errorBtnTitle, action: handlePopupClose}])
      }
  }

  const getSignedUrlForLogger = (data) => {
    const payload = { data, deviceId: getDeviceId()}
    getSignedUrl.mutateAsync(payload);
    getSignedUrl.mutateAsync({...payload, data: { ...data, isOld : true}});
  }

  const handlePopupClose = () => {
    if(channelWindow?.refreshApp)
    window.electron.send({ channel: channelWindow.refreshApp });
    resetDialogStore();
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false)
    addSecurityHashInInterceptor(true);
    setShowLoader(false)
    resetDialogStore();
  }

  const checkDiscountPricing = async () => {
    try {
      const userDiscount = await getUserDiscountData.mutateAsync();

      if (!(currentUser.disc_is_discounted === userDiscount.is_discount &&  currentUser.disc_discount_rate === userDiscount.discount_rate && currentUser.disc_discount_pricing_column === userDiscount.discount_pricing_column)) {
        showOverlayToaster(snackbarMessageContent.discountPriceChanged, snackbarSeverityType.warning, [{name:commomKeys.refresh, handler: handleSnackbarClose}], null, true);
      }
      const prevUser = {...currentUser,  disc_is_discounted: userDiscount.is_discount, disc_discount_rate: userDiscount.discount_rate, disc_discount_pricing_column: userDiscount.discount_pricing_column}
      setCurrentUser(prevUser);
    } catch (error) {
      console.log(error)
    }

  }

  const reconnectPusher = async () => {
    try{
      let accessToken;
      if (isImpersonatedUserLoggedIn) accessToken = await refereshImpersonatedUserToken(originalLoggedInUserData)
      else {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        accessToken = user.signInUserSession.accessToken.jwtToken;
      }
      window.electron.send({ channel: channelWindow.refreshPrivateChannel, data: { channelId: pusherId, accessToken } });
    } catch(error) {
      console.log(error);
    } finally {
      setPusherId(null);
    }
  }

  const getLogData = ()=>{
    if(channelWindow?.logData){
      window.electron.send({ channel:channelWindow.logData, data:null}); // Send request to the main process
    }
  };
  
  const notificationUriHandler = (path) => {   
      let [userId, notificationId, routePath, stateUrl] = path.split('/');
      if((+userId) === (+currentUser.id)){
        if(routePath)
        routePath = `/${routePath}`;
        const state = {};
        if(stateUrl){
          const stateUrlArray = stateUrl.split(',');
          for(const element of stateUrlArray){
            const [key, value] = element.split('=');
            state[key] = value;
          };
        }
        let isValidRoutePath = false;
        for(const routeKey in routes){
          if(!isValidRoutePath && routes[routeKey] === routePath) isValidRoutePath=true;
        }
        if(isValidRoutePath){
          setNavigationStateForNotification(state);
          navigatePage(location.pathname, {path:routePath})
        }
      }
      else{
        console.log(`Notication for User: ${userId} but found currentUser:${currentUser.id}`);
      }
  }

  const markNotificationAsRead = (notificationList) => {
    const deviceId = getDeviceId();
    const payload = {data:{notification_id: [], device_id: deviceId } };
    for(const notification of notificationList){
      payload.data.notification_id.push(notification.notificationId);
    }
    axios.post(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/markAsRead', payload)
    .then(res => {
      if(channelWindow?.isMarkAsReadNotification)
      window.electron.send({ channel: channelWindow?.isMarkAsReadNotification, data:true })
    })
    .catch(err => {
      if(channelWindow?.isMarkAsReadNotification)
      window.electron.send({ channel: channelWindow?.isMarkAsReadNotification, data:false })
    });
  }

  const openAppUsingLinkHandler = (path) => {
    if(path.indexOf("mail/create-po") >= 0){
      if(currentUser?.type === userRole.buyerUser)
      navigate(routes.createPoPage);

    }else if(path.indexOf("mail/buyer-setting") >= 0){
      if(currentUser?.type === userRole.buyerUser)
      navigate(routes.buyerSettingPage);
    }else{

    }
  }

  const handleSnackbarClose = async (event, reason) => {
    setSnackbarOpen(false);
    try {
        await axios.get(import.meta.env.VITE_API_SERVICE + '/reference-data/homepage');
        resetSnackbarStore();
        if(channelWindow?.refreshApp)
        window.electron.send({ channel: channelWindow.refreshApp });
    } catch (error) {
        setSnackbarOpen(true);
    }
  };

  const onlyCloseSnackbar = async () => {
    setSnackbarOpen(false);
    resetSnackbarStore();
    setBackdropOverlay(false);
  };

  const showOverlayToaster = (message, severity, buttons, closeHandler, showOverlay)=>{
    showToastSnackbar(message, severity, buttons, closeHandler);
    if(showOverlay)
    setBackdropOverlay(true);
  }

  const socketConnectionErrorHandler = (message) => {
    showOverlayToaster(message, snackbarSeverityType.alert, [{name:commomKeys.tryAgain, handler: handleSnackbarClose}], null, true);
  }
  const removeSocketDisconnectToaster = ()=>{
    resetSnackbarStore();
    setBackdropOverlay(false);
  }

  const onSocketConnectionError = (errorMsg)=>{
    dispatchRaygunError(
      new Error(errorMsg),
      [raygunKeys.socketInvalidToken.tag]
    );
    if(channelWindow?.refreshApp)
    window.electron.send({ channel: channelWindow.refreshApp });
    else
    setForceLogout(true);
  }

  async function createSocketConnection(userData, accessToken){
    const extraHeaders= {
      "gissToken": import.meta.env.VITE_WEB_SOCKET_GISS_TOKEN,
      "email": userData.email_id,
      "accessToken":  accessToken
    };
    if (!deviceId.current) {
      deviceId.current = await getDeviceId();
    }
    const data = await getOsAndLastLoginAppVersion();
    const object = {
      email_id: userData.email_id,
      os_version: data.os_version,
      last_login_app_version: data.last_login_app_version,
      device_id: deviceId.current,
      ui_version: import.meta.env.VITE_RENDERER_DEPLOY_VERSION,
    }
    foregroundBackgroundActivityPayloadRef.current = object;

    const socketProps = {
      userRole: userData.type,
      socketConnectionErrorHandler: socketConnectionErrorHandler,
      removeSocketDisconnectToaster:  removeSocketDisconnectToaster,
      onSocketConnectionError: onSocketConnectionError,
      ...object
    }
    const newSocket = await createSocket(import.meta.env.VITE_WEB_SOCKET_SERVER,extraHeaders, socketProps);
    setSocket(newSocket);
  }

  const getRefenceData = async (user, isImpersonated = false) => {
    const response = await getReferenceData.mutateAsync();
    const referenceData = response.data;
    await getAllProductsData(user, referenceData, isImpersonated);
  }

  const getAllProductsData = async (user, referenceData, isImpersonated) => {
    const response: ReferenceDataProduct[] = await getAllProductsHook.mutateAsync();
    const currentTandC = user.current_tnc_version;
    const acceptedTandC = user.accepted_terms_and_condition;
    const getAllProducts = updatedAllProductsData(response.data.data);
    setUserData({ "data": user, multipliers});
    setReferenceData(referenceData)
    setProductData(getAllProducts);
    setProductMapping(getProductMapping(getAllProducts, user))
    if (location.pathname !== routes.newUpdate) {
      if (!isImpersonated && (currentTandC !== acceptedTandC || currentTandC === null || acceptedTandC === null)) {
        navigate(routes.TnCPage, {state : { isViewMode: false, navigateTo: routes.homePage }});
      }
      else if(channelWindow?.getLoginCredential && !isImpersonated && user.is_migrated_to_password <= 1){
        setShowLoader(false);
        navigate(routes.changePassword);
      } 
      else {
        setDisableBryzosNavigation(false);  
        if (!discountData) {
          navigate(routes.homePage);
        }
      }
    };
  }

  const addSecurityHashInInterceptor = async (noAccessTokenRequire, currentAccessToken, userData) => {
    try{
      const res = await refetchGetSecurity();
      if(res.data){
        const securityHash = await generateHashFromEncryptedData(res.data, import.meta.env.VITE_SECRET_KEY);
        setSecurityHash(securityHash);
        setDisableInitialSecurityApiCall(true);
        axios.interceptors.request.clear();
        const params:RequestInterceptorParams = {
          impersonatedToken: currentAccessToken
        }
        addAxiosInterceptor(() => setForceLogout(true), (request) => {
          request.headers.security = securityHash;
          if(userData) request.headers["super-admin"] = userData.data.id
        }, noAccessTokenRequire, params);
      }
    } catch (error) {
      console.log(error);
    }
    
  }

  const getOsAndLastLoginAppVersion = async () => {
    type responseType = { os_version: null | string; last_login_app_version: null | string; };
    let systemVersion = null;
    if (channelWindow?.systemVersion) {
    systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion });
    }
    const response: responseType = { os_version: systemVersion, last_login_app_version: appVersion };
    return response;
  }

  const getDeviceId = () => {
    if (!deviceId.current && channelWindow?.getDeviceId) {
      try {
        const id = window.electron.sendSync({ channel: channelWindow.getDeviceId });
        if (id && typeof id === 'string') {
          deviceId.current = id;
          return id;
        } else {
          deviceId.current = null;
          return null;
        }
      } catch (e) {
        deviceId.current = null;
        return null;
      }
    }
    return deviceId.current;
  };

  const showCustomNotification = (data: any) => {
    if (data) {
      let action = data.notification.action;

      const message = data.notification.body;
      const severity = data.notification.priority;
      let buttons;
      if (action === CUSTOM_NOTIFICATION_ACTION[0]) {
        buttons = [{ name: CUSTOM_NOTIFICATION_ACTION[0], handler: handleSnackbarClose }];
      } else if (action === CUSTOM_NOTIFICATION_ACTION[1]) {
        buttons = [{ name: "", handler: onlyCloseSnackbar, icon: <CloseIcon /> }];
      }
      showOverlayToaster(message, severity, buttons, null, true);
    }
  }

useEffect(() => {
  if (!userData?.data && sdk) {
    sdk.logout();
  }
  if (userData?.data?.id && userRole.sellerUser === userData?.data?.type) {
    axios
      .get(import.meta.env.VITE_API_SERVICE + "/user/sellingPreference", {
        headers: {
          UserId: userData.data.id,
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          if (
            typeof response.data.data === "object" &&
            "err_message" in response.data.data
          ) {
            setSellerSettingsData(null);
          } else {
            const sellerSettingData = response.data.data;
            setSellerCompanyName(sellerSettingData?.company_name ?? "");
            if(sellerSettingData?.company_name &&
              sellerSettingData?.company_address_line1 && 
              sellerSettingData?.company_address_city && 
              sellerSettingData?.company_address_state_id && 
              sellerSettingData?.company_address_zip && 
              sellerSettingData?.first_name && 
              sellerSettingData?.last_name && 
              sellerSettingData?.email_id && 
              sellerSettingData?.phone ){
              setSellerSettingsData(true);
            }else{
              setSellerSettingsData(null)
            }
          }
        } else {
          setSellerSettingsData(null);
        }
      })
      .catch(() => {
        setSellerSettingsData(null);
      });
  }
  if(userData?.data?.type === userRole.buyerUser){
    const getBuyerPreferences = async () => {
      try {
        const buyingPreference = await getBuyingPreference.mutateAsync();
        if (buyingPreference?.data?.data !== 'SUCCESS') {
          const buyerPreferenceData = buyingPreference.data.data;
          setBuyerSettingInfo(buyerPreferenceData);
        }
      } catch (error) {
        console.error('Error fetching buyer preferences:', error);
      }
    };

    getBuyerPreferences();
  }
}, [userData?.data]);

useEffect(()=>{
  getLogData()
  if(window.electron?.isWeb){
    navigate(routes.loginPage);
  }
},[])


const openUpdateLink = ()=>{
  window.open(import.meta.env.bryzosWebUrl);
  setTimeout(()=>{
    if(channelWindow?.close)
    window.electron.send({ channel: channelWindow.close })
  },0);
}

  const initiateDeadSimpleChat = async (data: any) => {
      try {
        const sdk = new DSChatSDK(import.meta.env.VITE_DEAD_SIMPLE_CHAT_ROOM_ID, 'chat-frame', import.meta.env.VITE_DEAD_SIMPLE_CHAT_PUBLIC_KEY);
        await sdk.connect();
        if (data.is_moderator) {
          await sdk.joinRoom({ accessToken: data.access_token });
        } else {
          await sdk.joinRoom({ uniqueUserIdentifier: data.unique_user_identifier });
        }
        setSdk(sdk);
        setShowChatIcon(true);
      } catch (e) {
        setShowChatIcon(false);
        console.log(e);
      }
  };

  const createDeadSimpleChatUser = async () => {
    try {
      const response = (await axios.post(import.meta.env.VITE_API_SERVICE + '/user/createUser', null)).data?.data;
      return { unique_user_identifier: response };
    } catch (error) {
      return null;
    }
  }

  // Default theme set to 'dark'
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
      // Set the initial theme on mount
      document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const handleToggle = () => {
      setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const [hideCloseAndMinimizeBtn, setHideCloseAndMinimizeBtn] = useState(false);

  useEffect(()=>{
    // if(!isMacDevice){
      setHideCloseAndMinimizeBtn(true);
      setTimeout(()=>setHideCloseAndMinimizeBtn(false), MENU_ANIMATION_DURATION)
    // }
    if(openMenu)
      setIsMenuOpen(true);
    else
      setTimeout(()=>{
        setIsMenuOpen(false);
      }, MENU_ANIMATION_DURATION);
  },[openMenu])

  useEffect(() => {
    const calculateWidth = () => {
      const routerContainerWidth = routerContainerRef.current?.offsetWidth || 0;
      const rightContentWidth = rightContentRef.current?.offsetWidth || 0;
      const totalWidth = routerContainerWidth + rightContentWidth + 48 + 351;
      setWrapperContentWidth(totalWidth > 800 ? totalWidth : 848);
    };

    // Create a ResizeObserver instance
    const resizeObserver = new ResizeObserver(() => {
      calculateWidth();
    });

    // Observe both elements if they exist
    if (routerContainerRef.current) {
      resizeObserver.observe(routerContainerRef.current);
    }
    if (rightContentRef.current) {
      resizeObserver.observe(rightContentRef.current);
    }

    // Initial calculation
    calculateWidth();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [location.pathname, isMenuOpen, showLoader]);

  useEffect(() => {
    const checkDivHeights = () => {
      if (routerContainerRef.current && rightWindowRef.current) {
        const height1 = routerContainerRef.current.clientHeight;
        const height2 = rightWindowRef.current.clientHeight;
        setAreRightWindowHeightsEqual(height1 === height2);
      }
    };
  
    // Create a separate ResizeObserver for height comparison
    const heightObserver = new ResizeObserver(() => {
      checkDivHeights();
    });
  
    // Observe the divs we want to compare
    if (routerContainerRef.current) {
      heightObserver.observe(routerContainerRef.current);
    }
    if (rightWindowRef.current) {
      heightObserver.observe(rightWindowRef.current);
    }
  
    // Initial check
    checkDivHeights();
  
    // Cleanup
    return () => {
      heightObserver.disconnect();
    };
  }, [showVideo]);
  const { setEmitAppCloseEvent} = useGlobalStore();

  const closeBtnClick = () => {
    if(channelWindow?.close){
        setEmitAppCloseEvent(true);
        window.electron.send({ channel: channelWindow.close })
    }
}

const minimizeBtnClick = () => {
    if(channelWindow?.minimize){
        window.electron.send({ channel: channelWindow.minimize })
    }
}

useEffect(()=>{
  if(channelWindow?.adjustWindowView){
    window.electron.send({ channel: channelWindow.adjustWindowView })
  }
},[openMenu, loadComponent])


return ( 
    <div ref={ref} style={{ display: 'inline-flex', transition: 'height 1s linear' }} className={clsx(isMacDevice && 'isMacDevice')}>
      <EventLogger />
    
  
      <div className={`widgetCanvas  ${location.pathname === routes.loginPage || location.pathname === routes.forgotPassword ? 'appBg' : 'noBg'}   ${window.electron.isWeb ? 'webBackground' : ''} ${backdropOverlay ? 'isbackdropOverlay' : ''} ${(noInternetAccessibility || apiFailureDueToNoInternet || !onlineStatus)  ? 'noInternetWindow' : ''}` }>
      {location.pathname !== routes.loginPage && <div className={clsx('closeAndMinimizeBtn', isMacDevice && 'macCloseAndMinimizeBtn', hideCloseAndMinimizeBtn && 'hideCloseAndMinimizeBtn', (isMacDevice && !isMenuOpen) && 'positionCloseAndMinimizeBtn')}>
        <div className="btn close-btn" onClick={closeBtnClick}></div>
        <div className="btn min-btn" onClick={minimizeBtnClick}></div>
      </div>}
      <NoInternet />
      <ErrorBoundary>
        <AuthenticationWrapper cognitoUser={cognitoUser} addSecurityHashInInterceptor={addSecurityHashInInterceptor} getDeviceId={getDeviceId} removeSocketDisconnectToaster={removeSocketDisconnectToaster} getRefenceData={getRefenceData} createSocketConnection={createSocketConnection} showCustomNotification={showCustomNotification} markNotificationAsRead={markNotificationAsRead} foregroundBackgroundActivityPayloadRef={foregroundBackgroundActivityPayloadRef} showOverlayToaster={showOverlayToaster} getSignedUrlForLogger={getSignedUrlForLogger}>

        {/* 
        {(location.pathname !== routes.onboardingWelcome && location.pathname !== routes.loginPage && location.pathname !== routes.forgotPassword ) &&  
          <HeaderTop/>
        } */}
        {/* {!openMenu && <div className={clsx('ghostContainer')}></div>} */}
        <Menu routerContentRef={routerContentRef} isMenuOpen={isMenuOpen}/>
        <div className={clsx('WrapperContent')} >
          <div className={clsx('bgImg')} ref={mainWrapperRef}>
          {backdropOverlay && 
                  <div className='backdropOverlay' />
              }
              {location.pathname !== routes.newUpdate && (
              <div className={openSnackbar ? 'snackBarMarginTop' : ''}>
                <ToastSnackbar />
              </div>
            )}
            <DialogBox parentRef={mainWrapperRef}/>
            {(location.pathname !== routes.loginPage && location.pathname !==  routes.forgotPassword) && <div className={clsx('wrapperOverlay', areRightWindowHeightsEqual && 'fillBorderRightBottom', !!loadComponent && 'fillBorderRightTop', isMenuOpen && 'wrapperMenuBorder')}></div>}
            <div className={clsx('routerContainer')} >
              <div className={clsx('routerContent')} ref={routerContainerRef}>
              {
                showLoader &&
                <div className={`loaderContent ${hasLoginProcessCompleted ? 'loaderMain' : 'loginLoader'}`}>
                  <Loader />
                </div>
              }
              <div className={` ${location.pathname !== routes.loginPage ? 'headerPanel commonHeader' : `loginBody`} ${showLoader && 'loaderRunning'}`} ref={routerContentRef}>
                <Routes>
                    <Route exact path={routes.onboardingWelcome} element={<OnboardingWelcome />} />
                    <Route path={routes.onboardingDetails} element={<OnboardingDetails />} />
                    <Route path={routes.onboardingTnc} element={<OnboardingTnc />} />
                    <Route path={routes.onboardingThankYou} element={<OnboardingThankYou />} />
                    <Route path={routes.loginPage} element={<Login/>} />
                    <Route exact path={routes.forgotPassword} element={<ForgotPassword />} />
                    <Route exact path={routes.changePassword} element={<ChangePassword getDeviceId={getDeviceId}/>} />
                    <Route path={routes.homePage} element={<Home />} />
                    <Route path={routes.TnCPage} element={<Tnc />} />
                    <Route path={routes.successPage} element={<Success />} />
                    <Route path={routes.buyerSettingPage} element={<BuyerSetting getDeviceId={getDeviceId}/>} />
                    <Route path={routes.sellerSettingPage} element={<SellerSetting getDeviceId={getDeviceId}/>} />
                    <Route path={routes.createPoPage} element={<CreatePo />} />
                    <Route path={routes.videoLibrary} element={<VideoLibrary />} />
                    <Route path={routes.orderConfirmationPage} element={<OrderConfirmation />} />
                    <Route path={routes.acceptOrderPage} element={<AcceptOrder />} />
                    <Route path={routes.disputePage} element={<Dispute />} />
                    <Route path={routes.orderPage} element={<Order />} />
                    <Route path={routes.chat} element={<Chat />} />
                    <Route path={routes.impersonateList} element={<Impersonate />} />
                    <Route path={routes.bomUpload} element={<BomUpload />} />
                  </Routes>
              </div>
              </div>
            </div>
          </div>
          <div className='RightContent' ref={rightContentRef}>
              <RightWindow rightWindowRef={rightWindowRef} routerContentRef={routerContentRef}/>
              {/* <img src='/corner2.svg' alt='acrylic' className='acrylicCorner' /> */}
          </div>
        </div>
        
        <MatPopup open={openMatPopup}
          popupClass={{
            root: 'UpdateButton',
            paper: 'dialogContent'
          }}>
          <div className='excitingNews'>
            <label>Exciting News! </label>
            <p>We've added auto-updates to the app. Please uninstall your current app, then download the latest version from <span onClick={openUpdateLink}>here</span>. After this one-time manual update, all future updates will be automatic. Thanks for your support!</p>
          </div>
        </MatPopup>
        </AuthenticationWrapper>
      </ErrorBoundary>
      {/* <div className='bgEllips'></div>
      <div className='bgEllips1'></div>  */}
      {/* <label className="toggle-switch">
                    <input
                        type="checkbox"
                        onChange={handleToggle}
                        checked={theme === 'dark'}
                    />
                    <span className="slider"></span>
                </label> */}
    </div>
    </div>
);
}
  
export default App2;