//@ts-nocheck
import React, { useCallback, useState, KeyboardEvent, useEffect } from 'react';
import styles from './BomUpload.module.scss';
import SearchHeader from '../../SearchHeader';
import BomUploadImage from "../../../assets/New-images/BomUploadImg.png";
import { ReactComponent as BomCrossIcon } from '../../../assets/New-images/bom-icon-close.svg';
import { useDropzone } from 'react-dropzone';
import Game from 'src/renderer2/component/Game/Game';
import { uploadFileAndGetS3Url } from '@bryzos/giss-ui-library';
import usePostBomUploadS3Url from '../../../hooks/usePostBomUploadS3Url';
import { navigatePage } from 'src/renderer2/helper';
import { routes } from 'src/renderer2/common';
import useGetGameScore from 'src/renderer2/hooks/useGetGameScore';
type FileStatus = 'idle' | 'uploading' | 'success' | 'error';

const BomUpload: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [filePreview, setFilePreview] = useState<string | null>(null);
    const [fileStatus, setFileStatus] = useState<FileStatus>('idle');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isDragActive, setIsDragActive] = useState<boolean>(false);
    const [bomUploadId, setBomUploadId] = useState<string | null>(null);

    const { data: getGameScore } = useGetGameScore();
    // console.log('getGameScore', getGameScore)
    const {
        mutate: saveBomUploadS3Url,
        data: saveBomUploadS3UrlData,
        isLoading: isSaveBomUploadS3UrlLoading,
    } = usePostBomUploadS3Url();

    useEffect(() => {
        if (saveBomUploadS3UrlData && saveBomUploadS3UrlData?.bom_upload_id) {
            console.log('saveBomUploadS3UrlData', saveBomUploadS3UrlData);
            setBomUploadId(saveBomUploadS3UrlData.bom_upload_id);
            
        }
    }, [saveBomUploadS3UrlData]);

    const validateFile = (file: File): boolean => {
        const ext = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        const allowedExtensions = ['.xlsx', '.xls', '.csv', '.pdf'];
        
        if (allowedExtensions.indexOf(ext) === -1) {
            setErrorMessage(`${file.name} is not supported. Please upload an Excel, CSV, or PDF file.`);
            return false;
        }
        
        // Check file size (10MB limit)
        if (file.size > 10 * 1024 * 1024) {
            setErrorMessage(`File size exceeds 10MB limit.`);
            return false;
        }
        
        return true;
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
            const file = acceptedFiles[0];
            
            if (!validateFile(file)) {
                setFileStatus('error');
                return;
            }
            
            setFile(file);
            setErrorMessage('');
            setFileStatus('success');
            
            const fileName = file.name.substring(0, file.name.lastIndexOf(".")) || file.name;
            handleUploadBOM(file, fileName);
            
            // Create file preview
            const reader = new FileReader();
            reader.onload = () => {
                setFilePreview(file.name);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleUploadBOM = async (file: File, fileName: string) => {
        const s3Url = await uploadFileAndGetS3Url(file, import.meta.env.VITE_S3_UPLOAD_BOM_BUCKET_NAME, '/bom/', `${import.meta.env.VITE_API_SERVICE}/user/get_signed_url`, fileName, import.meta.env.VITE_ENVIRONMENT);
        const payload = {
            data: {
                actual_file_name: file.name,
                s3_url: s3Url
            }
        }
        saveBomUploadS3Url(payload)
        // setFile(null);
    }

    const handleUpload = () => {
        if (!file) {
            setErrorMessage('Please select a file first.');
            return;
        }
        
        setFileStatus('uploading');
        
        // Simulate upload process - in real app, replace with actual API call
        setTimeout(() => {
            setFileStatus('success');
            // After successful upload, you would typically navigate to a confirmation page
            // or process the file content
        }, 1500);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            open();
        }
    };

    // Add function to clear the uploaded file and reset the form
    const handleClearFile = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent triggering the dropzone
        if(fileStatus === 'idle' || fileStatus === 'error'){  
            console.log('idle section ')
            navigatePage(location.pathname, { path: routes.createPoPage })
        }else{
            setFile(null);
            setFilePreview(null);
            setFileStatus('idle');
            setErrorMessage('');
        }
    };

    const {
        getRootProps,
        getInputProps,
        open,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv'],
            'application/pdf': ['.pdf']
        },
        onDragEnter: () => setIsDragActive(true),
        onDragLeave: () => setIsDragActive(false),
        onDropAccepted: () => setIsDragActive(false),
        onDropRejected: () => setIsDragActive(false)
    });

    const getFileIcon = (fileName: string) => {
        const ext = fileName.substring(fileName.lastIndexOf(".")).toLowerCase();
        if (ext === '.pdf') {
            return '📄';
        } else if (ext === '.xlsx' || ext === '.xls') {
            return '📊';
        } else if (ext === '.csv') {
            return '📋';
        }
        return '📄';
    };

    const renderUploadStatus = () => {
        switch (fileStatus) {
            case 'uploading':
                return <div className={styles.statusMessage}>Uploading file...</div>;
            case 'error':
                return (
                    <div className={styles.errorMessage}>
                        {errorMessage}
                        <button 
                            className={styles.closeButton} 
                            onClick={handleClearFile}
                            aria-label="Clear error"
                        >
                            ✕
                        </button>
                    </div>
                );
            case 'success':
                if (file) {
                    return (
                        <div className={styles.filePreview}>
                            <button 
                                className={styles.closeButton} 
                                onClick={handleClearFile}
                                aria-label="Clear uploaded file"
                            >
                                ✕
                            </button>
                            <div className={styles.fileName}>
                                <span className={styles.fileIcon}>{getFileIcon(file.name)}</span>
                                {file.name}
                            </div>
                            <div className={styles.fileSize}>
                                {(file.size / 1024).toFixed(1)} KB
                            </div>
                        </div>
                    );
                }
                return null;
            default:
                return null;
        }
    };

    return (
        <div>
            <div>
                <SearchHeader />
            </div>
            <div className={styles.bomUploadContainer}>
                <span onClick={(handleClearFile)} className={styles.bomUploadCrossIcon}>
                    <BomCrossIcon />
                </span>
                {true ? 
                    <div>
                        <div>
                            <Game gameScore={getGameScore} bomUploadId={bomUploadId} />
                        </div>
                    </div>
                :
                    <div
                        className={`${styles.bomUploadContent} ${isDragActive ? styles.dragActive : ''} ${isDragReject ? styles.dragReject : ''}`}
                        {...getRootProps()}
                        tabIndex={0}
                        role="button"
                        aria-label="Drop zone for BOM files"
                    >
                        {/* Only show the image and text when no file is uploaded or there's an error */}
                        {(fileStatus === 'idle' || fileStatus === 'error') && (
                            <>
                                <span className={styles.bomUploadImage}>
                                    <img src={BomUploadImage} alt="Upload BOM file" />
                                </span>
                                <span className={styles.bomUploadText}>
                                    DRAG 'N DROP YOUR <br />
                                    BOM ANYWHERE
                                </span>
                            </>
                        )}

                        {renderUploadStatus()}

                        <div className={styles.buttonContainer}>
                            {(fileStatus === 'idle' || fileStatus === 'error') && (
                                <button
                                    className={styles.bomUploadButton}
                                    onClick={open}
                                    onKeyDown={handleKeyDown}
                                    tabIndex={0}
                                >
                                    BROWSE YOUR FILES
                                </button>
                            )}

                            {file && fileStatus !== 'uploading' && (
                                <button
                                    className={styles.uploadNowButton}
                                    onClick={handleUpload}
                                    tabIndex={0}
                                >
                                    UPLOAD NOW
                                </button>
                            )}

                            {file && (fileStatus as FileStatus) === 'success' && (
                                <button
                                    className={styles.clearButton}
                                    onClick={handleClearFile}
                                    tabIndex={0}
                                >
                                    CLEAR & UPLOAD DIFFERENT FILE
                                </button>
                            )}
                        </div>

                        <input {...getInputProps()} multiple={false} />

                        {/* <div className={styles.supportedFormats}>
                        Supported formats: .xlsx, .xls, .csv, .pdf (Max 10MB)
                    </div> */}
                    </div>
                }
            </div>
        </div>
    );
};

export default BomUpload;
