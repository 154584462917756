import React from 'react'

import styles from '../home.module.scss'
import clsx from 'clsx'
import { options } from 'src/renderer2/common'
import { useSearchStore } from '../../../store/SearchStore';

const DomesticRequired = () => {
    const { selectedDomesticOption, setSelectedDomesticOption } = useSearchStore();
    return (
        <div className={styles.domesticReq}>
            <div className={styles.leftFilterTitle}>DOMESTIC REQUIRED</div>
            {options.map((option) => (
                <button
                    key={option.title}
                    className={clsx(styles.filterBtn, {
                        [styles.activeBtn]: selectedDomesticOption === option.value,
                    })}
                    onClick={() => setSelectedDomesticOption(option.value)}
                >
                    {option.title}
                </button>
            ))}
        </div>
    )
}

export default DomesticRequired