import React from 'react';
import styles from './RPMBar.module.scss';
import gradient from '../../assets/New-images/gradiant.svg'
interface RPMBarProps {
  barNumber: number;
  barsToBeFilled: number;
  bracketDivider: number;
}

/**
 * RPMBar component represents a single bar in the RPM visualization
 * with automatic filling based on the current value.
 */
const RPMBar: React.FC<RPMBarProps> = ({
  barNumber,
  barsToBeFilled,
  bracketDivider
}) => {
  /**
   * Calculates how many quarters of the current bar should be filled
   * based on the current value relative to the maximum value
   * @returns {number} Number of quarters to fill (0-4)
   */
  
  /**
   * Determines the gradient color class based on bar position
   * @param position - The position of the bar (1-12)
   * @returns {string} The CSS class name for the gradient
   */
  const getBarColor = (position: number): string => {
    if (position <= 2) return styles.gradientRed;
    if (position <= 4) return styles.gradientOrange;
    if (position <= 8) return styles.gradientYellow;
    return styles.gradientGreen;
  };
  const activeColor = getBarColor(barNumber);
  const getBarNumbers = (): number[] => {
    const barNumbers = Array.from({ length: bracketDivider }, (_, i) => i + 1);
    return barNumbers;
  };
  return (
    <div className={styles.rpmBarContainer}>
      {/* Full bar with 4-part split - RPM indicator */}
      <div className={styles.bar}>
        {/* Four equal parts of the bar */}
        {getBarNumbers().map((partIndex) => {    
          const isActive = ((barNumber - 1) * bracketDivider + partIndex) <= barsToBeFilled;
          return (  <div 
            key={partIndex}
            className={`${styles.barPart}`}
            style={{ 
              backgroundImage: isActive ? `url(${gradient})` : 'none', 
              backgroundPosition: `${((barNumber - 1) / 11) * 100}% center`, 
              width: '100%',
              transition: 'opacity 0.3s ease-in-out'
            }}
          />
        )})}
      </div>
    </div>
  );
};

export default RPMBar;
