import React, { useRef, useState } from 'react'
import ProductDescription from 'src/renderer2/component/ProductDescription/ProductDescription'
import ProductPricing from './productPricing'
import { ProductPricingModel } from 'src/renderer2/types/Search'
import { useGlobalStore } from '@bryzos/giss-ui-library'
import { useSearchStore } from '../../../store/SearchStore'
import clsx from 'clsx'
import { ReactComponent as UsaFlag } from '../../../assets/New-images/ButtonUsaMade.svg';

import styles from '../home.module.scss';

type ProductDescriptionAndPricingState = {
    i: number
    selectedSearchProduct: ProductPricingModel
};

const ProductDescriptionAndPricing: React.FC<ProductDescriptionAndPricingState> = ({ i, selectedSearchProduct }) => {

    const { productMapping }: any = useGlobalStore();
    const { selectedProductsData, setSelectedProductsData, productFeedbackInProgress, focusSingleProduct, setFocusSingleProduct, shortListedSearchProductsData, filterShortListedSearchProductsData } = useSearchStore();

    const productDescriptionAndPricingRef = useRef(null);
    const [mouseOverProductId, setMouseOverProductId] = useState<any>(null);
    const [isMouseOverOnProduct, setIsMouseOverOnProduct] = useState(false)

    const handleSelectSingleProduct = (
        singleProducts: ProductPricingModel,
        focusSingleProduct: { [key: number]: boolean },
        setFocusSingleProduct: React.Dispatch<{ [key: number]: boolean }>
    ) => {
        if(!(productFeedbackInProgress && productFeedbackInProgress.has(singleProducts.id))){
            const singleProduct: ProductPricingModel[] = selectedProductsData ?? [];
            const isDuplicate = singleProduct.some((data: ProductPricingModel) => data.id === singleProducts.id);
            let singleProductData;
            if (isDuplicate) {
                delete focusSingleProduct[singleProducts.id];
                singleProductData = singleProduct.filter((data: ProductPricingModel) => data.id !== singleProducts.id)
            } else {
                singleProductData = [...singleProduct, singleProducts]
                setFocusSingleProduct({ ...focusSingleProduct, [singleProducts.id]: true });
            }
            setSelectedProductsData(singleProductData);
        }
    }
    
    const handleSelectAllLinesOnClick = () => {
        const spreadSelectedSearchProductsData = [...shortListedSearchProductsData];
        let selectAllLinesObj: any = {};
        const filterSelectedProductData = spreadSelectedSearchProductsData.filter(({ id }) => {
            if (!(productFeedbackInProgress && productFeedbackInProgress.has(id))) {
                selectAllLinesObj[id] = true;
                return true;
            }
            return false;
        })
        setSelectedProductsData(filterSelectedProductData);
        setFocusSingleProduct(selectAllLinesObj);
    }

    const handleUnselectAllLinesOnClick = () => {
        setSelectedProductsData([]);
        setFocusSingleProduct({});
    }

    return (
        <div
            className={styles.selectedSearchProductTop}
            onMouseOver={() => { setMouseOverProductId(selectedSearchProduct.id) }}
            onMouseOut={() => { setMouseOverProductId(null) }}
            ref={productDescriptionAndPricingRef}
        >
            {((mouseOverProductId === selectedSearchProduct.id && focusSingleProduct[selectedSearchProduct.id]) && ((filterShortListedSearchProductsData.length - (productFeedbackInProgress?.size ?? 0)) > 1)) &&
                (
                    <>
                        {((selectedProductsData.length + (productFeedbackInProgress?.size ?? 0)) === filterShortListedSearchProductsData.length) ?
                            <div className={styles.selectAllLines} onClick={handleUnselectAllLinesOnClick}>UNSELECT ALL LINES</div>
                            :
                            <div className={styles.selectAllLines} onClick={handleSelectAllLinesOnClick}>SELECT ALL LINES</div>
                        }
                    </>
                )
            }
            <div
                className={clsx(styles.productDescriptionMain, (!(productFeedbackInProgress && productFeedbackInProgress.has(selectedSearchProduct.id)) &&focusSingleProduct[selectedSearchProduct.id]) ? styles.clickToShare : '')}
                key={selectedSearchProduct.id}
                onClick={() => handleSelectSingleProduct(selectedSearchProduct, focusSingleProduct, setFocusSingleProduct)}
                onMouseOver={()=> {setIsMouseOverOnProduct(true)}}
                onMouseLeave={()=>{setIsMouseOverOnProduct(false)}}
            >
                <ProductDescription product={selectedSearchProduct} />
                <ProductPricing 
                    product={selectedSearchProduct}
                    productDescriptionAndPricingRef={productDescriptionAndPricingRef} 
                    isMouseOverOnProduct = {isMouseOverOnProduct}
                />
            </div>
        </div>
    )
}

export default ProductDescriptionAndPricing;