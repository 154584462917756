import React, { useEffect, useState, useRef } from 'react';
import styles from './PricingBracket.module.scss';
import RPMBars from './RPMBars';
import WeightIndicator from './WeightIndicator';
import GearSelector from './GearSelector';

interface PricingBracketProps {
  currentWeight: number;
  minWeight: number;
  numberOfBrackets: number;
  bracketDivider: number;
  numberOfGears: number;
  totalNumberOfBarsFilled: number;
  animationTime: number;
}

const PricingBracket: React.FC<PricingBracketProps> = ({
  currentWeight,
  minWeight,
  numberOfBrackets,
  bracketDivider,
  numberOfGears,
  totalNumberOfBarsFilled,
  animationTime
}) => {
  const [barCounter, setBarCounter] = useState(0);
  const [selectedGear, setSelectedGear] = useState(0);

  const animationTimePerBar = animationTime / (bracketDivider * numberOfBrackets);

  const animationInProgress = useRef<boolean>(false);

  useEffect(() => {
    animateSpeedoMeter();
  }, [totalNumberOfBarsFilled]);

  const animateSpeedDown = async (i: number): Promise<void> => {
    
    return new Promise((resolve, reject) => {
    if(i > totalNumberOfBarsFilled + 10)
      setTimeout(() => {
        setBarCounter(i);
        resolve();
      }, animationTimePerBar);
      else
      setTimeout(() => {
        setBarCounter(i);
        resolve();
      }, animationTimePerBar * 2);
    });
  };

  const animateSpeedUp = async (i: number): Promise<void> => {
    return new Promise((resolve, reject) => {
    if(i < totalNumberOfBarsFilled - 10)
      setTimeout(() => {
        setBarCounter(i);
        resolve();
      }, animationTimePerBar);
      else
      setTimeout(() => {
          setBarCounter(i);
          resolve();
        }, animationTimePerBar * 2);
    });
  };

  const animateSpeedoMeter = async() => {
    animationInProgress.current = false;
    await new Promise(resolve => setTimeout(resolve, 50));
    animationInProgress.current = true;
    
    // if(totalNumberOfBarsFilled === 0){
    //   setSelectedGear(0);
    //   setBarCounter(0);
    //   return;
    // }
    let _selectedGear = Math.ceil(barCounter / (bracketDivider * numberOfBrackets));
    if(totalNumberOfBarsFilled > barCounter){
      for(let i = barCounter + 1; i <= totalNumberOfBarsFilled; i++){
        if (!animationInProgress.current) return;
        const hasGearShifted = i % (bracketDivider * numberOfBrackets) === 1;
        if(hasGearShifted || i === 1){
          _selectedGear++;

          setSelectedGear(_selectedGear >= numberOfGears ? numberOfGears : _selectedGear);
        }
        await animateSpeedUp(i);
      }
    }
    else{
      for(let i = barCounter -1; i >= totalNumberOfBarsFilled; i--){
        if (!animationInProgress.current) return;

        const hasGearShifted = i % (bracketDivider * numberOfBrackets) === 0;
        if(hasGearShifted){
          _selectedGear--;
          setSelectedGear(_selectedGear <= 0 ? 0 : _selectedGear);
        }
        await animateSpeedDown(i);
      }
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.weightContainer}>
        <div
          className={styles.barsContainer}
        >
          <div className={styles.weightIndicator}>
            <WeightIndicator
              currentWeight={currentWeight}
              minWeight={minWeight}
            />
          </div>

          <RPMBars
            barCounter={barCounter}
            numberOfBrackets={numberOfBrackets}
            bracketDivider={bracketDivider}
          />
          <div className={styles.title}><span>PRICING</span> <span>BRACKET</span></div>

          <GearSelector
            selectedNumber={selectedGear}
            numberOfGears={numberOfGears}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingBracket;
