
const InputWrapper = ({children}) => {
    return(
        <>
            {children}
        </>
    )

}

export default InputWrapper;