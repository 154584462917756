import React, { useState, useRef, useEffect } from 'react';

interface PseudoScrollProps {
  children: React.ReactNode;
  scrollPosition: number;
  maxScrollHeight: number;
  onScrollChange: (newScrollPosition: number) => void;
  height: string | number;
  width: string | number;
  thumbColor?: string;
  thumbHeight?: number;
  thumbWidth?: number;
  setIsDraggingScroll?: (isDragging: boolean) => void;
  thumbMarginTop?: number;
  thumbMarginBottom?: number;
}

const PseudoScroll: React.FC<PseudoScrollProps> = ({
  children,
  scrollPosition,
  maxScrollHeight,
  onScrollChange,
  height,
  width,
  thumbColor = 'rgba(0, 0, 0, 0.7)',
  thumbHeight = 10,
  thumbWidth = 10,
  setIsDraggingScroll,
  thumbMarginTop = 0,
  thumbMarginBottom = 0
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartY, setDragStartY] = useState(0);
  const [startScrollPosition, setStartScrollPosition] = useState(0);
  
  const wrapperRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const lastScrollPosition = useRef(scrollPosition);
  const animationFrameId = useRef<number | null>(null);
  
  // Update thumb position with margin consideration
  useEffect(() => {
    const updateThumbPosition = () => {
      if (!wrapperRef.current || !thumbRef.current) return;
      
      const containerHeight = wrapperRef.current.clientHeight;
      // Calculate the available track height (container minus margins and thumb size)
      const trackHeight = containerHeight - (thumbMarginTop + thumbMarginBottom) - thumbHeight;
      
      // Calculate the scroll ratio (0 to 1)
      const scrollRatio = scrollPosition / Math.max(maxScrollHeight, 1);
      
      // Calculate the new position with margin offset
      const thumbPosition = thumbMarginTop + (scrollRatio * trackHeight);
      
      // Apply the position
      thumbRef.current.style.transform = `translateY(${thumbPosition}px)`;
      lastScrollPosition.current = scrollPosition;
    };
    
    // Use requestAnimationFrame for smoother updates
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
    
    animationFrameId.current = requestAnimationFrame(updateThumbPosition);
    
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [scrollPosition, maxScrollHeight, thumbHeight, thumbWidth, thumbMarginTop, thumbMarginBottom]);
  
  // Call setIsDraggingScroll when isDragging changes
  useEffect(() => {
    if (setIsDraggingScroll) {
      setIsDraggingScroll(isDragging);
    }
  }, [isDragging, setIsDraggingScroll]);
  
  // Handle mouse events for dragging the thumb with margin consideration
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !wrapperRef.current) return;
      
      e.preventDefault();
      const containerHeight = wrapperRef.current.clientHeight;
      const deltaY = e.clientY - dragStartY;
      
      // Calculate the available track height considering margins
      const trackHeight = containerHeight - (thumbMarginTop + thumbMarginBottom) - thumbHeight;
      
      // Calculate scroll position ratio based on track height
      const scrollRatio = maxScrollHeight / trackHeight;
      
      // Calculate new scroll position with adjusted ratio
      const newScrollPosition = Math.max(
        0, 
        Math.min(
          maxScrollHeight,
          startScrollPosition + (deltaY * scrollRatio)
        )
      );
      
      // Send the new scroll position back to parent
      onScrollChange(newScrollPosition);
    };
    
    const handleMouseUp = () => {
      setIsDragging(false);
    };
    
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStartY, maxScrollHeight, onScrollChange, startScrollPosition, thumbHeight, thumbWidth, thumbMarginTop, thumbMarginBottom]);
  
  const handleThumbMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    setDragStartY(e.clientY);
    setStartScrollPosition(scrollPosition);
  };
  
  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'relative',
        width,
        height,
        overflow: 'hidden'
      }}
    >
      {/* Content container */}
      <div style={{ width: '100%', height: '100%' }}>
        {children}
      </div>
      
      {/* Custom scrollbar track - shows the full scrollable area */}
      <div
        style={{
          position: 'absolute',
          right: 4,
          top: thumbMarginTop,
          bottom: thumbMarginBottom,
          width: `${thumbWidth}px`,
          backgroundColor: 'transparent',
          borderRadius: `${thumbWidth/2}px`,
          zIndex: 99
        }}
      />
      
      {/* Custom scrollbar thumb (black circle) */}
      <div
        ref={thumbRef}
        style={{
          position: 'absolute',
          right: 4,
          top: 0, // Initial position - will be moved with transform
          width: `${thumbWidth}px`,
          height: `${thumbHeight}px`,
          backgroundColor: thumbColor,
          borderRadius: `${thumbWidth/2}px`,
          cursor: 'pointer',
          zIndex: 100,
          willChange: 'transform', // Hint for browser optimization
          transition: isDragging ? 'none' : 'transform 0.1s cubic-bezier(0.33, 1, 0.68, 1)'
        }}
        onMouseDown={handleThumbMouseDown}
      />
    </div>
  );
};

export default PseudoScroll; 