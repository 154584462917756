// @ts-nocheck
import { useMutation } from "@tanstack/react-query";
import { reactQueryKeys } from "../common";
import axios from "axios";

const useSaveLoginActivityAnalytics = () => {
  return useMutation(async ({email,deviceId,ui_version,os_version,reason,last_login_app_version}) => {
        const payload = {
            "data":{
                email_id: email ?? "",
                device_id: deviceId ?? "",
                ui_version: ui_version ?? "",
                os_version: os_version ?? "",
                reason: reason ?? "",
                last_login_app_version: last_login_app_version ?? ""
            }
        }
        try {
            const response = await axios.post(`${import.meta.env.VITE_API_SERVICE}/user/cognito-logs`, payload);
        } catch (error:any) {
            console.log(error)
        }
});
};

export default useSaveLoginActivityAnalytics;
