//@ts-nocheck
import React from "react";
import { routes } from "../common";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { OTPInputComponent } from "../component/OTPInput";
import { useLocation, useNavigate } from "react-router";
import { commomKeys, snackbarSeverityType, usePostMigrateToPassword,forgotPasswordConst, useGlobalSignoutApi, channelWindowList, useGlobalStore, useCheckEmailApproval, emojiRemoverRegex, getChannelWindow } from "@bryzos/giss-ui-library";
import { useEventListener } from '@mantine/hooks';
import useSnackbarStore from "../component/Snackbar/snackbarStore";
import { navigatePage } from "../helper";
import { ReactComponent as ShowPassIcon } from '../../assets/New-images/icon-eye-show.svg';
import { ReactComponent as ShowPassIconHover } from '../../assets/New-images/icon-eye-show-hover.svg';
import { ReactComponent as HidePassIcon } from '../../assets/New-images/icon-eye-hide.svg';
import { ReactComponent as HidePassIconHover } from '../../assets/New-images/icon-eye-hide-hover.svg';
import { ReactComponent as BLogo } from '../assets/New-images/New-Bryzos-Logo.svg';
import { ReactComponent as SubmitIcon } from '../assets/New-images/Login-Icon.svg';
import { ReactComponent as SubmitIconActive } from '../assets/New-images/Login-Icon-Active.svg';

import CustomTextField from "../component/CustomTextField";
import InputWrapper from "../component/InputWrapper";
import CustomPasswordField from "../component/CustomPasswordField";
import NewBryzosLogo from "../assets/New-images/New-Logo.png";

function ForgotPassword() {
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const[showOtp, setShowOtp] = useState(false);
  const[otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const location = useLocation();

  return (
    <>
    {(!otp) ?
      <EmailOtpComponent
        showPasswordInput={showPasswordInput}
        showError={showError}
        showOtp={showOtp}
        otp={otp}
        setShowPasswordInput={setShowPasswordInput}
        setShowError={setShowError}
        setShowOtp={setShowOtp}
        setOtp={setOtp}
        setEmail={setEmail}
        email={email}/> :
        <ConfirmPassword
        otp={otp}
        setOtp={setOtp}
        email={email} />
    }
    </>
  )
}

const EmailOtpComponent = ({showPasswordInput, showError, showOtp, setShowPasswordInput, setShowError, setShowOtp, setOtp, setEmail, email}) => {
    const checkEmailApproval = useCheckEmailApproval();

    const {
      register,
      watch,
      handleSubmit,
      setError,
      clearErrors,
      formState: { errors, isValid, submitCount },
    } = useForm({
      resolver: yupResolver(
        yup
          .object({
            email: yup
              .string()
              .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                {
                  message: "Please enter valid email",
                }
              )
              .required("Email is Required"),
          })
          .required()
      ),
      mode: "onSubmit",
      defaultValues:{
        email: email
      }
    });
    const [disableSendOtp,setDisableSendOtp] = useState(false);
    const [isResendOtpBtnDisabled, setIsResendOtpBtnDisabled] = useState(false);
    const {showToastSnackbar, resetSnackbarStore, setSnackbarOpen} = useSnackbarStore();
    const navigate = useNavigate();
    useEffect(()=>{
      setShowOtp(false);
      setIsResendOtpBtnDisabled(false);
    },[])

    const handleKeydown =  (event) =>  {
      if (event.key === 'Enter') {
        setShowError(true);
        if(!disableSendOtp){
          sendOtpHandler()
        }
      }
      else{
        setShowError(false);
      }
    }

    const sendOtpHandler = async () => {
      console.log("Forgot Password sending OTP")
      setDisableSendOtp(true)
      if(!errors.email && watch('email').length){
        const email = watch('email');
        checkEmailApproval.mutateAsync(email).then(async(res)=>{
          if(res.data.data.error_message){
            showToastSnackbar(res.data.data.error_message, snackbarSeverityType.alert,[{name:"OK", handler: handleSnackbarClose}], null);
          }else if(res.data?.data){
            handleForgotPassword(email);
          }else{
            onEmailError(forgotPasswordConst.emailApprovalError)
            setDisableSendOtp(false)
          }
        })
        .catch((e)=>{
          console.error("sendOtpHandler ", e);
          onEmailError(commomKeys.errorContent)
          setDisableSendOtp(false)
        })
      }
      else{
        setShowError(true);
        setDisableSendOtp(false)
      }
    };

    const handleSnackbarClose = ()=>{
      setSnackbarOpen(false);
      navigatePage(location.pathname, {path: routes.loginPage})
    }

    const handleForgotPassword = async (email: string) => {
      try {
        console.log("handleForgotPassword requesting OTP")
        await Auth.forgotPassword(email);
        showToastSnackbar(forgotPasswordConst.otpSent, snackbarSeverityType.success, null, resetSnackbarStore, 3000);
        setShowOtp(true);
        setEmail(email);
        console.log("handleForgotPassword OTP request Sent")
        if(showOtp) setIsResendOtpBtnDisabled(true);
      } catch (error: any) {
        console.error("handleForgotPassword ", error);
        onEmailError(error.message)
      }
    }

    const onEmailError = (message: string) => {
      setShowOtp(false);
      setShowError(true);
      setError("email", { message })
    }

    const handleFocus = () => {
      setShowPasswordInput(true);
    };

    const toggleRenderer = () => {
      if(!errors?.email){
        const otp = watch('otp');
        if(otp.length === 6){
          setOtp(otp);
          clearErrors();
        }
        else
        setError('otp', {message: 'Incorrect OTP'})
      }
      else{
        setShowError(true);
      }
    }

    const keydownRef = useEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        if(showOtp)
          handleSubmit(toggleRenderer)()
        else
        handleKeydown(event);
      }
    });

    const cancelReset = () => {
      navigate(routes.loginPage);
    }

    return (
        <div className="resetPasswordWrapper" ref={keydownRef}>
          {(!showOtp && cancelReset) && <>
          <div className="loginHelp">
          <span className='dragArea'></span>
            <span className='logoLogin'><img className="bryzosLogo" src={NewBryzosLogo} alt="Logo" /></span>
            <span className="loginHelpDesc">
              <div className="loginHelpTitle">LOGIN HELP</div>
              <p>
                Enter your email in the field on the right and press the arrow to submit. Shortly after submitting, you will receive an email from Bryzos. That email will contain a one-time-code that you will use to create a new password.
              </p>
            </span>
          </div>
          <div className="emailInput">
            <span className="forgetEmailInput">
                <InputWrapper>
                            <CustomTextField register={register("email")} autoFocus
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                register("email").onChange(e);
                                clearErrors("email");
                            }}
                            placeholder='Enter Your Email' errorInput={errors.email?.message} />
            </InputWrapper>
            <span className="cancelReset" onClick={()=>{cancelReset()}}>Cancel Reset</span>
            </span>
            <span className='submitArrow'>
              <button onClick={() => { sendOtpHandler() }} disabled={!isValid}>{!isValid ? <SubmitIcon/> : <SubmitIconActive/> }</button>
            </span>
          </div>
          </>
          }
         
          {showOtp && <div className="forgetOtp">
          <div className="loginHelp">
            <span className='dragArea'></span>
            <span className='logoLogin'><img className="bryzosLogo" src={NewBryzosLogo} alt="Logo" /></span>
            <span className="loginHelpDesc">
              <div className="loginHelpTitle">LOGIN HELP</div>
              <p>
                The one-time-password (OTP) has been emailed to user@domain.com.  Enter the OTP in the cell on the right.
              </p>
              {isResendOtpBtnDisabled ? <button className="resendOtp" >Code has been re-sent to {watch('email')}</button> : <button className="resendOtp" onClick={() => { sendOtpHandler() }}>Re-Send Code</button>}
            </span>
          </div>
          <div className="emailInput">
            <span className="forgetEmailInput">
            <InputWrapper>
                            <CustomTextField register={register("otp")}
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                register("otp").onChange(e);
                                setIsResendOtpBtnDisabled(false);
                            }}
                            placeholder='Enter Code' maxLength={6} mode="wholeNumber" errorInput={errors.otp?.message} />
            </InputWrapper>
            <span className="cancelReset" onClick={()=>{cancelReset()}}>Cancel Reset</span>
            </span>
            <span className='submitArrow'>
              <button onClick={()=>{toggleRenderer()}} disabled={!(watch('otp')?.length === 6)}>{!(watch('otp')?.length === 6) ? <SubmitIcon/> : <SubmitIconActive/> }</button>
            </span>
           
          </div>
              
            {/* <p className='errorText errorForgetPass'>{errors.otp?.message}</p> */}
          </div>}
          <div className='bgEllips'></div>
            <div className='bgEllips1'></div>
        </div>
    );
}

const ConfirmPassword = ({otp, setOtp, email})=>{
  const changePasswordConfirmation = usePostMigrateToPassword();
  const navigate = useNavigate();
  const {showToastSnackbar, resetSnackbarStore} = useSnackbarStore();
  const globalSignout = useGlobalSignoutApi();
  const { setShowLoader } = useGlobalStore();
  const [passwordVisibility, setPasswordVisibility] = useState({
    password1: true,
    password2: true,
  });
  const {
    register,
    watch,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    getValues,
    reset,
    formState: { errors, isValid, submitCount },
  } = useForm({
    resolver: yupResolver(
      yup
        .object({
            newPassword: yup.string().required().test('len', 'Password must be 6 digits', val => val?.length >= 6),
            confirmPassword: yup.string().required().test("isRequired", forgotPasswordConst.passwordMismatch, function(value) {
              const password = this.parent.newPassword;
              if(password?.trim() === value?.trim()) return true;
              return false;
            })
        })
        .required()
    ),
    mode: "onSubmit",
    defaultValues: {
      newPassword: '',
      confirmPassword: ''
    }
  });
  const channelWindow = getChannelWindow();


  const setNewPasswordHandler = async (data) => {
    try {
      console.log("setNewPasswordHandler requesting new password")
      const newPassword = data.newPassword;
      const confirmPassword = data.confirmPassword;
      if(newPassword === confirmPassword){
        setShowLoader(true);
        const res = await Auth.forgotPasswordSubmit(email, otp, confirmPassword);
        if(res === 'SUCCESS'){
          await changePasswordConfirmation.mutateAsync(email);
          let device_id = '';
          if (channelWindow?.getDeviceId) {
              device_id = window.electron.sendSync({ channel: channelWindow.getDeviceId });
          }else{
            device_id = "No device_id found"
          }
          //call gobalsignout api
          await globalSignout.mutateAsync({data:{email_id: email,device_id}});
          setShowLoader(false);
          showToastSnackbar(forgotPasswordConst.passwordChanged, snackbarSeverityType.success, null, resetSnackbarStore, 3000); 
          navigate(routes.loginPage);
          console.log("setNewPasswordHandler new password requested")
        }
      }else{
        console.log("setNewPasswordHandler password mismatch")
        setError("confirmPassword", { message: forgotPasswordConst.passwordMismatch })
        return
      }
    } catch (error) {
      console.error("setNewPasswordHandler:error ",error);
      let content = commomKeys.errorContent;
      if(error.message === forgotPasswordConst.invalidVerificationCode)
        content = forgotPasswordConst.invalidVerificationCode;
      else if(error.message === forgotPasswordConst.attemptExceeded)
        content = forgotPasswordConst.attemptExceeded;
      showToastSnackbar(content, snackbarSeverityType.alert, null, resetSnackbarStore, 3000); 
      setOtp('');
      setShowLoader(false);
      setValue('newPassword', '')
      setValue('confirmPassword', '')
    }
  };

  const keydownRef = useEventListener('keydown', function (event) {
    if (event.key === 'Enter') {
        handleSubmit(setNewPasswordHandler)()
    }
  });

  const handlePasswordBlur = () => {
    const newPassword = getValues('newPassword')?.trim();
    const confirmPassword = getValues('confirmPassword')?.trim();
    if(newPassword?.length && confirmPassword?.length){
        if (newPassword === confirmPassword) {
          clearErrors(["newPassword", "confirmPassword"]);
        } else{ 
            setError("newPassword", { message: "Password does not match!" });
        }
    }
  }

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
        ...prevState,
        [field]: !prevState[field],
    }));
  };
  const cancelReset = () => {
    navigate(routes.loginPage);
  }

  return (
    <div className="loginWrapper" ref={keydownRef}>
      <span className='dragArea'></span>
       <span className='logoLogin'><img className="bryzosLogo" src={NewBryzosLogo} alt="Logo" /></span>
      <div className="togglePassWrapperMain">
        <InputWrapper>
          <CustomPasswordField 
            onChange={(e) => {
              e.target.value = e.target.value.trim();
              register('newPassword').onChange(e);
            }}
            placeholder={'New Password'}
            isConfirmPassword={true}
            register={register("newPassword")}
            currentText={watch('newPassword')}
            targetText={watch('confirmPassword') ?? ''}
            onBlur={(e) => {
              register("newPassword").onBlur(e);
              handlePasswordBlur()
          }}

          /> 
        </InputWrapper>
        {/* <span className="dflex">
          <input type={passwordVisibility.password1 ? 'password' : 'text'} {...register("newPassword")} placeholder='Enter new password'
            autoFocus
            onChange={(e) => {
              e.target.value = e.target.value.trim();
              register('newPassword').onChange(e);
            }}
            onBlur={(e) => {
                register("newPassword").onBlur(e);
                handlePasswordBlur()
            }} 
          />
          <button className="showPassBtn" onClick={() => togglePasswordVisibility('password1')}>
              {passwordVisibility.password1 ? <ShowPassIcon /> : <HidePassIcon />}
          </button>
        </span> */}
        {/* {(errors.newPassword && <p className='errorText errorForgetPass'>{errors.newPassword?.message}</p>)
        ||
        (errors.confirmPassword && <p className='errorText errorForgetPass'>{errors.confirmPassword?.message}</p>)
        } */}
        {/* {(!(errors.confirmPassword || errors.newPassword) && watch('confirmPassword')?.length>0 && watch('newPassword')?.length>0) && <button className="pressBtn" onClick={()=>{handleSubmit(setNewPasswordHandler)()}}>Press ENTER to save password</button>} */}
      </div>
      <div className="togglePassWrapper">
      <InputWrapper>
          <CustomPasswordField 
            onChange={(e) => {
              e.target.value = e.target.value.trim();
              register('confirmPassword').onChange(e);
            }}
            placeholder={'Re-Enter New Password'}
            isConfirmPassword={true}
            register={register("confirmPassword")}
            currentText={watch('confirmPassword')}
            targetText={watch('newPassword') ?? ''}
            onBlur={(e) => {
              register("confirmPassword").onBlur(e);
              handlePasswordBlur()
          }}
            
          /> 
        </InputWrapper>
        {/* <span className="dflex">
          <input type={passwordVisibility.password2 ? 'password' : 'text'} {...register("confirmPassword")} placeholder='Confirm password'
            onChange={(e) => {
              e.target.value = e.target.value.trim();
              register('confirmPassword').onChange(e);
            }}
            onBlur={(e) => {
                register("confirmPassword").onBlur(e);
                handlePasswordBlur()
            }}
          />
          <button className="showPassBtn" onClick={() => togglePasswordVisibility('password2')}>
              {passwordVisibility.password2 ? <ShowPassIcon /> : <HidePassIcon />}
          </button>
        </span> */}
        <span className="cancelReset" onClick={()=>{cancelReset()}}>Cancel Reset</span>
      </div>
      <span className='submitArrow'>
        <button  disabled={!isValid} onClick={()=>{handleSubmit(setNewPasswordHandler)()}}>{!isValid ? <SubmitIcon/> : <SubmitIconActive/> }</button>
        </span>
        <div className='bgEllips'></div>
        <div className='bgEllips1'></div>
    </div>
  );
} 

export default ForgotPassword;