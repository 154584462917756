import React from 'react';
import styles from './WeightIndicator.module.scss';
import clsx from 'clsx';
import { formatCurrencyWithComma } from '@bryzos/giss-ui-library';

interface WeightIndicatorProps {
  currentWeight: number;
  minWeight: number;
}

/**
 * WeightIndicator component displays the current weight and minimum weight requirements
 * with visual feedback for selected gear and bracket positions.
 */
const WeightIndicator: React.FC<WeightIndicatorProps> = ({
  currentWeight,
  minWeight
}) => {
  const isBelowMinimum = !currentWeight ? true : currentWeight < minWeight;

  return (
    <div className={styles.container}>
      <div className={clsx(styles.content, isBelowMinimum && styles.showMininumWeightImg)}>
        <div className={styles.weightDisplay}>
          <span className={clsx(styles.weightValue, isBelowMinimum && styles.weightValueBelowMinimum)}><span>{formatCurrencyWithComma(currentWeight.toString())}</span>
          <span className={styles.weightInfoText}>LBS</span>
          </span>
          {
            isBelowMinimum && (
              <div className={styles.weightInfo}>
                <span className={styles.weightMinimum}>{minWeight} LB MIN</span>
                <span className={styles.weightOrder}>ORDER</span>
              </div>
             )
          }
        
        </div>
      </div>
    </div>
  );
};

export default WeightIndicator;
