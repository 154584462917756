import React, { useEffect } from 'react';

const EventLogger = () => {
    useEffect(() => {
        if(import.meta.env.VITE_ENVIRONMENT !== 'dev'){
            const handleClick = (event: MouseEvent) => {

                const target = event.target as HTMLElement;
                
                const elementPath = event.composedPath
                    ? event.composedPath().map((el) => (el as HTMLElement).tagName).join(' > ')
                    : target.tagName;
                console.log('Element path:', elementPath);
                console.log('Element text:', target.innerText?.replace(/\r?\n/g, '\\n'));
                console.log('Element class:', target.className);
            };

            // Add event listener to document
            document.addEventListener('click', handleClick);

            // Cleanup listener on component unmount
            return () => document.removeEventListener('click', handleClick);
        }
    }, []);

    return null; // No UI output, purely for handling events
};

export default EventLogger;
