import React, { RefObject, useEffect, useRef, useState } from 'react'

import styles from '../home.module.scss';
import { MenuItem, Select, Tooltip } from '@mui/material';
import { ReactComponent as FeedbackHoverArrow } from '../../../assets/New-images/FeedbackArrow.svg';
import { ReactComponent as ZipBg } from '../../../assets/New-images/Zip_Bg.svg';
import { ReactComponent as ArrowUp } from '../../../assets/New-images/UpArrow.svg';
import { ReactComponent as ArrowDown } from '../../../assets/New-images/DownArrow.svg';
import { ReactComponent as FeedbackArrow } from '../../../assets/New-images/feedback-arrow.svg';
import { useSearchStore } from '../../../store/SearchStore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { feedbackUnits } from '../../../common';
import { ProductPricingModel } from 'src/renderer2/types/Search';
import clsx from 'clsx';
import { formatCurrencyWithComma, formatToTwoDecimalPlaces } from '@bryzos/giss-ui-library';
import { priceFormatter } from '../../../helper';

type PricingFeedbackState = {
    product: ProductPricingModel,
    productDescriptionAndPricingRef: RefObject<HTMLDivElement>,
    isMouseOverOnProduct: boolean
};

const PricingFeedback: React.FC<PricingFeedbackState> = ({ product, productDescriptionAndPricingRef, isMouseOverOnProduct }) => {

    const { register, handleSubmit, setValue, watch, formState: { errors, isValid } } = useForm({
        defaultValues: {
            productPrice: "",
            zipCode: "",
            productPriceUnit: "cwt",
            productPCPrice: ""
        },
        resolver: yupResolver(
            yup.object({
                productPrice: yup.string().required('Product Price is required'),
                zipCode: yup.string(),
                productPriceUnit: yup.string(),
                productPCPrice: yup.string(),
                productId: yup.number(),
            }).required()
        ),
        mode: 'onSubmit',
    });

    const { saveFeedbackMap, setSaveFeedbackMap, focusSingleProduct, searchZipCode, productFeedbackInProgress, setProductFeedbackInProgress, selectedProductsData, setSelectedProductsData, selectedPriceUnit, orderSizeSliderValue, } = useSearchStore();

    const savedFeedbackData = saveFeedbackMap[product.id];
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [FeedbackHover, setFeedbackHoverArrow] = useState(true);
    const [isFeedbackEdit, setIsFeedbackEdit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [productPricePlaceholder, setProductPricePlaceholder] = useState('');
    const containerRef = useRef<HTMLDivElement>(null);
    const [focusableElements, setFocusableElements] = useState<HTMLElement[]>([]);
    const [isBlurred, setIsBlurred] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false)

    useEffect(() => {
        const handleMouseEnter = (event: MouseEvent) => {
            if (!isClicked && !saveFeedbackMap[product.id] && !focusSingleProduct[product.id]) {
                setShowFeedbackForm(true);
            }
        }
        const handleMouseLeave = (event: MouseEvent) => {
            if (!isClicked && !saveFeedbackMap[product.id] && !focusSingleProduct[product.id]) {
                setShowFeedbackForm(false);
            }
        }
        if (productDescriptionAndPricingRef?.current) {
            productDescriptionAndPricingRef.current.addEventListener('mouseenter', handleMouseEnter);
            productDescriptionAndPricingRef.current.addEventListener('mouseleave', handleMouseLeave);
        }
        return () => {
            if (productDescriptionAndPricingRef?.current) {
                productDescriptionAndPricingRef.current.removeEventListener('mouseenter', handleMouseEnter);
                productDescriptionAndPricingRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [isClicked, saveFeedbackMap, focusSingleProduct, setShowFeedbackForm])

    useEffect(() => {
        setValue('productPrice', priceFormatter(product));
        setValue('productPriceUnit', selectedPriceUnit)
        setValue('zipCode', searchZipCode)
        setProductPricePlaceholder(priceFormatter(product))
    }, [])

    useEffect(() => {
        if (!isClicked) {
            setValue('productPrice', priceFormatter(product))
            setValue('productPriceUnit', selectedPriceUnit)
            setValue('zipCode', searchZipCode)
            setValue('productPCPrice', '')
            setProductPricePlaceholder(priceFormatter(product))
        }
    }, [isClicked, selectedPriceUnit, searchZipCode, orderSizeSliderValue])

    useEffect(() => {
        if (isClicked) {
            let feedbackSet;
            if (productFeedbackInProgress) {
                productFeedbackInProgress.add(product.id);
                feedbackSet = new Set([...productFeedbackInProgress])
            } else {
                feedbackSet = new Set([product.id]);
            }
            setProductFeedbackInProgress(feedbackSet);
        } else {
            setProductFeedbackInProgress(null);
        }
    }, [isClicked])

    useEffect(() => {
        if (isSubmitted) {
            setTimeout(() => {
                setIsSubmitted(false);
            }, 1400);
        }
    }, [isSubmitted])

    useEffect(() => {
        if (containerRef.current) {
            const orderedElements = [
              document.getElementById("product-price"),
              document.getElementById("feedback-zipcode"),
              document.getElementById("product-pc-price"),
              document.getElementById("feedback-submit"),
              document.getElementById("feedback-cancel"),
            ].filter(Boolean) as HTMLElement[];
            setFocusableElements(orderedElements);
        }
    }, [watch('productPrice')]);

    useEffect(()=>{
        if((isFeedbackEdit && isClicked)){
            document.getElementById("product-price")?.focus();
        }
    },[isFeedbackEdit, isClicked])

    const handlePriceClick: React.MouseEventHandler<HTMLSpanElement> | undefined = (event) => {
        event.stopPropagation(); // Prevents event bubbling
        if (!isClicked) {
            setValue('productId', product.id);
            setValue('productPrice', '')
            setValue('productPCPrice', '')
            setFeedbackHoverArrow(false);
            setIsClicked(true); // Mark as clicked
            setShowFeedbackForm(true);
            document.getElementById("product-price")?.focus();
        }
    };

    const handleCloseClick: React.MouseEventHandler<HTMLButtonElement> | undefined = (event) => {
        event.stopPropagation(); // Prevents event from bubbling up
        setFeedbackHoverArrow(true);
        setIsClicked(false); // Mark as clicked
        setIsFeedbackEdit(false);
        setShowFeedbackForm(false);
    };

    const handleSubmitFeedbackOnclick: React.MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
        e.stopPropagation();
        handleSubmit(submit)();
    }

    const submit = (data: any) => {
        handleTooltipClose(0);
        const feedbackData = { ...data };
        setSaveFeedbackMap({ ...saveFeedbackMap, [feedbackData.productId]: feedbackData });
        setShowFeedbackForm(false);
        setIsClicked(false); // Mark as clicked
        setIsFeedbackEdit(false);
        setIsSubmitted(true);
    }

    const UpDownIcon = () => (
        <div className={styles.arrowWrapper}>
            <ArrowUp />
            <ArrowDown />
        </div>
    );

    const handleFeedBackEdit: React.MouseEventHandler<HTMLSpanElement> | undefined = (e) => {
        e.stopPropagation();
        setOpenTooltip(true);
        setIsFeedbackEdit(true);
        setFeedbackValues(saveFeedbackMap[product.id]);
        setFeedbackHoverArrow(false);
        setShowFeedbackForm(true);
        setIsClicked(true);
        if (focusSingleProduct[product.id]) {
            delete focusSingleProduct[product.id];
            setSelectedProductsData(selectedProductsData.filter((data: ProductPricingModel) => data.id !== product.id));
        }
    }

    const setFeedbackValues = (data: any) => {
        Object.keys(data).forEach((key: any) => {
            setValue(key, data[key]);
        })
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Tab") {
            event.preventDefault();
            event.stopPropagation();

            const currentIndex = focusableElements.indexOf(
                document.activeElement as HTMLElement
            );
            let nextIndex;
            if (event.shiftKey) {
                nextIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
            } else {
                nextIndex = (currentIndex + 1) % focusableElements.length;
            }
            focusableElements[nextIndex]?.focus();
        }

        if(event.key === "Enter"){
            event.preventDefault();
            handleSubmitFeedbackOnclick(event)
        }
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
        setIsBlurred(false);
      };
    
      const handleTooltipClose = (_timeout = 1000) => {
        setIsBlurred(true);
        if(_timeout !== 0){
            setTimeout(() => {
                setOpenTooltip(false);
            }, _timeout);
        }
        else{
            setOpenTooltip(false);
        }
      };

    return (
        <>
            {(((showFeedbackForm && !saveFeedbackMap[product.id]) || isFeedbackEdit) && !focusSingleProduct[product.id]) && (
                <div className={styles.FeedbackHoverArrowMain} onKeyDown={handleKeyDown} tabIndex={0} ref={containerRef}>
                    {FeedbackHover ? (
                        <div className={styles.FeedbackHoverArrow}>
                            <FeedbackHoverArrow />
                            <span>Click to provide<br />pricing feedback</span>
                        </div>
                    ) : (watch('productPrice')) && (
                        <button id='feedback-submit' className={styles.submitFeedbackBtn} onClick={handleSubmitFeedbackOnclick} >
                            SUBMIT FEEDBACK
                        </button>

                    )}
                    <div className={styles.feedbackFormWrapper}>
                    {!isClicked && <div className={styles.priceClickOverlay} onClick={handlePriceClick}></div>}
                    <div className={styles.feedBackForm}>
                        <div className={styles.feedbackTopSection}>
                            <span className={styles.dollerTxt}>$</span>
                            <input id='product-price' type="text" placeholder={productPricePlaceholder} {...register('productPrice')}
                                onFocus={(e) => {
                                    e.target.value = e.target.value.replace(/[\$,]/g, '');
                                    setValue('productPrice', e.target.value)
                                }}
                                onChange={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                    register('productPrice').onChange(e);
                                }}
                                onBlur={(e)=>{
                                    e.target.value = e.target.value.replace(/[\$,]/g, '');
                                    e.target.value = e.target.value ? formatToTwoDecimalPlaces(e.target.value) : '';
                                    register('productPrice').onBlur(e);
                                }}
                            />
                            <div className={styles.zipLabel}>
                                <div className={styles.zipLBL}>ZIP</div>
                                <div className={styles.bgFeedback}>
                                    <ZipBg />
                                </div>
                            </div>

                        </div>
                        <div className={styles.feedBackFormMidSection}>
                            <span className={styles.inputBox}>
                                <input id='feedback-zipcode' type="text" placeholder={searchZipCode} {...register('zipCode')}
                                    maxLength={5}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                        register('zipCode').onChange(e);
                                    }}
                                />
                            </span>
                            <span className={styles.selectBox}>
                                <Select className="unitFeedbackDropdown"
                                    onChange={(e) => {
                                        register('productPriceUnit').onChange(e);
                                        setValue('productPriceUnit', e.target.value);
                                    }}
                                    value={watch('productPriceUnit')}
                                    IconComponent={(props) => <UpDownIcon {...props} />}
                                    displayEmpty
                                    MenuProps={{
                                        classes: {
                                            paper: 'SelectFeedbackDropdown',
                                        },
                                    }}
                                >
                                    {feedbackUnits.map((unit, i) => (
                                        <MenuItem key={i} value={unit.value} >{unit.title}</MenuItem>
                                    ))}
                                </Select>
                            </span>
                        </div>
                        <div className={styles.feedbackbottomSection}>
                            <input id='product-pc-price' type="text" placeholder={'ex 200'}
                                {...register('productPCPrice')}
                                onChange={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    register('productPCPrice').onChange(e);
                                }}
                                onFocus={(e) => {
                                    e.target.value = e.target.value.replace(/[\$,]/g, '');
                                    setValue('productPCPrice', e.target.value)
                                }}
                                onBlur={(e)=>{
                                    e.target.value = e.target.value.replace(/[\$,]/g, '');
                                    e.target.value = e.target.value ? formatCurrencyWithComma(e.target.value) : '';
                                    register('productPCPrice').onBlur(e);
                                }}
                            />
                            <span>PC</span>
                        </div>

                    </div>
                      {isClicked && 
                        <button id='feedback-cancel' className={styles.btnCancel} onClick={handleCloseClick} >
                            CANCEL
                        </button>
                        }
                    </div>
                </div>
            )}
            <div>
                {isSubmitted && <div className={styles.feedbackPosition}>
                    <div className={clsx(styles.overlayForAnimation, styles.animationHideEaseOut)}></div>
                    <div className={styles.feedbackSaved}>Feedback Saved</div>
                </div>}
                {(savedFeedbackData && !isFeedbackEdit) &&
                    <>
                        <div className={styles.editArrowStyle} onClick={(e) => e.stopPropagation()}>
                            <Tooltip
                                placement="left-start"
                                open={openTooltip}
                                onOpen={handleTooltipOpen}  // Trigger to show the tooltip
                                onClose={() => handleTooltipClose(1000)}  // Trigger to hide the tooltip
                                classes={{
                                    tooltip: clsx(
                                        focusSingleProduct[product.id] && styles.focusSingleProduct,
                                        styles.feedbackContent,
                                        (isMouseOverOnProduct && isBlurred) && styles.blurEffect,
                                        (focusSingleProduct[product.id] && isBlurred) && styles.overlayOnBlur
                                    ),
                                }}
                                title={
                                    <div className={styles.editFeedback}>
                                        <div className={clsx(styles.overlayForAnimation, styles.animationShowEaseIn, isBlurred && styles.animationHideEaseOut2)}>

                                        </div>
                                        <div className={styles.feedbackInnerContent}>
                                            <p className={styles.feedbackDetail}>
                                                {`$${savedFeedbackData.productPrice} / ${savedFeedbackData.productPriceUnit === 'ea' ? 'PC' : savedFeedbackData.productPriceUnit.toUpperCase()
                                                    } to ${savedFeedbackData.zipCode} for ${savedFeedbackData.productPCPrice} PCs`}
                                            </p>
                                            <span className={styles.editBtn} onClick={handleFeedBackEdit}>
                                                Edit
                                            </span>
                                        </div>
                                    </div>
                                }
                            >
                                <span
                                    className={styles.feedBackArrow}
                                    onMouseEnter={() => setIsSubmitted(false)}
                                >
                                    <FeedbackArrow />
                                </span>
                            </Tooltip>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default PricingFeedback