import React, { useEffect, useRef, useState, KeyboardEvent } from 'react'
import styles from '../Header/Header.module.scss'
import { ReactComponent as BLogo } from '../../assets/New-images/New-Bryzos-Logo.svg';
import { ReactComponent as PinIcon } from '../../assets/New-images/Pin.svg';
import { ReactComponent as PinIconHover } from '../../assets/New-images/Pin-Hover.svg';
import { ReactComponent as MenuIcon } from '../../assets/New-images/Menu.svg';
import { ReactComponent as MenuIconHover } from '../../assets/New-images/Menu-Hover.svg';
import { ReactComponent as NotificationIcon } from '../../assets/New-images/Notification.svg';
import { ReactComponent as NotificationIconHover } from '../../assets/New-images/Notification-Hover.svg';
import { useAuthStore } from '@bryzos/giss-ui-library';
import { routes } from '../../common';
import { useLocation } from 'react-router-dom';
import { useMenuStore } from '../../component/Menu/MenuStore';
import clsx from 'clsx';
import NewBryzosLogo from "../../assets/New-images/New-Logo.png";
import { MENU_ANIMATION_DURATION } from 'src/renderer2/helper';

// Add interface for AuthStore
interface AuthStore {
  initiateLogout: (arg1: boolean, arg2: boolean, arg3: boolean) => void;
  [key: string]: any;
}

const Header = () => {
    const {initiateLogout} = useAuthStore() as AuthStore;
    const { setOpenMenu, openMenu } = useMenuStore();
    const location = useLocation();
    const lockMenuTillAnimation = useRef(false);

    const getTitle = () => {
        switch (location.pathname) {
            case routes.createPoPage:
                return 'CREATE PO';
            case routes.orderConfirmationPage:
                return 'CREATE PO';
            case routes.buyerSettingPage:
                return 'PROFILE SETTINGS';
            case routes.homePage:
                return 'PRICE SEARCH';
            case routes.bomUpload:
                return 'UPLOAD BOM';
            // Add more cases as needed for other pages
            default:
                return 'DEFAULT TITLE'; // Fallback title
        }
    };

    const handleMenuClick = () => {
        if(lockMenuTillAnimation.current) return;
        setOpenMenu(!openMenu);
        lockMenuTillAnimation.current = true;
        setTimeout(() => {
            lockMenuTillAnimation.current = false;
        }, MENU_ANIMATION_DURATION);
    };

    // Add keydown handler for the menu button
    const handleMenuKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleMenuClick();
        }
    };

    return (
        <div className={styles.headerStyle}>
            <div className={clsx(styles.subscribeTextDiv, location.pathname === routes.homePage && styles.priceSearchSubscribeTextDiv)}>
                <span className={styles.subscribeText}>SUBSCRIBE</span>
                <span className={styles.freeTrialText1}>FREE TRIAL: 14 DAYS LEFT</span>
            </div>
            <span className='dragArea'></span>
            <div className={styles.headerMain}>
                <div className={clsx(styles.logoSection, location.pathname === routes.homePage && styles.priceSearchMargin)}><img className={styles.bryzosLogo} src={NewBryzosLogo} alt="Logo" /></div>
                <div className={styles.pageName}><div>{getTitle()}</div> <div onClick={() => { initiateLogout(false, false, true)}} className={styles.greenDot}></div></div>
                <div className={styles.iconMain}>
                    {/* {location.pathname === routes.homePage &&
                        <>
                            <div className={styles.iconDiv} id='toggle-sticky-btn'>
                                <span className={styles.iconDivImg1}><PinIcon /></span>
                                <span className={styles.iconDivImg2}><PinIconHover /></span>
                            </div>
                            <div className={styles.iconDiv}>
                                <span className={styles.iconDivImg1}><NotificationIcon /></span>
                                <span className={styles.iconDivImg2}><NotificationIconHover /></span>
                            </div>
                            <div className={styles.iconTopVc} onClick={() => window?.electron?.send({ channel: channelWindow.minimize })}>
                                <span >VC</span>
                            </div>
                        </>
                    } */}
                    <div  tabIndex={11} className={styles.iconTopInviteUser}>
                        <span>INVITE USERS</span>
                    </div>
                    <div 
                        className={styles.iconDiv} 
                        tabIndex={12} 
                        onClick={() => handleMenuClick()}
                        onKeyDown={handleMenuKeyDown}
                        role="button"
                        aria-label="Toggle menu"
                    >
                        <span className={styles.iconDivImg1}><MenuIcon /></span>
                        <span className={styles.iconDivImg2}><MenuIconHover /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header