// src/api/axios.js
import axios from 'axios';
import { dispatchRaygunError } from '../helper';



const addErrorInterceptors = ()=>{
// Request interceptor
axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      dispatchRaygunError(error, 'RequestInterceptor');
      return Promise.reject(error);
    }
  );
  
  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error('Error URL:', error?.config?.url);
      console.error('Error message:', error?.message);
      console.error(error)
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized
        } else{
            dispatchRaygunError(error, 'has-response');
        }
      } else if (error.request) {
         //Do not do anything. We were getting false alarm from this case so removed the raygun.
      } else {
        dispatchRaygunError(error, 'no-request/response');
      }
      return Promise.reject(error);
    }
  );
  
}
export default addErrorInterceptors;