import React, { useEffect, useState } from 'react'

import styles from '../home.module.scss'
import OrderSizeFilter from '../../../component/OrderSizeFilter/OrderSizeFilter'

const OrderSize = () => {
    const [orderSizeSaveDefault ,setOrderSizeSaveDefault]= useState(false);
    return (
        <div className={styles.orderFilter}>
            <div className={styles.leftFilterTitle}>ORDER SIZE (LB)</div>
            <OrderSizeFilter setOrderSizeSaveDefault={setOrderSizeSaveDefault} />
            <button disabled={!orderSizeSaveDefault} className={styles.saveDefaultBtn}>SAVE AS DEFAULT</button>
        </div>
    )
}

export default OrderSize