// @ts-nocheck
import { useNavigate, useLocation } from 'react-router';
import styles from './onboardingThankyou.module.scss';
import { routes } from '../../common';
import OnboardingFooter from './onboardingFooter';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { getChannelWindow } from '@bryzos/giss-ui-library';

function OnboardingThankYou() {
  const channelWindow = getChannelWindow()  ;
  const isNotificationEnabled = channelWindow?.openNotificationSetting ? window.electron.sendSync({ channel: channelWindow.openNotificationSetting, data: null}) : false;
  const [notificationEnabled, setNotificationEnabled] = useState(isNotificationEnabled);
    const navigate = useNavigate();
    const handleCheckboxChange = () => {
      if(channelWindow?.openNotificationSetting)
        window.electron.send({ channel: channelWindow.openNotificationSetting, data: !notificationEnabled})
      setNotificationEnabled(!notificationEnabled);
    };
    const location = useLocation();
    const { isUserApproved } = location.state;
    
    return (
        <div className={clsx(styles.thankyouBox, 'bgBlurContent')}>
            <div className={styles.onboardingLogo}>
                <img src='/onboardingLogo.png' />
            </div>
            <div>
                <h2>Thank you for joining!</h2>
                {!isUserApproved ? 
                <>
                  <p className={styles.thnkPara}>Your information is currently being reviewed. Due to high volume, the review process could take up to an hour.</p> 
                  <p>You will receive an email at the provided email address once review is complete.</p>
                </>
                : 
                <p className={styles.thnkPara}>You are now approved and can log in using the credentials you created during signup.</p> 
                }
                {!notificationEnabled &&
                  <div className='turnOnNotif'>
                    <label className='containerChk'><input type='checkbox'  checked={notificationEnabled} onChange={handleCheckboxChange}/><span className='checkmark'></span>Click here to enable notifications</label>
                  </div>
                }
                <div className={styles.loginBtn}>
                <button onClick={() => navigate(routes.loginPage)}>{!!isUserApproved ? 'Login' : 'Go back to login'}</button>
                </div>
                
            </div>
            <OnboardingFooter />
        </div>
    );
}
export default OnboardingThankYou;