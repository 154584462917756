import React, { memo} from "react";
import { Dialog } from "@mui/material";
import styles from './DialogPopup.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';
import useDialogStore, { DialogAction } from "./DialogStore";
import { routes } from "@bryzos/giss-ui-library";

const DialogBox = memo(({parentRef}: {parentRef: React.RefObject<HTMLDivElement>}) => {

    const { openDialog, header, content, type, onClose, actions } = useDialogStore() as any;
    if(location.pathname === routes.loginPage) return <></>;
    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            transitionDuration={200}
            hideBackdrop
            container={parentRef.current}
            style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            PaperProps={{
                style: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    margin: 0
                }
            }}
            classes={{
                root: styles.ErrorDialog,
                paper: styles.dialogContent
            }}
        >
            {type === 'success' && 
            <button className={styles.closeIcon} onClick={onClose}><CloseIcon /></button>
            }
            <p className={styles.successPopupTitle}>{header}</p>
            <p>{content}</p>
            <div className={styles.DialogPopupBtn}>
            {
                actions?.map((action: DialogAction, index: number)=>{
                    return <button key={index} className={styles.submitBtn} onClick={action.action}>{action.name}</button>
                })
            }

            </div>
          
        </Dialog>
    );
});


export default DialogBox;