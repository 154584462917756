// @ts-nocheck
import styles from './ToastSnackbar.module.scss'
import useSnackbarStore from "./snackbarStore";
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '../../assets/images/Icon_Close.svg';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_NOTIFICATION_PRIORTY } from '../../common';
import { getChannelWindow } from '@bryzos/giss-ui-library';
import { useEffect } from 'react';

const ToastSnackbar = () => {
    const {openSnackbar, snackbarMessage, snackbarActions, snackbarSeverity, snackbarCloseHandler, snackbarTimer, resetSnackbarStore} = useSnackbarStore();
    const channelWindow = getChannelWindow()  ;

    useEffect(() => {
        if (snackbarTimer && openSnackbar) {
            setTimeout(() => {
                resetSnackbarStore()
            }, snackbarTimer);
        }
    }, [openSnackbar, snackbarTimer])

    let backgroundColor;
    if (snackbarSeverity === 'warning') {
        backgroundColor = 'warning';
    } 
    else if (snackbarSeverity === 'alert') {
        backgroundColor = 'alert'; // red
    } 
    else if (snackbarSeverity === 'success'){
        backgroundColor='green'
    } else if (snackbarSeverity === CUSTOM_NOTIFICATION_PRIORTY[0]) {
        backgroundColor = "snackbar_LOW"
    } else if (snackbarSeverity === CUSTOM_NOTIFICATION_PRIORTY[1]) {
        backgroundColor = "snackbar_MEDIUM"
    } else if (snackbarSeverity === CUSTOM_NOTIFICATION_PRIORTY[2]) {
        backgroundColor = "snackbar_HIGH"
    }

    if(!openSnackbar) return<></>

    return (
        <div className={clsx(styles.snackbarContainer, openSnackbar ? styles.show : styles.hide, `${backgroundColor}`)}>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: snackbarMessage }}/>
            {
                snackbarActions?.map((action)=>{
                    return <button key={uuidv4()} className={clsx(action.name && styles.actionBtn)} onClick={action.handler}> {action.name} <span className={styles.closeBtn}>{action.icon}</span></button>
                })
            }
            {
                snackbarCloseHandler ? <button className={styles.closeBtn} onClick={snackbarCloseHandler}> <CloseIcon /> </button> : <></>
            }
        </div>
    );
};


export default ToastSnackbar;