import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const usePostBomUploadS3Url = () => {

  return useMutation(async (data: any) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_SERVICE}/user/bom/upload-s3-url`,
        data
      );

      if (response.data?.data) {
        if (
          typeof response.data.data === "object" &&
          "error_message" in response.data.data
        ) {
          throw new Error(response.data.data.error_message);
        } else {
          return response.data.data;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      throw new Error(error?.message);
    }
  });
};

export default usePostBomUploadS3Url;
