import React, { useEffect, useState } from 'react';
import RPMBar from './RPMBar';
import styles from './RPMBars.module.scss';

interface RPMBarsProps {
  barCounter: number;
  numberOfBrackets: number;
  bracketDivider: number;
}

/**
 * RPMBars component displays a series of RPM bars (1-12)
 * with automatic filling based on the current value.
 */
const RPMBars: React.FC<RPMBarsProps> = ({
  numberOfBrackets,
  barCounter,
  bracketDivider
}) => {
  /**
   * Generates an array of bar numbers (1-12)
   * @returns {number[]} Array of bar numbers
   */

  const getBarNumbers = (): number[] => {
    const barNumbers = Array.from({ length: numberOfBrackets }, (_, i) => i + 1);
    return barNumbers;
  };

  let barsToBeFilled = 0;
  if(barCounter !== 0){
    if(barCounter % (bracketDivider * numberOfBrackets) === 0){
      barsToBeFilled = bracketDivider * numberOfBrackets;
    }
    else{
      barsToBeFilled = barCounter % (bracketDivider * numberOfBrackets);
    }
  }
  return (
    <div className={styles.container}>
      {getBarNumbers().map((barNumber) => (
        <RPMBar
          key={barNumber}
          barNumber={barNumber}
          barsToBeFilled={barsToBeFilled}
          bracketDivider={bracketDivider}
        />
      ))}
    </div>
  );
};

export default RPMBars;
