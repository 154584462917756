// @ts-nocheck
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '../common';
 import { ReactComponent as EnterIcon } from '../assets/images/icon-enter.svg';

function Success() {
  const navigate = useNavigate();

    useEffect(() =>{
      function goToMainPage(event: KeyboardEvent){
        if(event.key === 'Enter'){
          navigate(routes.homePage);
          
        }
      }

      document.addEventListener('keydown', goToMainPage)
      return(()=>{
        document.removeEventListener('keydown',goToMainPage)
      })
    },[navigate])
    const handleSuccessClick = () => {
      navigate(routes.homePage);
    }
    return (
        <>
          <div className='successPanel bgImg'>
              <div className='successBody'>
                  <div className='greenText'>Invitation Sent Successfully</div>
                  <div onClick={handleSuccessClick}  className='whiteText'>Return to Search <EnterIcon /></div>
              </div>
          </div>
        </>
    );
}

export default Success;