import React, { ErrorInfo, ReactNode } from "react";
import { dispatchRaygunError, unhandledError } from "../../helper";
import { routes } from "../../common";
import { ReactComponent as FatalErrorIcon } from '../../assets/images/fatalError.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/Icon_Close.svg';
import { ReactComponent as MinimizeAppIcon } from '../../assets/images/Minimize_App.svg';
import { getChannelWindow } from "@bryzos/giss-ui-library";


type Props = {
  children?: ReactNode;
}

type State = {
  hasError: boolean;
  channelWindow: any;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      channelWindow: getChannelWindow() ,
    };

    this.btnRefresh = this.btnRefresh.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error) {
      this.setState({ hasError: true });
    }

    dispatchRaygunError(error, unhandledError);
  }

  btnRefresh() {
    (window as any).electron.send({ channel: this.state.channelWindow?.refreshApp });
  }
  
btnMinimize() {
  (window as any).electron.send({ channel: 'windowMinimize'});
}

btnClose() {
  (window as any).electron.send({ channel: 'windowClose'});
}

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage>
          <span className="drag dargPanelError"></span>
          <span className="fetalErrorHeader">
            <button onClick={this.btnMinimize}><MinimizeAppIcon /></button>
            <button onClick={this.btnClose}><CloseIcon /></button>
          </span>
          <div className={`errorPanel bgImg ${location.pathname === routes.loginPage ? 'errorPanelMargin' : ''}`}>
            <div className='errorPage'>
              <div><FatalErrorIcon /></div>
              <div className="fatalErrorTitle">Something went wrong !!!</div>
              <p>Please restart the application.</p>
              <p>If same issue still occurs then please contact Bryzos Support.</p>
              <button className="restartAppBtn" onClick={this.btnRefresh}>Restart App</button>
            </div>
          </div>
        </ErrorPage>
      );
    } else {
      return this.props.children;
    }
  }
}


const ErrorPage: React.FC<Props> = ({ children }) => {
  return <div className="fatalErrorMain">{children}</div>;
};

export default ErrorBoundary;
