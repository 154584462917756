// @ts-nocheck
import * as yup from "yup";
const isEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}
export const schema = yup.object({
  companyName: yup.string().trim().required('Company Name is not valid'),
  yourCompany: yup.string().trim().required('Your Company is not valid'),
  companyAddressLine: yup.string().trim().required('Company Address is not valid'),
  companyCity:yup.string().trim().required('Company Address is not valid'),
  companyState:yup.number().required('Company Address is not valid'),
  companyZipCode:yup.string().required('Company Address is not valid').min(5,'Company Address is not valid'),
  firstName:yup.string().trim().required('Name is not valid'),
  lastName:yup.string().trim().required('Name is not valid'),
  emailAddress:yup.string().trim().required('Email/Phone is not valid').email('Email/Phone is not valid').max(50, 'please do not enter more than 50 characters'),
  phoneNo:yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Email/Phone is not valid").required("Phone number is required"),
  deliverToAddress: yup.string().trim().required('Address is not valid'),
  deliverToCity: yup.string().trim().required('City is not valid'),
  deliverToState: yup.number().required('State is not valid'),
  deliverZipCode: yup.string().required('Zip is not valid').min(5,'Zip is not valid'),
  deliveryDate: yup.number().required('Delivery Date is Required'),
  sendInvoicesTo: yup.string().trim().required('Send Invoices to is not valid').test('valid-emails', 'Send Invoices to is not valid', value => {
    if (!value) return true;
    const emails = value.split(',');
    const isValid = emails.every(email => email.trim() && isEmail(email.trim()));
    return isValid;
  }),
  shippingDocsTo: yup.string().trim().required('Enter valid email').test('valid-emails', 'Enter valid email', value => {
    if (!value) return true;
    const emails = value.split(',');
    const isValid = emails.every(email => email.trim() && isEmail(email.trim()));
    return isValid;
  }),
  bnplAvailable: yup.boolean(),
  net30CheckBox: yup.string(),
  einNumber: yup.string().trim().test("isRequired", "Ein Number is not valid", function(value) {
    const bnplAvailable = this.parent.bnplAvailable;
    if(bnplAvailable === true) return true;
    const net30CheckBox = this.parent.net30CheckBox;
    if(net30CheckBox === "false") return true;
    if(!/^x{5}\d{4}$|^\d{2}-\d{7}$/.test(value)){
      return false
    }
    return !!value;
  }),
  dnBNumber: yup.string().test("isRequired", "D&B Number is not valid", function (value) {
    const bnplAvailable = this.parent.bnplAvailable;
    if(bnplAvailable === true) return true;
    const net30CheckBox = this.parent.net30CheckBox;
    if(net30CheckBox === "false") return true;
    if(!/^x{5}\d{4}$|^\d{9}$/.test(value)){
      return false
    }
    return !!value;
  }),
  creditLine: yup.string().test("isRequired", "Credit Line is not valid", function (value) {
    const bnplAvailable = this.parent.bnplAvailable;
    if(bnplAvailable === true) return true;
    const net30CheckBox = this.parent.net30CheckBox;
    if(net30CheckBox === "false") return true;
    if (value) {
      return +value > 0;
    } else {
      return false;
    }
  }),
  achCheckBox: yup.string(),
  bankName: yup.string(),
  routingNo: yup.string(),
  accountNo: yup.string(),
  requestCreditLine: yup.string(),
  resaleCertificateList: yup.array()
  .of(
    yup.object()
      .shape({
        resaleCertFile: yup.mixed(),
        cerificate_url_s3: yup.string().default(null).nullable(),
        state_id: yup.number().nullable()
        .when("cerificate_url_s3", {
          is: (s3Url) => s3Url?.trim()?.length > 0, 
          then: (s) => s.required("Required")
        })
        .transform((value)=>{
          if(isNaN(value)){ 
            return null
          }
          return value;
        }),
        expiration_date: yup.string()
        .when("cerificate_url_s3", {
          is: (s3Url) => s3Url?.trim()?.length > 0, 
          then: (s) => s.required("Required")
        }),
        uploadCertProgress: yup.boolean().default(false).nullable(),
        status: yup.string().nullable(),
        id: yup.string().nullable(),
        is_deletable: yup.string().nullable(),

      }) 
  ),
})