import React, { useState, useEffect } from 'react';
import styles from './reaction.module.scss';
import clsx from 'clsx';

// Interface for a single reaction
interface Reaction {
  reaction: string;
  userId: string;
  username: string;
  _id: string;
}

// Interface for the props of the component
interface ReactionComponentProps {
  reactions: Reaction[];
  sdk: any;
  messageId: string;
  userId:string;
}

interface ReactionDetail {
  count: number;
  users: string[];
}

interface ReactionDetails {
  [key: string]: ReactionDetail;
}

const reactionEmojis: { [key: string]: string } = {
  wave: '👋',
  thumbs_up: '👍',
  clap: '👏',
  pray: '🙏',
  strong: '💪',
  celebrate: '🎉',
  heart: '❤️'
};

const ReactionComponent: React.FC<ReactionComponentProps> = ({ reactions, sdk, messageId, userId }) => {
  const [reactionDetails, setReactionDetails] = useState<{ [key: string]: { count: number, users: string[] } }>({});

  useEffect(() => {
    extractCountFromReactions(reactions,); 
  }, [reactions]);

  useEffect(() => {
    if (!sdk) {
      return;
    }
    (async () => {
      sdk.on('messageLiked', handleMessageLike);
      return ()=>{
        sdk.off('messageLiked',handleMessageLike);
      }
    })();
  }, [sdk]);

  const handleMessageLike = (message: any) => {
    if (message.messageId === messageId) {
      if(message.action === "liked"){
        const test = reactions.filter((reaction)=>(reaction.userId === message.userId && reaction.reaction === message.reaction && reaction.username === message.username))
        if(test.length==0)
          reactions.push({ reaction: message.reaction, userId: message.userId, username: message.username, _id: '' });
      }else{
        reactions = reactions.filter((reaction)=>(reaction.userId !== message.userId || reaction.reaction !== message.reaction));
      }
      extractCountFromReactions(reactions);
    }
  }

  const extractCountFromReactions = (reactions: Reaction[]) => {
    const details = reactions.reduce((acc:ReactionDetails, { reaction, username }) => {
      if (!acc[reaction]) {
        acc[reaction] = { count: 0, users: [] };
      }
      acc[reaction].count += 1;
      if (!acc[reaction].users.includes(username)) {
        let userName = username.split('_');
        let userInitials = userName[0].charAt(0).toUpperCase();
        if(userName.length>1&&userName[1].length>0){
          userInitials += userName[1].charAt(0).toUpperCase();
        }
        acc[reaction].users.push(userInitials);
      }
      return acc;
    }, {});
  
    setReactionDetails(details);
  };
  
  const handleReactionClick = (reaction:string)=>{
    const myReactions:Reaction[] = reactions.filter(item=>item.userId === userId && item.reaction === reaction);
    if(myReactions.length > 0){
      sdk.likeMessage(messageId, reaction, "unliked");
    }else{
      sdk.likeMessage(messageId, reaction, "liked");
    }
  }
  return (
    <div className={styles.reactionContainer}>
      {Object.entries(reactionDetails).map(([reaction, details]) => {
        if (details.count > 0) {
          return (
            <div key={reaction} onClick={() => handleReactionClick(reaction)} className={clsx(styles.reaction, 'reaction', 'tooltip')} title={`${details.users.join(', ')}`}>
              <span>{reactionEmojis[reaction]}</span> <span className={styles.countReaction}>{details.count}</span>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default ReactionComponent;
