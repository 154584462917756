import React from 'react'

import styles from '../home.module.scss'
import SearchResultPanel from './SearchResultPanel'
import SelectedProductList from './SelectedProductList'

const MainContent = () => {
    return (
        <div className={styles.middleSection}>
            <SearchResultPanel
            />
            <SelectedProductList />
        </div>
    )
}

export default MainContent