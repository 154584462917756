import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { reactQueryKeys } from "../common";

const useGetGameScore = () => {
  return useQuery([reactQueryKeys.getGameScore], async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_SERVICE}/user/get-game-score`
      );
      if (response.data?.data) {
        if (
          typeof response.data.data === "object" &&
          "error_message" in response.data.data
        ) {
          throw new Error(response.data.data.error_message);
        } else {
          return response.data.data;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }, {
    staleTime: 0, 
    cacheTime: 0,   
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
});
};

export default useGetGameScore;
