import { ReferenceDataProduct } from "@bryzos/giss-ui-library";
import { create } from "zustand";
import { ProductPricingModel } from "../types/Search";


type UseSearchStoreType = {
    shortListedSearchProductsData: ProductPricingModel[];
    setShortListedSearchProductsData: React.Dispatch<ProductPricingModel[]>;
    searchByProductResult: ReferenceDataProduct[];
    setSearchByProductResult: React.Dispatch<ReferenceDataProduct[]>;
    searchString: string;
    setSearchString: React.Dispatch<string>;
    selectedProductsData: any[];
    setSelectedProductsData: React.Dispatch<any[]>;
    selectedDomesticOption: boolean;
    setSelectedDomesticOption: React.Dispatch<boolean>;
    selectedPriceUnit: string;
    setSelectedPriceUnit: React.Dispatch<string>;
    searchZipCode: string;
    setSearchZipCode: React.Dispatch<string>;
    saveFeedbackMap: any;
    setSaveFeedbackMap: React.Dispatch<any>;
    productFeedbackInProgress: Set<number> | null;
    setProductFeedbackInProgress: React.Dispatch<Set<number> | null>;
    orderSizeSliderValue: number;
    setOrderSizeSliderValue: React.Dispatch<number>;
    filterShortListedSearchProductsData: ProductPricingModel[];
    setFilterShortListedSearchProductsData: React.Dispatch<ProductPricingModel[]>;
    filterSearchByProductResult: ReferenceDataProduct[];
    setFilterSearchByProductResult: React.Dispatch<ReferenceDataProduct[]>;
    focusSingleProduct: any;
    setFocusSingleProduct: React.Dispatch<any>;
    sessionId: string;
    setSessionId: React.Dispatch<string>;
    enableRejectSearchAnalytic: boolean;
    setEnableRejectSearchAnalytic: React.Dispatch<boolean>;
} 
const commonSearchStore = {
    shortListedSearchProductsData: [],
    searchByProductResult: [],
    searchString: "",
    selectedProductsData: [],
    selectedDomesticOption: false,
    selectedPriceUnit: "cwt",
    searchZipCode: '10010',
    saveFeedbackMap: {},
    productFeedbackInProgress: null,
    orderSizeSliderValue: 500,
    filterShortListedSearchProductsData: [],
    filterSearchByProductResult: [],
    focusSingleProduct: {},
    sessionId: '',
    enableRejectSearchAnalytic: true
}

export const useSearchStore = create<UseSearchStoreType>((set) => ({
    ...commonSearchStore,
    setSearchString: (searchString: string) => {set({searchString})}, 
    setShortListedSearchProductsData: (shortListedSearchProductsData: any) => {set({shortListedSearchProductsData})}, 
    setSearchByProductResult: (searchByProductResult: any) => {set({searchByProductResult})},
    setSelectedProductsData: (selectedProductsData: any) => {set({selectedProductsData})},
    setSelectedPriceUnit: (selectedPriceUnit: any) => {set({selectedPriceUnit})},
    setSelectedDomesticOption: (selectedDomesticOption: any) => {set({selectedDomesticOption})},
    setSearchZipCode: (searchZipCode: string) => {set({searchZipCode})},
    setSaveFeedbackMap: (saveFeedbackMap: object) => {set({saveFeedbackMap})},
    setProductFeedbackInProgress: (productFeedbackInProgress: Set<number> | null) => {set({productFeedbackInProgress})},
    setOrderSizeSliderValue: (orderSizeSliderValue: number) => {set({orderSizeSliderValue})},
    setFilterShortListedSearchProductsData: (filterShortListedSearchProductsData: ProductPricingModel[]) => {set({filterShortListedSearchProductsData})},
    setFilterSearchByProductResult: (filterSearchByProductResult: ReferenceDataProduct[]) => {set({filterSearchByProductResult})},
    setFocusSingleProduct: (focusSingleProduct: any) => {set({focusSingleProduct})},
    setSessionId: (sessionId: string) => {set({sessionId})},
    setEnableRejectSearchAnalytic: (enableRejectSearchAnalytic: boolean) => {set({enableRejectSearchAnalytic})},
    resetSearchStore: () => set(state => ({
      ...commonSearchStore
    })),

}))