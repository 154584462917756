// @ts-nocheck
import { MinSearchDataLen } from "../common";

export const searchProducts = (products, searchStrings, searchData) => {
    if (Array.isArray(products) && (searchData !== '' && searchData.length >= MinSearchDataLen)) {
        return products.filter((product) => {
            return searchStrings.every((searchString) => {
                return Object.keys(product).some((key) => {
                    if (key.startsWith("Key")) {
                        const keyElement = product[key];
                        if (keyElement === '' || keyElement === null) {
                            return false;
                        }
                        return keyElement.toLowerCase().startsWith(searchString);
                    }
                    return false;
                });
            });
        });
    }
    return [];
}

export const getValidSearchData = (searchString) => {
    const regex = /[^\w'./"-]+/g;
    const words = searchString.toLowerCase().split(regex).filter(Boolean);

    const phrasesToConcatenate = [
        "type b",
        "sch 40",
        "sch 80",
        "1 1/4",
        "1 1/2",
        "1 1/8",
        "1 3/8",
        "1 5/8",
        "1 7/8",
        "1 3/4",
        "2 1/2",
        "2 1/4",
        "2 1/8",
        "2 3/4",
        "2 7/8",
    ];

    for (let i = 0; i < words.length - 1; i++) {
        const currentAndNextWordConcat = words[i] + " " + words[i + 1];
        if (phrasesToConcatenate.includes(currentAndNextWordConcat)) {
            words[i] = currentAndNextWordConcat;
            words.splice(i + 1, 1);
        } else if (words[i].includes('x') && i > 0 && i < words.length - 1) {
            words.splice(i, 1);
        }
    }

    const uniqueWords = new Set(words);
    return Array.from(uniqueWords);
}

export const getPurchaseOrderFilteredList = (purchaseOrdersList, filterPoStoreBy, searchByProductOrZipValue, productMapping) => {
    let poListToBeShown;
    if(filterPoStoreBy === '') poListToBeShown = purchaseOrdersList;
    else poListToBeShown = purchaseOrdersList.filter( order => order.claimed_by === filterPoStoreBy);
    if(searchByProductOrZipValue.length > 1){
        const productListForReference = purchaseOrdersList.reduce((acc, order) => {
            order.items.forEach((product) => {
                acc.push({...productMapping[product.product_id], buyer_po_number: order.buyer_po_number, Key150: order.zip});
            });
            return acc;
        }, []);
        const searchStrings = getValidSearchData(searchByProductOrZipValue);
        const filteredProductsFromReferenceList = searchProducts(productListForReference, searchStrings, searchByProductOrZipValue);
        const poNumberSet = new Set();
        filteredProductsFromReferenceList.forEach((product) => poNumberSet.add(product.buyer_po_number));
        poListToBeShown =  poListToBeShown.filter((order) => poNumberSet.has(order.buyer_po_number));
    }
    return poListToBeShown;
}