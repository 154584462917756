// @ts-nocheck
import styles from './onboardingFooter.module.scss';


function OnboardingFooter() {
    return (
        <div className={styles.onboardingFooterPage}>
                <div className={styles.patentPandingTxt}>PATENT PENDING</div>
        </div>
    );
}
export default OnboardingFooter;
