import React, { useState, useRef } from 'react';
import styles from 'src/renderer2/pages/buyer/createPo.module.scss';
import { ClickAwayListener } from '@mui/material';
import { ReactComponent as DropdownIcon } from '../../assets/New-images/StateIconDropDpown.svg';
import StatesList from '../StatesList/StatesList';


const StateDropDown = ({ states, setValue, stateDropDownValue = '', setStateDropDownValue, stateInputFocus, setStateInputFocus }: any) => {
  
  const [inputValue, setInputValue] = useState(stateDropDownValue || '');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  
  const handleStateSelection = (state: any) => {
    setInputValue(state.code);
    setValue('shipping_details.state_id', state.id);
    setStateDropDownValue(state.code);
    setStateInputFocus(false);
    inputRef.current?.blur();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      handleClickAway(states[selectedIndex]);
    }
    
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
    
    const filteredStates = states.filter((state: any) => 
      state.code.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    const filteredIndexes = filteredStates.map((state: any) => 
      states.findIndex((s: any) => s.id === state.id)
    );
    
    if (event.key === 'ArrowDown') {
      setSelectedIndex(prev => {
        const currentIdx = filteredIndexes.indexOf(prev);
        const nextIdx = currentIdx + 1;
        return filteredIndexes[nextIdx] !== undefined ? filteredIndexes[nextIdx] : prev;
      });
    } else if (event.key === 'ArrowUp') {
      setSelectedIndex(prev => {
        const currentIdx = filteredIndexes.indexOf(prev);
        const prevIdx = currentIdx - 1;
        return filteredIndexes[prevIdx] !== undefined ? filteredIndexes[prevIdx] : prev;
      });
    } else if (event.key === 'Enter') {
      handleClickAway(states[selectedIndex]);
    }
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    setInputValue(value);
    
    const matches = states?.filter((state: any) => 
      state.code.toLowerCase().startsWith(value.toLowerCase())
    );
    
    if (matches.length === 1 && value.length > 0) {
      setSelectedIndex(states.findIndex((s: any) => s.id === matches[0].id));
    } else {
      setSelectedIndex(-1);
    }
  };


  const handleClickAway = (state: any = null) => {
    if(selectedIndex !== -1){
      setInputValue(state?.code || stateDropDownValue || '');
      setValue('shipping_details.state_id', state?.id || '');
      setStateDropDownValue(state?.code || '');
      setSelectedIndex(-1);
    }else{
      setInputValue(state?.code || stateDropDownValue || '');
    }
    setStateInputFocus(false);
    inputRef.current?.blur();
  };

  const handleInputFocus = () => {
    setStateInputFocus(true);
    setInputValue('');
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.stateDropdownContainer}>
        <div className={stateInputFocus ? styles.stateWrapper : ''}>
          <input
            ref={inputRef}
            type="text"
            placeholder="STATE"
            value={inputValue}
            maxLength={2}
            className={styles.addressInputs}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={states.length === 0}
          />
          {stateInputFocus && <DropdownIcon onClick={handleClickAway} />}
        </div>
        
        {stateInputFocus && states.length > 0 && (
          <StatesList
            states={states}
            onStateClick={handleStateSelection}
            inputValue={inputValue}
            selectedIndex={selectedIndex}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default StateDropDown;
