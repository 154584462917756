import React from 'react'

import styles from '../home.module.scss'
import clsx from 'clsx'
import OrderSize from './OrderSize'
import PricingUnits from './PricingUnits'
import DomesticRequired from './DomesticRequired'

const SearchLeftSection = () => {

    return (
        <div className={styles.leftSection}>
            <OrderSize />
            <PricingUnits />
            <DomesticRequired />
        </div>
    )
}

export default SearchLeftSection