// @ts-nocheck
import { forwardRef, useEffect, useImperativeHandle } from "react";
import useGetCassAccessToken from "../../../hooks/useGetCassAccessToken";
import useCreateCassSupplier from "../../../hooks/useCreateCassSupplier";
import usePostCassResponse from "../../../hooks/usePostCassResponse";
import useGetSellerSetting from "../../../hooks/useGetSellerSetting";
import { cassErrorMessage, supplier, userRole } from "../../../common";
import { useImmer } from "use-immer";
import { formatPhoneNumberWithCountryCode } from "../../../helper";
import MatPopup from "../../../component/MatPopup/MatPopup";
import styles from "./Cass.module.scss";
import { useGlobalStore } from '@bryzos/giss-ui-library';

const Cass = forwardRef(({ getValues, referenceData, states }, ref) => {
  useImperativeHandle(ref, () => {
    return {
      startCassCreateion: startCassCreateion,
    };
  });

  const [promise, setPromise] = useImmer({ resolve: null, reject: null });
  const [startCreation, setStartCreation] = useImmer(false);

  const [responseMessage, setResponseMessage] = useImmer([]);

  const { userData } = useGlobalStore();

  const {
    data: cassAccessToken,
    refetch: getCassAccessToken,
    isLoading: isCassDataLoading,
    isFetching: isCassDataFetching,
    isFetched: isCassDataFetched,
  } = useGetCassAccessToken();

  const {
    refetch: getSellerSettingData,
    data: sellerSettingData,
    isLoading: isSellerSettingDataLoading,
    isFetching: isSellerSettingDataFetching,
  } = useGetSellerSetting(userData?.data?.id);

  const {
    mutate: createCassSupplier,
    data: createCassSupplierData,
    error: createCassSupplierError,
    isLoading: isCreateCassSupplierLoading,
  } = useCreateCassSupplier();

  const {
    mutate: postCassResponse,
    data: postCassResponseData,
    error: postCassResponseError,
    isLoading: isPostCassResponseLoading,
  } = usePostCassResponse();

  useEffect(() => {
    if (
      !isCassDataLoading &&
      !isCassDataFetching &&
      isCassDataFetched &&
      cassAccessToken &&
      !isSellerSettingDataLoading &&
      !isSellerSettingDataFetching &&
      sellerSettingData &&
      startCreation
    ) {
      setStartCreation(false);
      creationCassSupplier();
    }
  }, [
    isCassDataLoading,
    isCassDataFetching,
    isCassDataFetched,
    cassAccessToken,
    isSellerSettingDataLoading,
    isSellerSettingDataFetching,
    sellerSettingData,
  ]);

  useEffect(() => {
    if (!isCreateCassSupplierLoading) {
      if (createCassSupplierError?.message) {
        try {
          setResponseMessage(
            createCassSupplierError.message
              ? JSON.parse(createCassSupplierError.message)
              : []
          );
        } catch (error) {
          console.log(error);
        }
      } else if (createCassSupplierData) {
        setResponseMessage([]);
        postCassResponse({ data: { cass_unique_id: createCassSupplierData } });
      }
    }
  }, [createCassSupplierData, isCreateCassSupplierLoading]);

  useEffect(() => {
    if (!isPostCassResponseLoading) {
      if (postCassResponseData) {
        if (promise.resolve) {
          promise.resolve(postCassResponseData);
        }
      } else if (postCassResponseError) {
        if (promise.reject) {
          promise.reject(postCassResponseError);
        }
      }
    }
  }, [postCassResponseData, isPostCassResponseLoading, postCassResponseError]);

  useEffect(() => {
    if (userData?.data?.id) {
      getSellerSettingData(userData.data.id);
    }
  }, [userData]);

  const startCassCreateion = () => {
    setStartCreation(true);
    return new Promise((res, rej) => {
      setPromise({ resolve: res, reject: rej });
      const obj = getCassPayloadObj();
      if (obj) {
        getCassAccessToken();
      } else {
        rej(
          "bankName or disbursementRoutingNumber or disbursementAccountNumber is not valid"
        );
      }
    });
  };

  const creationCassSupplier = () => {
    const obj = getCassPayloadObj();
    if (obj && cassAccessToken) {
      createCassSupplier({ data: obj, accessToken: cassAccessToken });
    }
  };

  const getCassPayloadObj = () => {
    if (referenceData) {
      let disbursementMethod;
      let disbursementRoutingNumber;
      let disbursementAccountNumber;
      let disbursementTransactionCode = null;
      let refundTransactionCode = null;
      let stateOrProvince = null;
      let cassSellerUniqueIdentifierPrefix = "";
      let cassUniqueIdentifierTag = "";
      let bankName = "";

      if (states?.length) {
        const stateId = getValues("companyState");
        const obj = states.find((obj) => obj.id === stateId);

        if (obj?.code) {
          stateOrProvince = obj.code;
        }
      }

      if (getValues("achCheckBox")) {
        disbursementMethod = getDisbursementMethodId("ACH");
        disbursementRoutingNumber = getValues("routingNo");
        disbursementAccountNumber = getValues("accountNo");
        bankName = getValues("bankName1");
      } else if (getValues("wireCheckBox")) {
        disbursementMethod = getDisbursementMethodId("WIRE");
        disbursementRoutingNumber = getValues("routingNumber");
        disbursementAccountNumber = getValues("accountNumber");
        bankName = getValues("bankName");
      }

      let obj = referenceData.ref_general_settings.find(
        (obj) => obj.name === "CASS_DISBURSEMENT_TRANSACTION_CODE"
      );
      disbursementTransactionCode = obj?.value ?? null;

      obj = null;
      obj = referenceData.ref_general_settings.find(
        (obj) => obj.name === "CASS_REFUND_TRANSACTION_CODE"
      );
      refundTransactionCode = obj?.value ?? null;

      obj = null;
      obj = referenceData.ref_general_settings.find(
        (obj) => obj.name === "CASS_SELLER_UNIQUE_IDENTIFIER_PREFIX"
      );
      cassSellerUniqueIdentifierPrefix = obj?.value ?? "";

      obj = null;
      obj = referenceData.ref_general_settings.find(
        (obj) => obj.name === "CASS_UNIQUE_IDENTIFIER_TAG"
      );
      cassUniqueIdentifierTag = obj?.value ?? "";

      const uniqueIdentifier =
        cassSellerUniqueIdentifierPrefix + (sellerSettingData
          ? sellerSettingData[cassUniqueIdentifierTag]
          : "");

      if (
        !(bankName && disbursementRoutingNumber && disbursementAccountNumber) ||
        disbursementRoutingNumber?.includes("x") ||
        disbursementAccountNumber?.includes("x")
      ) {
        return null;
      }

      return {
        clientKey: import.meta.env.VITE_CASS_CLIENT_KEY,
        uniqueIdentifier: uniqueIdentifier,
        uniqueGroupIdentifier: sellerSettingData
          ? sellerSettingData.user_id
          : null,
        masterDataType: supplier,
        masterDataName: getValues("companyName")?.slice(0, 40),
        phoneNumber: formatPhoneNumberWithCountryCode(
          getValues("phoneNo") ?? ""
        ),
        primaryContactName:
          (getValues("firstName") + " " + getValues("lastName"))?.slice(0, 40),
        primaryContactEmailAddress: getValues("emailAddress")?.slice(0, 50),
        remitEmailAddresses: getValues("emailAddress")?.slice(0, 200),
        disbursementMethod: disbursementMethod,
        disbursementTransactionCode: disbursementTransactionCode,
        disbursementRoutingNumber: disbursementRoutingNumber,
        disbursementAccountNumber: disbursementAccountNumber,
        refundTransactionCode: refundTransactionCode,
        refundRoutingNumber: disbursementRoutingNumber,
        refundAccountNumber: disbursementAccountNumber,
        address1: getValues("companyAddressLine")?.slice(0, 40),
        address2: null,
        city: getValues("companyCity")?.slice(0, 40),
        stateOrProvince: stateOrProvince,
        zipOrPostalCode: getValues("companyZipCode")?.slice(0, 10),
      };
    }
  };

  const getDisbursementMethodId = (selectPaymentMethod) => {
    if (referenceData) {
      let selectPaymentId = findPaymentMethodId(selectPaymentMethod);

      if (selectPaymentId) {
        let obj = referenceData.reference_cass_disbursement_method.find(
          (item) => item.pgpm_mapping_id === selectPaymentId
        );
        return obj?.cass_code;
      }
    }
  };

  const findPaymentMethodId = (paymentMethod) => {
    let obj = referenceData.ref_pgpm_mapping.find(
      (item) =>
        item.payment_method === paymentMethod &&
        item.user_type === userRole.sellerUser.toLowerCase()
    );
    if (obj) {
      return obj.id;
    }
    return null;
  };

  const closeErrorPopup = () => {
    if (promise.reject) {
      promise.reject();
    }
    setResponseMessage([]);
  };

  return (
    <div>
      <MatPopup
        hideBackdrop
        popupClass={{
          root: styles.cassDialog,
          paper: styles.dialogContent,
        }}
        open={responseMessage?.length > 0}
      >
        {responseMessage?.map(
          (msg, index) =>
            msg?.errorMessage && (
              <p key={index}>{`${cassErrorMessage}${msg.errorMessage}`}</p>
            )
        )}
        <button className={styles.submitBtn} onClick={closeErrorPopup}>
          Close
        </button>
      </MatPopup>
    </div>
  );
});

export default Cass;
