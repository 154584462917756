import styles from '../../pages/search/home.module.scss'
import { ReactComponent as UsaFlag } from '../../assets/New-images/ButtonUsaMade.svg';
import { useSearchStore } from 'src/renderer2/store/SearchStore';

const ProductDescription = ({product}) => {
    const { selectedDomesticOption } = useSearchStore();
    const lines:string[] = product.UI_Description.split('\n');
    const firstLine:string = lines[0];
    const restLines:string[] = lines.slice(1);

    return (
            <div className={styles.searchProductDesc}>
                {(product.domestic_material_only && selectedDomesticOption) && <span className={styles.usaFlag}><UsaFlag /> </span>}
                <p className={styles.firstLine}>{firstLine}</p>
                {restLines.map((line: string, index:number) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
    );
}

export default ProductDescription;