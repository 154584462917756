import React, { useEffect, useState, useRef } from 'react';

import styles from '../home.module.scss';
import { useSearchStore } from '../../../store/SearchStore';
import { ProductPricingModel } from '../../../types/Search';
import { priceFormatter } from '../../../helper';
import RenderOdometers from '../../../component/Odometer/RenderOdometers';

type PricingFeedbackState = {
  product: ProductPricingModel
};

const PricePerUnit: React.FC<PricingFeedbackState> = ({ product }) => {
  const { selectedPriceUnit, searchZipCode, orderSizeSliderValue, selectedDomesticOption } = useSearchStore();
  const [formattedPrice, setFormattedPrice] = useState<string>("0.00");

  // Ref to store previous price for comparison
  const prevPriceRef = useRef<string>("0.00");

  useEffect(() => {
      setFormattedPrice(priceFormatter(product));
  }, [searchZipCode, orderSizeSliderValue, selectedPriceUnit, selectedDomesticOption]);

  // Update the previous price after rendering
  useEffect(() => {
    prevPriceRef.current = formattedPrice;
  }, [formattedPrice]);

  return (
    <div className={styles.priceSelectedWrapper}>
      <div className={styles.priceSelected}>
        <span className={styles.doller}>$</span>
        
        {/* Custom Odometers for each digit */}
        <div className={styles.displayRow}>
        <RenderOdometers
        previous={prevPriceRef.current}
        current={formattedPrice}
        />
        </div>

        <span className={styles.priceUnit}>
          <span className={styles.unitLbl}>PER </span> 
          {selectedPriceUnit === 'ea' ? 'PC' : selectedPriceUnit.toUpperCase()}
        </span>
      </div>
    </div>
  );
}

export default PricePerUnit;
