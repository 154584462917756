import React from "react";
import styles from './search/home.module.scss'
import ProductSearch from "./search/headerSection/productSearch";
import Header from "../pages/Header/Header";
import { useLocation } from "react-router-dom";
import { routes } from "../common";
import clsx from "clsx";

const SearchHeader = () => {
  const location = useLocation();

  return (
    <div className={clsx(styles.topSection, location.pathname === routes.homePage && styles.searchHeader)}>
      <Header />
      {
        location.pathname === routes.homePage && <ProductSearch />
      }
      <div className='bgEllips'></div>
      <div className='bgEllips1'></div>
    </div>
  )
};

export default SearchHeader;
