import { RefObject, useRef } from "react";

import styles from '../home.module.scss';
import { ProductPricingModel } from "src/renderer2/types/Search";
import clsx from "clsx";
import PricingFeedback from "./PricingFeedback";
import PricePerUnit from "./PricePerUnit";
// import AnimatedNumber from "./AnimateNumbers";

type ProductPricingState = {
    product: ProductPricingModel,
    productDescriptionAndPricingRef: RefObject<HTMLDivElement>,
    isMouseOverOnProduct: boolean
};

const ProductPricing: React.FC<ProductPricingState> = ({ product, productDescriptionAndPricingRef, isMouseOverOnProduct }) => {

    return <>
        <div className={styles.priceRating} >
            <div className={styles.priceMain}>
                <PricePerUnit
                    product={product}
                />
                <PricingFeedback
                    product={product}
                    productDescriptionAndPricingRef={productDescriptionAndPricingRef}
                    isMouseOverOnProduct={isMouseOverOnProduct}
                />
            </div>
        </div>
    </>
}

export default ProductPricing;