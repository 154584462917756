import VideoPlayer from 'src/renderer2/component/videoPlayer/videoPlayer'
import styles from './VideoPlayerRightWindow.module.scss'
import { ReactComponent as CloseXIcon } from '../../../assets/New-images/Close-x-icon.svg';
import { useRightWindowStore } from '../../RightWindow/RightWindowStore';
import { useState } from 'react';
import { ReactComponent as RightWindowPlayBtn } from '../../../assets/New-images/RightWindowVideoPlayBtn.svg';

const VideoPlayerRightWindow = () => {
    const { setShowVideo } = useRightWindowStore();
    const [playVideoUrl, setPlayVideoUrl] = useState('');

    return (
        <div className={styles.videoPlayerRightWindowContainer}>
            <div className={styles.videoPlayerSection}>
                {
                    playVideoUrl ? (
                        <VideoPlayer
                            url={playVideoUrl}
                            width={"100%"}
                            height={"275px"}
                            autoPlay={true}
                            playNextVideo={() => { }}
                            disableNextVideoBtn={true}
                            captionUrl={''}
                            hideControls={true}
                            isRightWindow={true}
                        />
                    )
                        :
                        <div className={styles.videoThumbnailContainer}  onClick={() => setPlayVideoUrl('https://d3hu27qw9bj9kd.cloudfront.net/prod/video-78a88f93-894d-48d8-9ea2-8040b09cc5ab.mp4')}>
                            <img src={'https://videolibrary.imgix.net/prod/thumbnail-a3123cc0-b722-4e76-9c25-a4cd36b12946.png'} height={"275px"} width={"100%"}></img>
                            <div className={styles.playBtnContainer}>
                                <RightWindowPlayBtn />
                            </div>
                        </div>

                }

            </div>
            <div className={styles.informationContainer}>
                <span className={styles.title}>PRICING BRACKET</span>
                <span className={styles.description}>The more you buy, the more you save.</span>
                <span className={styles.description}>Bryzos has multiple price buckets so your pricing will change incrementally.</span>
            </div>
            <p className={styles.closeButton} onClick={() => setShowVideo(false)}><CloseXIcon /></p>
        </div>
    )
}

export default VideoPlayerRightWindow
