import clsx from 'clsx';
import { useRightWindowStore } from './RightWindowStore';
import styles from './rightWindow.module.scss';
import { getChannelWindow } from '@bryzos/giss-ui-library';
import {  useRef } from 'react';
import { ReactComponent as  AcrylicCorner} from '../../assets/New-images/corner3.svg';
import VideoPlayerRightWindow from './VideoPlayerRightWindow/VideoPlayerRightWindow';

const RightWindow = ({rightWindowRef, routerContentRef}: {rightWindowRef: React.RefObject<HTMLDivElement>, routerContentRef: React.RefObject<HTMLDivElement>}) => {
    const { loadComponent, showVideo, setShowVideo } = useRightWindowStore();
    const channelWindow:any =  getChannelWindow();
    const divRef = useRef<HTMLDivElement>(null);

    const ignoreMouseEvents = () => {
        setTimeout(() => {
            handleMouseLeave();
        }, 1000);
    }

    const handleMouseEnter = () => {
        if(channelWindow?.ignoreMouseEvents){
            window.electron.send({ channel: channelWindow.ignoreMouseEvents, data:true})
        }
    }

    const handleMouseLeave = () => {
        if(channelWindow?.ignoreMouseEvents){
            window.electron.send({ channel: channelWindow.ignoreMouseEvents, data:false})
        }
    }

    if(!!!loadComponent){
        return <></>
    }
    return (
        <div className={styles.rightWindowContainer}>
            <div className={styles.rightWindow} ref={rightWindowRef}>
                <div className={clsx('wrapperOverlay', styles.wrapperOverlay)}></div>
                {loadComponent}
            </div>
            {(routerContentRef?.current?.offsetHeight !== rightWindowRef?.current?.offsetHeight) && (
                <div className={styles.nonFrameContainer}
                    onClick={ignoreMouseEvents}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={divRef}>
                    <div className={styles.roundCorner}><AcrylicCorner /></div>

                </div>)
            }
        </div>
    )
}

export default RightWindow;