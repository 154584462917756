// @ts-nocheck
import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify'; 
import { cognitoCookiePrefix, reactQueryKeys } from '../../common';
import { useGlobalStore } from '@bryzos/giss-ui-library';
import useSaveLoginActivityAnalytics from './useSaveLoginActivityAnalytics';
import { getUserAppData, stringifyError } from '../helper';
const store:any = useGlobalStore.getState();

const getUser = () => {
  return Auth.currentAuthenticatedUser().then((res) => {
    return res;
  });
};

const useCognitoUser = (isUserLoggedIn, securityHash) => {
  const saveLoginActivityAnalytics = useSaveLoginActivityAnalytics()
  return useQuery([reactQueryKeys.cognitoUser], getUser, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!isUserLoggedIn && !!document.cookie && !!securityHash,
    onError(err) {
      const data= getUserAppData()
      const payload = {
        ...data, reason:{error:stringifyError(err), isManualLogin:store.isManualLogin}, 
      }
      const isCookiePresent = document.cookie.split("; ").some((cookie)=>{
        const [name] = cookie.split("=");
        return name.startsWith(cognitoCookiePrefix);
      });
      if(isCookiePresent) saveLoginActivityAnalytics.mutate(payload);
      console.error('useCognitoUser ', err)
      store.setIsAppReadyToUseInState(true);
      store.setShowLoader(false)
      return err;
    },
  });
};

export default useCognitoUser;
