import { useSearchStore } from '../../../store/SearchStore'
import { ReactComponent as CenterArrowIcon } from '../../../assets/New-images/Big-Arrow.svg';
import clsx from 'clsx';
import ProductDescriptionAndPricing from './ProductDescriptionAndPricing';
import styles from '../home.module.scss'


const SelectedProductList = () => {
    const { filterShortListedSearchProductsData, searchByProductResult } = useSearchStore();

    return (
        <div className={styles.selectedProductWrapper}>
            {(filterShortListedSearchProductsData.length === 0) &&
                <div className={styles.NoResultsToDisplay}>
                    <CenterArrowIcon />
                    <div className={styles.NoResultsToDisplayText}>
                        <p className={styles.marginBottom10}>NO RESULTS TO DISPLAY</p>
                        <p>TYPE IN SEARCH BAR ABOVE</p>
                    </div>
                </div>
            }
            <div className={clsx(styles.selectedProductDescriptionMain, (searchByProductResult.length !== 0) && styles.searchResult)}>
                {filterShortListedSearchProductsData.map((selectedSearchProduct, i) => (
                    <ProductDescriptionAndPricing
                        key={selectedSearchProduct.id} i={i}
                        selectedSearchProduct={selectedSearchProduct}
                    />
                ))}
            </div>
        </div>
    )
}

export default SelectedProductList