// @ts-nocheck
import { Dialog } from "@mui/material";
import React from "react";

type Props = {
  children?: React.ReactNode,
  open: boolean;
  children?: React.ReactNode;
  popupClass: string;
  hideBackdrop: boolean
}

const MatPopup: React.FC<Props> = ({ open, children, popupClass, hideBackdrop }) => {
  return <Dialog open={!!open}
    classes={popupClass} hideBackdrop={hideBackdrop}>{children}</Dialog>;
};

export default MatPopup;
