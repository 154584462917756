import React from 'react'

import styles from '../home.module.scss'
import clsx from 'clsx'
import { useSearchStore } from '../../../store/SearchStore'
import { navigatePage } from 'src/renderer2/helper'
import { routes } from 'src/renderer2/common'

const SearchRightSection = () => {
    const { selectedProductsData, setSelectedProductsData, shortListedSearchProductsData, setShortListedSearchProductsData, focusSingleProduct, setFocusSingleProduct, filterShortListedSearchProductsData, setFilterShortListedSearchProductsData, saveFeedbackMap, setSaveFeedbackMap } = useSearchStore();

    const handleDeleteLines = () => {
        const spreadSelectedSearchProductsData = [...shortListedSearchProductsData];
        const filterShortListedSearchProductsData = spreadSelectedSearchProductsData.filter((productData) => {
            if (!focusSingleProduct[productData.id]) return true;
            setSaveFeedbackMap({ ...saveFeedbackMap, [productData.id]: undefined });
            return false;
        });
        setShortListedSearchProductsData(filterShortListedSearchProductsData);
        setFocusSingleProduct({});
        setSelectedProductsData([]);
    }

    const handleResetData = () => {
        setShortListedSearchProductsData([]);
        setFilterShortListedSearchProductsData([]);
        setSelectedProductsData([]);
        setSaveFeedbackMap({});
        setFocusSingleProduct({});
    }

    return (
        <div className={styles.rightSection}>
            <button className={styles.createPOBTn} onClick={() => navigatePage(location.pathname, {path:routes.createPoPage})}><span>CREATE PO</span></button>
            <div className={clsx(styles.actionMenu, (selectedProductsData.length === 0) && styles.overlay)}>
                <div className={styles.actionMenuTitle}>ACTION MENU</div>
                <button className={styles.actionBtn} onClick={() => { console.log('Share Pricing Data ', selectedProductsData) }} disabled={(selectedProductsData.length === 0)} >SHARE PRICING</button>
                <button className={styles.actionBtn} disabled={(selectedProductsData.length === 0)} >EXPORT PRICING</button>
                <button className={styles.actionBtn} disabled={(selectedProductsData.length === 0)} >SAVE PRICING</button>
                <button className={styles.actionBtn} onClick={handleDeleteLines} disabled={(selectedProductsData.length === 0)} >DELETE LINES</button>
                <button className={styles.actionBtn} disabled={(selectedProductsData.length === 0)} >ADD LINES TO PO</button>
            </div>
            <span className={styles.clearList}>
            <button disabled={filterShortListedSearchProductsData.length < 1} onClick={handleResetData}>
             CLEAR LIST
             </button>
            </span>
           
        </div>
    )
}

export default SearchRightSection