import clsx from 'clsx';
import styles from './chat-messages.module.scss';
import React, { useEffect, useState } from 'react';
import { Badge, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ReactionComponent from './reactions/ReactionComponent';
import ReactionMenu from './reactions/ReactionMenu';
import { useGlobalStore } from '@bryzos/giss-ui-library';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
    sdk: any;
    channelMessages: any[];
    userData: any;
    startLoader: boolean,
    isFetching: boolean
};
export const ChatMessages: React.FC<Props> = ({
    sdk,
    channelMessages,
    userData,
    startLoader,
    isFetching
}) => {
    const { showLoader }: any = useGlobalStore();

    const chatUserId = userData?.chat_data?.user_id;

    const [showMenu, setShowMenu] = useState(-1);
    const [selectedReactions, setSelectedReactions] = useState<string[]>([]);

    const isDateCurrentDate = (utcTime: string) => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const userLocalTime = dayjs.utc(utcTime).tz(userTimezone);
        const currentDate = dayjs().tz(userTimezone);
        return userLocalTime.isSame(currentDate, 'day');
    }

    function convertUTCToLocal(utcTime: string) {
        const localDateTime = dayjs.utc(utcTime).local();
        const date: string = isDateCurrentDate(utcTime) ? "Today" : localDateTime.format("D MMM YYYY");
        const time = localDateTime.format("h:mm A");
        return { date, time };
    }

    if (channelMessages.length > 0) {
        const chatElement: any[] = [];
        const len = channelMessages.length - 1;

        for (let i = len; i >= 0; i--) {
            const obj = channelMessages[i];

            const userName = obj.user.username.split("_");
            const userFirstLetter = userName[0][0]?.toUpperCase();
            let userLastLetter = "";
            if (userName.length > 1) {
                userLastLetter = userName[1][0]?.toUpperCase();
            }
            obj.username = userFirstLetter + userLastLetter;

            const dateObj = convertUTCToLocal(obj.created);
            obj.date = dateObj.date;
            obj.time = dateObj.time;

            chatElement.push(obj);
        }

        const sortByDate: any = {};

        chatElement.forEach((obj, i) => {
            const date = obj.date;

            if (i > 0) {
                obj.groupSameUserMsg = obj.user._id === chatElement[i - 1].user._id;
            }

            if (!Array.isArray(sortByDate[date])) {
                sortByDate[date] = []
            }
            sortByDate[obj.date] = [...sortByDate[obj.date], obj];
        });

        const handleReactionSelect = (reaction: string, msgObj: any) => {
            const alreadyLiked = msgObj.likes.filter((like: any) => like.userId === chatUserId && like.reaction === reaction);
            if (alreadyLiked.length === 0)
                sdk.likeMessage(msgObj._id, reaction, "liked");
            else 
                sdk.likeMessage(msgObj._id, reaction, "unliked");
            setShowMenu(-1);
        };

        const setReactMenuReactions = (msgObj:any) => {
            const alreadyLiked:string[] = msgObj.likes.filter((like:any)=>like.userId === chatUserId)
                                              .map((like:any)=>like.reaction);
            setSelectedReactions([...new Set(alreadyLiked)]);
        };

        return Object.keys(sortByDate)?.map(key => {
            const messages = sortByDate[key];
            return <div key={key}>
               <div className={styles.dateMain}>
                 <div className={styles.date}>{isFetching ? 'Loading...' : key}</div>
                </div> 
                {messages.map((obj: any, index: number) => (<div className={styles.chatMessagesContent} key={index} id={`message-${obj._id}`}>
                    {!obj.groupSameUserMsg && <Badge overlap="circular" classes={{ badge: obj.user._id === chatUserId ? styles.ownMessagesBedge : styles.ownMessagesBedge1 }} badgeContent={obj.username} color="secondary" className={clsx(styles.userName, obj.user._id === chatUserId && styles.ownMessagesMainContent, styles.userName)}></Badge>}
                    <div className={clsx(obj.user._id === chatUserId && styles.ownMessagesSection)} onMouseEnter={()=>{setReactMenuReactions(obj)}}>
                        <Tooltip classes={{ tooltip: styles.reactionTooltip }} title={<ReactionMenu selectedReactions={selectedReactions} onReactionSelect={(key) => { handleReactionSelect(key, obj) }} />}>
                            <div key={obj._id} className={clsx(styles.messageText, obj.user._id === chatUserId && styles.ownMessages)}>
                                <p dangerouslySetInnerHTML={{ __html: obj.message }}></p>
                                {!obj.groupSameUserMsg && <div className={styles.chatArrow}></div>}
                                <ReactionComponent reactions={obj.likes} sdk={sdk} messageId={obj._id} userId={chatUserId}/>
                                <span className={styles.chatTime}>{obj.time}</span>

                            </div></Tooltip>
                    </div>


                </div>))}
            </div>;
        });
    } else {
        return <div className={styles.noChatFound}>
            {!showLoader && !startLoader && <p>No Chats Found.</p>}
        </div>;
    }
};
