import React from 'react'

import styles from '../home.module.scss'
import clsx from 'clsx'
import { units } from 'src/renderer2/common'
import { useSearchStore } from '../../../store/SearchStore';

const PricingUnits = () => {
    const { selectedPriceUnit, setSelectedPriceUnit } = useSearchStore();

    return (
        <div className={styles.pricingUnit}>
            <div className={styles.leftFilterTitle}>PRICING UNITS</div>
            {units.map((unit) => (
                <button
                    key={unit.title}
                    className={clsx(styles.filterBtn, {
                        [styles.activeBtn]: selectedPriceUnit === unit.value,
                    })}
                    onClick={() => setSelectedPriceUnit(unit.value)}
                >
                    {unit.title}
                </button>
            ))}
        </div>
    )
}

export default PricingUnits