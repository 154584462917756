import React, { useEffect } from 'react'
import App from './renderer/App';
import App2 from './renderer2/App';
import { useLocation } from 'react-router-dom';

function VersionControl() {
    const location = useLocation();
    const isV2InPath = location.pathname.includes('/v2');

    useEffect(() => {
        (async()=>{
            if (isV2InPath) {
                await import('./renderer2/index.scss');
                await import('./renderer2/App.scss');
            
            } else {
                await import('./renderer/index.scss');
                await import('./renderer/App.scss');
            }
        })()
    }, []);

    return (<>
    {
        isV2InPath ?
        <App2/>
        :
        <App/>
    }
    </>
    )
}

export default VersionControl;