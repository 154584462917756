// @ts-nocheck
import { create } from 'zustand';

interface MenuState {
  openMenu: boolean;
  closeWithoutAnimation: boolean;
  setCloseWithoutAnimation: (closeWithoutAnimation: boolean) => void;
  resetMenuStore: () => void;
  setOpenMenu: (openMenu: boolean) => void;
}

const commonStore = {
    openMenu:false,
    closeWithoutAnimation:false,
  }
  
  
  export const useMenuStore = create<MenuState>((set, get) => ({
    ...commonStore,
    setOpenMenu: (openMenu: boolean) => set({ openMenu }),
    setCloseWithoutAnimation: (closeWithoutAnimation: boolean) => set({ closeWithoutAnimation }),
    resetMenuStore: () => set(state => ({
      ...commonStore
    })),
  }));
    