import { emojiRemoverRegex, formatPhoneNumber } from "@bryzos/giss-ui-library";

import clsx from 'clsx'
import styles from './CustomTextField.module.scss'
import { UseFormSetError } from "react-hook-form";
import { useState } from "react";

type CustomTextFieldProps = {
    type?: string,
    register: any,
    autoFocus?: boolean,
    onFocus?: Function,
    onChange?: Function,
    placeholder: string,
    onBlur?: Function,
    inputRef?: any,
    className?:any,
    errorInput?:any,
    maxLength?: number | undefined,
    readOnly?: boolean | undefined,
    mode?: string,
    setError?: React.Dispatch<React.SetStateAction<boolean>>,
    disabled?: boolean,
    tabIndex?: number,
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    id?: string
}

const CustomTextField: React.FC<CustomTextFieldProps> =({type='text', register={}, autoFocus, onFocus, onChange, placeholder, onBlur, inputRef, className, errorInput, maxLength, readOnly, mode, setError, disabled, tabIndex, onKeyDown, id }) => {


    const handleOnFocus = (e) => {
        if(onFocus) onFocus(e);
    }

    const handleOnChange = (e) => {
        e.target.value = e.target.value.replace(emojiRemoverRegex, '');
        if(setError && type === 'email'){
            if(e.target.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                setError(true);
            }else{
                setError(false);
            }
        }
        if(mode === 'wholeNumber') e.target.value = e.target.value.replace(/\D/g, '');
        if(mode === 'number') e.target.value = e.target.value.replace(/[^\d.]/g, '');
        if(mode === 'phoneNumber') e.target.value = formatPhoneNumber(e.target.value);
        if(mode === 'currency') e.target.value = e.target.value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        if(register) register.onChange(e);
        if(onChange) onChange(e)
        
    }

    const handleOnBlur = (e) => {
        e.target.value = e.target.value.trim();
        if(register) register.onBlur(e);
        if(onBlur) onBlur(e)
    }

    const handleInputRef = (e) => {
        inputRef && inputRef(e); if(register) register.ref(e);
    }

    return(
        <>
            <input
                {...register}
                autoFocus={autoFocus}
                onFocus={handleOnFocus}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                placeholder={placeholder}
                type={type}
                ref={handleInputRef}
                maxLength={maxLength}
                readOnly={readOnly}
                className={clsx(styles.input, !!errorInput && styles.inputError, className && className)}
                disabled={disabled}
                tabIndex={tabIndex}
                onKeyDown={onKeyDown}
                id={id}
            />
        </>
    )
}
    
export default CustomTextField;