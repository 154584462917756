// @ts-nocheck
import {  useEffect, useState, useRef } from "react";
import styles from "../createPo.module.scss";
import { Controller } from "react-hook-form";
import { Autocomplete, ClickAwayListener, Fade, Tooltip } from "@mui/material";
import { CustomMenu } from "../CustomMenu";
import clsx from "clsx";
import { ReactComponent as RemoveLineIcon } from "../../../assets/images/Remove-line.svg";
import { ReactComponent as RemoveLineHoverIcon } from "../../../assets/images/Remove-line-hover.svg";
import { ReactComponent as QuestionIcon } from '../../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../../assets/images/question-white-hover.svg';
import { ReactComponent as DomesticCheckTextIcon } from '../../../assets/New-images/Domestic-Check-Text.svg';
import { CommonTooltip } from "../../../component/Tooltip/tooltip";
import { v4 as uuidv4 } from 'uuid';
import { formatDollarPerUnit, searchProducts, getValidSearchData, useGlobalStore, MinSearchDataLen, formatToTwoDecimalPlaces } from '@bryzos/giss-ui-library';
import CustomTextField from "src/renderer2/component/CustomTextField";
import InputWrapper from "src/renderer2/component/InputWrapper";
const SAME_TAG_ERROR_MESSAGE =
  "Same part? <br />You have applied this part number to another product already.";

const CreatePoTile = ({
  index,
  register,
  fields,
  updateValue,
  products,
  control,
  setValue,
  watch,
  errors,
  getValues,
  userPartData,
  sessionId,
  selectedProduct,
  searchStringData,
  setSearchString,
  setLineSessionId,
  lineSessionId,
  handleCreatePOSearch,
  apiCallInProgress,
  updateLineProduct,
  removeLineItem,
  pricePerUnitChangeHandler,
  quantitySizeValidator,
  saveUserLineActivity,
  orderInfoIsFilled,
  setDisableBidBuyNow,
  openAddLineTab,
  setOpenDeliveryToDialog,
  hidePoLineScroll,
  setHidePoLineScroll,
  scrollToTop
}) => {
  const { userData , referenceData} = useGlobalStore();
  const [descriptionInput, setDescriptionInput] = useState("");
  const [sameTagErrorMsg, setSameTagErrorMsg] = useState(null);
  const [openDescription, setOpenDescription] = useState(false);
  const [isQtyInEditMode, setIsQtyInEditMode] = useState(true);
  const [showDomesticMaterialOnly, seShowDomesticMaterialOnly] = useState(true);
  const [enableRejectSearchAnalytic, setEnableRejectSearchAnalytic]= useState(false);
  const [focusPlaceHolder, setFocusPlaceHolder]= useState(false);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [isDescriptionModeEnabled, setIsDescriptionModeEnabled] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isDomesticFocused, setIsDomesticFocused] = useState(false);
  const lineNumberRef = useRef(null);

  const isOrderLineDisabled = !(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0) && !orderInfoIsFilled;

  useEffect(() => {
    setValue(`cart_items.${index}.line_session_id`, uuidv4());
    if (index > 0) {
      saveUserActivity();
    }
    qtyEditModeCloseHandler();
  }, []);

  useEffect(() => {
    const description = getValues(`cart_items.${index}.descriptionObj`);


    if (referenceData) {
      setValue(
        `cart_items.${index}.domesticMaterialOnly`,
        false
      );
    }
    if (description && Object.keys(description).length > 0) {
      if (!description.domestic_material_only) {
        seShowDomesticMaterialOnly(false);
        setValue(`cart_items.${index}.domesticMaterialOnly`, null);
      } else {
        seShowDomesticMaterialOnly(true);
      }
    } else {
      seShowDomesticMaterialOnly(true);
    }
  }, [referenceData, watch(`cart_items.${index}.descriptionObj`)]);

  useEffect(() => {
    const product_tag = getValues(`cart_items.${index}.product_tag`);
    const description = getValues(`cart_items.${index}.descriptionObj`);

    if (description && Object.keys(description).length > 0) {
      setValue(`cart_items.${index}.description`, description.UI_Description);
      setValue(`cart_items.${index}.shape`, description.Key2);
      setValue(`cart_items.${index}.product_id`, description.Product_ID);
      setValue(`cart_items.${index}.reference_product_id`, description.id);
      if (!description.domestic_material_only) {
        seShowDomesticMaterialOnly(false);
        setValue(`cart_items.${index}.domesticMaterialOnly`, null);
      } else {
        seShowDomesticMaterialOnly(true);
      }
    } else {
      seShowDomesticMaterialOnly(true);
    }

    if (description && Object.keys(description).length > 0) {
      if (userPartData && Object.keys(userPartData)?.length) {
        let isMappingExist = false;
        const i = Object.keys(userPartData).findIndex((key)=>{
          if(userPartData[key] === product_tag){
            isMappingExist = true;
            if(+key === description.Product_ID){
               return true;
            }
          }
        })
        setSameTagErrorMsg(i > -1 ? null : SAME_TAG_ERROR_MESSAGE);
        if (!isMappingExist) {
          setSameTagErrorMsg(null);
        }
      }
    } else {
      setSameTagErrorMsg(null);
      setValue(`cart_items.${index}.product_tag`, "");
    }
  }, [
    watch(`cart_items.${index}.product_tag`),
    watch(`cart_items.${index}.descriptionObj`),
  ]);

  useEffect(() => {
    if (!getValues(`cart_items.${index}.descriptionObj`) || errors?.cart_items?.[index]?.qty) {
      setIsQtyInEditMode(true);
    }
  }, [
    getValues(`cart_items.${index}.descriptionObj`),
    errors?.cart_items?.[index]?.qty,
  ]);


useEffect(() => {
  if(selectedProduct && selectedProduct.UI_Description === descriptionInput && lineSessionId === getValues(`cart_items.${index}.line_session_id`)){
    setEnableRejectSearchAnalytic(false)
    handleCreatePOSearch(searchStringData, 'Accept', getValues(`cart_items.${index}.line_session_id`))
  }
},[selectedProduct])

useEffect(() => {
  if((!selectedProduct && descriptionInput.length >= MinSearchDataLen) || (descriptionInput.length >= MinSearchDataLen)){
    setSearchString(descriptionInput)
    setLineSessionId(getValues(`cart_items.${index}.line_session_id`))
  }
  if(descriptionInput.length === 1 ){
    setEnableRejectSearchAnalytic(true)
  }
  if(descriptionInput.length === 0 && searchStringData.length !== 0 && searchStringData.length >= MinSearchDataLen && enableRejectSearchAnalytic){
    handleCreatePOSearch(searchStringData, 'Reject', getValues(`cart_items.${index}.line_session_id`))
    setEnableRejectSearchAnalytic(false)
  }
},[descriptionInput])

  function display(data) {
    const lines = data.UI_Description.split("\n");
    const firstLine = lines[0];
    const restLines = lines.slice(1);

    return (
      <>
        <p className="liFisrtLine">{firstLine}</p>
        {restLines.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </>
    );
  }

  const qtyEditModeCloseHandler = () => {
    if (
      getValues(`cart_items.${index}.descriptionObj`) &&
      getValues(`cart_items.${index}.qty`) &&
      !errors?.cart_items?.[index]?.qty
    ) {
      setIsQtyInEditMode(false);
    } else {
      setIsQtyInEditMode(true);
    }
  };

  const descriptionEditModeCloseHandler = () => {
   setIsDescriptionModeEnabled(false)
  };

  const quantityChangeHandler = (event:any) => {
    quantitySizeValidator(event,index);
    updateValue(index);
  }

  const dollerPerUmFormatter = (umVal) => {
    const price_unit = getValues(`cart_items.${index}.price_unit`);
    return formatDollarPerUnit(price_unit,umVal,index);
  }

  const saveUserActivity = (isRemovingLine = false) => {
    if(watch(`cart_items.${index}.descriptionObj`)){
      saveUserLineActivity(sessionId, isRemovingLine, index);
    }
  }

  const getPlaceholder = (index) => {
    const cartItems = watch('cart_items');
    const firstEmptyIndex = cartItems ? cartItems.findIndex(item => !item?.descriptionObj || !item?.descriptionObj?.UI_Description) : -1;
    if (focusPlaceHolder) {
      return "EX:  BEAM 4\" x 13 . . .\nOR ENTER A TAGGED PART #";
    } else if (index === firstEmptyIndex && !orderInfoIsFilled) {
      return "ALL HEADER INFORMATION MUST BE ENTERED BEFORE ENTERING LINE ITEMS";
    } else {
      return "";
    }
  };
  
  return (
    <>
    <tr
    >
      <td>
        <div className={styles.prodId}>
          {/* <span className={styles.numberContainer}>{index + 1}</span> */}

          <div className={styles.domesticMaterialCheckbox} >
            <label className={styles.lineNumberContainer}>
              <input
                type="checkbox"
                {...register(`cart_items.${index}.domesticMaterialOnly`)}
                checked={watch(`cart_items.${index}.domesticMaterialOnly`) ?? false}
                onChange={(e) => {
                  if (!showDomesticMaterialOnly || !(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0)) {
                    return; // Prevent changes when visually disabled
                  }
                  register(`cart_items.${index}.domesticMaterialOnly`).onChange(e);
                  saveUserActivity();
                }}
                className={styles.hiddenCheckbox}
                // We keep it enabled for keyboard navigation but mark it as aria-disabled
                aria-disabled={(!showDomesticMaterialOnly || (!(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0)))}
              />
              <span
                className={clsx(
                  styles.customNumberToggle,
                  watch(`cart_items.${index}.domesticMaterialOnly`) ? styles.active : "",
                  (!showDomesticMaterialOnly || (!(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0))) ? styles.disabled : ""
                )}
                onBlur={() => {
                  setIsDomesticFocused(false);
                }}
                onFocus={() => {
                  console.log("focus new data");
                  setIsHovering(false); // Turn off hover style when focused
                  setIsDomesticFocused(true);
                }}
                onKeyDown={(e) => {
                  // Always become active when receiving keyboard focus, even when visually disabled
                  setIsDomesticFocused(true);
                  
                  // Allow toggling with Enter or Space only when not visually disabled
                  const isVisuallyDisabled = !showDomesticMaterialOnly || 
                    !(watch(`cart_items.${index}.descriptionObj`) && 
                      Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0);
                      
                  if ((e.key === 'Enter' || e.key === ' ') && !isVisuallyDisabled) {
                    e.preventDefault();
                    const newValue = !(watch(`cart_items.${index}.domesticMaterialOnly`) ?? false);
                    setValue(`cart_items.${index}.domesticMaterialOnly`, newValue);
                    saveUserActivity();
                  }
                  
                  // Handle tab navigation
                   if (e.key === 'Tab' && !e.shiftKey) {
                    // Forward tab to next element
                    setIsDomesticFocused(false);
                  }
                }}
                role="checkbox"
                id={"domesticMaterialCheckbox"+index}
                aria-checked={watch(`cart_items.${index}.domesticMaterialOnly`) ?? false}
                aria-disabled={(!showDomesticMaterialOnly || (!(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0)))}
                ref={lineNumberRef}
                tabIndex={(!showDomesticMaterialOnly || (!(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0))) ? -1 : 0}
              >
                {index + 1}
              </span>
              <span className={clsx(styles.usaOnlyText, watch(`cart_items.${index}.domesticMaterialOnly`) ? "" : styles.visibilityHidden)}>USA<br />ONLY</span>
            </label>
          </div>
          {(isHovering && showDomesticMaterialOnly && !watch(`cart_items.${index}.domesticMaterialOnly`)) && <DomesticCheckTextIcon className={styles.domesticCheckText} />}
        </div>
      </td>
      <td>
        <div className={clsx(styles.poDescriptionDiv, (isOrderLineDisabled) && styles.disabled)}>
          {isDescriptionModeEnabled ? (
            <ClickAwayListener onClickAway={descriptionEditModeCloseHandler}>
              <div>
                <Controller
                  name={register(`cart_items.${index}.descriptionObj`).name}
                  control={control}
                  render={({ field: { ...rest } }) => (
                    <Autocomplete
                      id={`combo-box-demo${index}`}
                      disabled={apiCallInProgress}
                      open={openDescription}
                      onClose={(e) => {
                        if (e?.keyCode === 27) {
                          setDescriptionInput('');
                        }
                        setOpenDescription(false);
                      }}
                      options={products?.length ? products : []}
                      inputValue={descriptionInput}
                      onInputChange={(_, value, reason) => {
                        if(value.length > 1 && reason === "input") setOpenDescription(true);
                        setDescriptionInput(value); setDisableBidBuyNow(true)
                      }}
                      value={watch(`cart_items.${index}.descriptionObj`)}
                      disablePortal
                      classes={{
                        root: styles.autoCompleteDesc,
                        popper: styles.autocompleteDescPanel,
                        paper: styles.autocompleteDescInnerPanel,
                        listbox: styles.listAutoComletePanel,
                        noOptions: clsx(styles.noOptionPanel, openDescription && styles.visibleNoOption),
                      }}
                      filterOptions={(options, state) => {
                        const searchTerm = state.inputValue.trim();
                        if (searchTerm.length <= 1) return [];

                        const filteredResults = searchProducts(
                          options,
                          getValidSearchData(searchTerm),
                          searchTerm,
                          userPartData,
                          true
                        );

                        setHasSearchResults(Boolean(filteredResults?.length));
                        return filteredResults;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (!option || !value) return false;
                        return option.UI_Description === value.UI_Description;
                      }}
                      getOptionLabel={(item) => item?.UI_Description?.toUpperCase() || ""}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <textarea
                            {...params.inputProps}
                            name={register(`cart_items.${index}.descriptionObj`).name}
                            type="text"
                            placeholder={getPlaceholder(index)}
                            className={clsx(
                              styles.poDescription,
                              (openDescription && descriptionInput.length > MinSearchDataLen && hasSearchResults) && styles.poDescriptionOpen,
                              (watch(`cart_items.${index}.descriptionObj`)?.UI_Description?.length > 0) && styles.poDescriptionFirstLine
                            )}
                            onFocus={() => {
                              params.inputProps.onFocus();
                              setFocusPlaceHolder(true);
                              setIsHovering(true);
                              openAddLineTab();
                            }}
                            onBlur={() => {
                              params.inputProps.onBlur();
                              setFocusPlaceHolder(false);
                              setIsHovering(false);
                              setIsDescriptionModeEnabled(false);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Tab') {
                                if (e.shiftKey) {
                                  const isCheckboxDisabled = !showDomesticMaterialOnly ||
                                    !(watch(`cart_items.${index}.descriptionObj`) &&
                                      Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0);

                                  // Skip focusing the domestic material checkbox if it's disabled
                                  if (isCheckboxDisabled) {
                                    return; // Let default tab behavior work
                                  }

                                  e.preventDefault();
                                  if (lineNumberRef && lineNumberRef.current) {
                                    setIsHovering(false);
                                    setIsDomesticFocused(true);
                                    lineNumberRef.current.focus();
                                  }
                                } 
                                else {
                                  if (openDescription && hasSearchResults) {
                                    e.preventDefault();
                                    const inputValue = params.inputProps.value;
                                    const activeElement = document.activeElement;
                                    
                                    // Get the aria-activedescendant attribute to find the currently focused option
                                    const activeDescendantId = activeElement.getAttribute('aria-activedescendant');
                                    
                                    if (activeDescendantId) {
                                      // Extract the option index from the ID (e.g., "combo-box-demo0-option-1" => 1)
                                      const optionIndexMatch = activeDescendantId.match(/option-(\d+)$/);
                                      if (optionIndexMatch && optionIndexMatch[1]) {
                                        const optionIndex = parseInt(optionIndexMatch[1]);
                                        // Get the filtered products based on the current input
                                        const options = products?.length ? products : [];
                                        const filteredOptions = searchProducts(
                                          options,
                                          getValidSearchData(inputValue),
                                          inputValue,
                                          userPartData,
                                          true
                                        );   
                                        // Get the selected item using the option index
                                        if (optionIndex >= 0 && optionIndex < filteredOptions.length) {
                                          const selectedItem = filteredOptions[optionIndex];
                                          // Update the form value with the selected item
                                          updateLineProduct(index, selectedItem);
                                          setValue(`cart_items.${index}.descriptionObj`, selectedItem);
                                          saveUserActivity();
                                          setOpenDescription(false);
                                          setDescriptionInput(selectedItem?.UI_Description?.toUpperCase() || "");
                                          setIsDescriptionModeEnabled(false);
                                          setIsQtyInEditMode(true);
                                          // Focus on the quantity field after selection
                                          setTimeout(() => {
                                            const qtyInput = document.querySelector(`input[name="cart_items.${index}.qty"]`);
                                            if (qtyInput instanceof HTMLElement) {
                                              qtyInput.focus();
                                            }
                                          }, 100);
                                        }
                                      }
                                    }
                                  } 
                                }

                                setOpenDeliveryToDialog(false);
                                setTimeout(() => {
                                  const descriptionInput = document.querySelector('input[name="shipping_details.zip"]');
                                  if (descriptionInput instanceof HTMLElement) {
                                    descriptionInput.focus();
                                  }
                                }, 100);
                              }
                            }}
                            autoFocus // Added autoFocus to automatically focus when rendered
                          />
                        </div>
                      )}
                      {...rest}
                      onChange={(_, item) => {
                        setOpenDescription(false)
                        if (item) {
                          updateLineProduct(index, item);
                          setIsQtyInEditMode(true);
                          rest.onChange(item);
                          
                          // Focus on the quantity field after a short delay
                          setTimeout(() => {
                            const qtyInput = document.querySelector(`input[name="cart_items.${index}.qty"]`);
                            if (qtyInput instanceof HTMLElement) {
                              qtyInput.focus();
                            }
                          }, 100);
                        }
                      }}
                      onBlur={(e) => {
                        if (!descriptionInput) {
                          setDescriptionInput('');
                          setValue(`cart_items.${index}.descriptionObj`, {});
                          updateLineProduct(index);
                        }
                        setOpenDescription(false)
                        rest.onBlur(e);
                        saveUserActivity();
                      }}
                      renderOption={(props, option) => (
                        <span key={option.id} {...props}>
                          {display(option)}
                        </span>
                      )}
                    />
                  )}
                />
              </div>
            </ClickAwayListener>
          )
          :
            <p className={styles.descriptionModeDisabled}>
              <textarea
                type="text"
                name={register(`cart_items.${index}.descriptionObj`).name}
                id={`combo-box-demo${index}`}
                value={watch(`cart_items.${index}.descriptionObj`)?.UI_Description?.toUpperCase() || ''}
                placeholder={getPlaceholder(index)}
                className={clsx(styles.poDescription, (watch(`cart_items.${index}.descriptionObj`)?.UI_Description?.length > 0) && styles.poDescriptionFirstLine)}
                onClick={() => {
                  setIsDescriptionModeEnabled(true);
                }}
                onFocus={() => {
                  setIsHovering(true);
                  setIsDescriptionModeEnabled(true)
                  openAddLineTab()
                }}
                onBlur={(e) => {
                  setIsHovering(false);
                }}
                onKeyDown={(e) => {
                  if(e.key === 'Tab' && e.shiftKey){
                    const isCheckboxDisabled = !showDomesticMaterialOnly || 
                      !(watch(`cart_items.${index}.descriptionObj`) && 
                        Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0);
                    
                    // Skip focusing the domestic material checkbox if it's disabled
                    if (isCheckboxDisabled) {
                      return; // Let default tab behavior work
                    }
                    
                    e.preventDefault();
                    if (lineNumberRef && lineNumberRef.current) {
                      setIsHovering(false);
                      setIsDomesticFocused(true);
                      lineNumberRef.current.focus();
                    }
                    
                    setOpenDeliveryToDialog(false);
                    setTimeout(() => {
                      const descriptionInput = document.querySelector('input[name="shipping_details.zip"]');
                      if (descriptionInput instanceof HTMLElement) {
                        descriptionInput.focus();
                      }
                    }, 100);
                  }
                }}
                disabled={isOrderLineDisabled}
                readOnly
              />
            </p>
          }
            {watch(`cart_items.${index}.descriptionObj`)?.UI_Description &&
              <Tooltip
                title={
                  sameTagErrorMsg && (
                    <span
                      dangerouslySetInnerHTML={{ __html: sameTagErrorMsg }}
                    ></span>
                  )
                }
                arrow
                placement={"bottom-end"}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
                classes={{
                  tooltip: "partNumberTooltip",
                }}
              >
                <div className={styles.partNumberFiled}>
                  <InputWrapper>
                    <CustomTextField
                      type='text'
                      register={register(`cart_items.${index}.product_tag`)}
                      disabled={apiCallInProgress}
                      placeholder="Add YOUR PART #"
                      className={clsx({ [styles.errorInput]: errors?.cart_items?.[index]?.product_tag?.message })}
                      value={watch(`cart_items.${index}.product_tag`) ?? ""}
                      onFocus={() => {
                        setIsHovering(true);
                        setIsDescriptionModeEnabled(false);
                      }}
                      onBlur={(e) => {
                        register(`cart_items.${index}.product_tag`).onBlur(e);
                        saveUserActivity();
                        setIsHovering(false);
                        // setIsQtyInEditMode(true);
                      }}
                      onKeyUp={(e) => {
                        setValue(
                          `cart_items.${index}.product_tag`,
                          e.target.value
                        );
                      }}
                    />
                  </InputWrapper>
                </div>
              </Tooltip>
            }

        </div>
      </td>
      <td>
        <div className={styles.poQty}>
          <div>
            {isQtyInEditMode ? (
              <ClickAwayListener  onClickAway={qtyEditModeCloseHandler}>
                <Tooltip
                  title={ watch(`cart_items.${index}.qty`) !== null &&
                    errors?.cart_items?.[index]?.qty?.message
                  }
                  arrow
                  placement={"bottom-start"}
                  disableInteractive
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                  classes={{
                    tooltip: "inputQtyTooltip",
                  }}
                >
                  <div>
                    <InputWrapper>
                      <CustomTextField 
                        type='text'
                        register={register(`cart_items.${index}.qty`)}
                        disabled={!(watch(`cart_items.${index}.descriptionObj`) && Object.keys(watch(`cart_items.${index}.descriptionObj`)).length > 0)}
                        value={watch(`cart_items.${index}.qty`) ?? ""}
                        onFocus={() => {
                          setIsHovering(true);
                          openAddLineTab();
                        }}
                        className={clsx(errors?.cart_items?.[index]?.qty?.message && styles.errorQty)}
                        onChange={(e) => {
                          quantityChangeHandler(e);
                        }}
                        onBlur={(e) => {
                          register(`cart_items.${index}.qty`).onBlur(e);
                          qtyEditModeCloseHandler();
                          saveUserActivity();
                          setIsHovering(false);
                        }}
                        errorInput={errors?.cart_items?.[index]?.qty?.message}
                        autoFocus={true}
                      />
                    </InputWrapper>
                  </div>
                </Tooltip>
              </ClickAwayListener>
            ) : (
                <p
                  className={styles.poQtyValue}
                >
                  <input type="text" className={styles.poQtyValue} value={watch(`cart_items.${index}.qty`) ? formatToTwoDecimalPlaces(watch(`cart_items.${index}.qty`)) : ''}
                    onClick={() => {
                      setIsQtyInEditMode(true);
                    }}
                    onFocus={() => {
                      setIsHovering(true);
                      setIsQtyInEditMode(true);
                    }}
                    onBlur={() => {
                      setIsHovering(false);
                    }}
                    readOnly />
                </p>
              )}
          </div>
          {watch(`cart_items.${index}.qty_unit`) &&
            <span className={styles.selectUom}>
            <CustomMenu
              name={register(`cart_items.${index}.qty_unit`).name}
              control={control}
              disabled={apiCallInProgress}
              onChange={() => {
                updateValue(index);
                saveUserActivity();
              }}
              items={
                getValues(`cart_items.${index}.qty_um`)?.map((x) => ({ title: x, value: x })) ?? []
              }
              className={styles.uomDrodown}
              MenuProps={{
                classes: {
                  paper: styles.selectUomPaper,
                },
                id: `qty-unit-menu-${index}` // Add index to make ID unique
              }}
              onKeyDown={(e) => {
                if (e.key === 'Tab' && document.activeElement?.closest(`#qty-unit-menu-${index}`)) { // Update selector to match new ID
                  const value = document.activeElement.textContent;
                  setValue(`cart_items.${index}.qty_unit`, value);
                  updateValue(index);
                  saveUserActivity();
                }
              }}
              placeholder={"Ft"}
            />
          </span>
          }
        </div>
      </td>
      <td>
        <div className={styles.poPerUm}>
          <div>
            {!!(watch(`cart_items.${index}.price`)) &&
              dollerPerUmFormatter(watch(`cart_items.${index}.price`) ?? 0)
            }
          </div>
          {watch(`cart_items.${index}.price_unit`) &&
            <span className={styles.selectUom1}>
              <CustomMenu
                name={register(`cart_items.${index}.price_unit`).name}
                control={control}
                disabled={apiCallInProgress}
                onChange={() => {
                  pricePerUnitChangeHandler(index);
                  saveUserActivity();
                }}
                items={
                  getValues(`cart_items.${index}.price_um`)?.map((x) => ({ title: x, value: x })) ?? []
                }
                className={styles.uomDrodown}
                MenuProps={{
                  classes: {
                    paper: styles.selectUomPaper,
                  },
                  id: `price-unit-menu-${index}` // Add index to make ID unique
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Tab' && document.activeElement?.closest(`#price-unit-menu-${index}`)) { // Update selector to match new ID
                    const value = document.activeElement.textContent;
                    setValue(`cart_items.${index}.price_unit`, value);  
                    pricePerUnitChangeHandler(index);
                    saveUserActivity();
                  }
                }}
                placeholder={"Ft"}
              />
            </span>
          }
        </div>
      </td>
      <td>
        <div className={styles.extendedValue}>
          {!!(watch(`cart_items.${index}.extended`)) &&
            formatToTwoDecimalPlaces(watch(`cart_items.${index}.extended`) ?? 0)
          }
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      {/* <td colSpan={4}>
        <div className={styles.domesticMaterialCheckbox}>
          <div className={styles.div50}>
            {showDomesticMaterialOnly && (
              <label className={clsx(styles.lblCheckbox, "containerChk")}>
                <input
                  type="checkbox"
                  checked={watch(
                    `cart_items.${index}.domesticMaterialOnly`
                  )}
                  {...register(
                    `cart_items.${index}.domesticMaterialOnly`
                  )}
                  onChange={(e) => {
                    register(`cart_items.${index}.domesticMaterialOnly`).onChange(e);
                    saveUserActivity();
                  }}
                />
                <span className={styles.domesticMaterialTex}>
                  Domestic (USA) Material Only
                </span>
                <span className={clsx(styles.checkmark, "checkmark")} />
              </label>
            )}
          </div>
        </div>
      </td> */}

    </tr>
    </>
  );
};

export default CreatePoTile;
