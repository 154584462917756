import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useSellerOrderStore, useGlobalStore, selectProduct, dataOpticsApi1, dataOpticsApi2, getFormattedProductPricing, ReferenceDataProduct, userRole, referenceProductItem } from '@bryzos/giss-ui-library';
import { ProductPricingModel, SearchAnalyticDataModel, HttpRequestPayload } from '../../types/Search';
import styles from './home.module.scss'
import clsx from 'clsx';
import SearchHeader from '../SearchHeader';
import { useSearchStore } from '../../store/SearchStore';
import SearchLeftSection from './searchLeftSection/SearchLeftSection';
import SearchResultPanel from './mainContent/SearchResultPanel';
import SelectedProductList from './mainContent/SelectedProductList';
import SearchRightSection from './searchRightSection/SearchRightSection';
import MainContent from './mainContent/MainContent';

const Home = () => {
    const { enableShareWidget, setEnableShareWidget, setShowLoader, setSearchSessionId, searchSessionId, userData, discountData, productMapping }: any = useGlobalStore();

    const { shortListedSearchProductsData, setShortListedSearchProductsData, searchByProductResult, selectedProductsData, setSelectedProductsData, selectedPriceUnit, selectedDomesticOption, setFilterShortListedSearchProductsData, setFilterSearchByProductResult, sessionId, setSessionId } = useSearchStore();

    const [showWidgetPanel, setShowWidgetPanel] = useState<boolean>(false);
    const { resetFiltersInPurchaseOrders }: any = useSellerOrderStore();
    const analyticRef = useRef<string>();
    const [isShareWidget, setIsShareWidget] = useState<boolean>(false);
    analyticRef.current = sessionId;


    useEffect(() => {
        resetFiltersInPurchaseOrders();
        setShowLoader(false);
        if (searchSessionId) {
            setSessionId(searchSessionId)
        } else {
            const sessionId = uuidv4();
            setSessionId(sessionId);
        }

        return () => {
            setEnableShareWidget(false);
            const dataOpticsPayload = {
                "data": {
                    "session_id": analyticRef.current,
                    "move_to_screen": location.pathname.replace('/', "")
                }
            }
            dataOpticsApi2(dataOpticsPayload)
        }
    }, []);

    useEffect(() => {
        if (enableShareWidget) {
            handleOpenWidget();
        }
        else {
            handleCloseWidget();
        }
    }, [enableShareWidget])

    useEffect(() => {
        if (sessionId) {
            setSearchSessionId(sessionId);
        }
    }, [sessionId])

    useEffect(() => {
        if (productMapping && shortListedSearchProductsData?.length) {
            const selectedProductsCopy: ProductPricingModel[] = [];
            shortListedSearchProductsData.forEach((selectedProduct: ProductPricingModel) => {
                selectedProductsCopy.push(getFormattedProductPricing(productMapping[selectedProduct.id], userData.data.type));
            });
            setShortListedSearchProductsData(selectedProductsCopy);
        }
        else {
            setShortListedSearchProductsData([]);
        }
    }, [discountData, productMapping]);


    useEffect(() => {
        filterSearchByProductList();
        filterSelectedSearchProductList();
    }, [selectedDomesticOption, shortListedSearchProductsData]);

    useEffect(() => {
        filterSearchByProductList()
    }, [searchByProductResult]);

    const filterSearchByProductList = () => {
        if (searchByProductResult.length !== 0) {
            let filteredSearchByProductResult = [...searchByProductResult];
            if (selectedDomesticOption) {
                filteredSearchByProductResult = filteredSearchByProductResult.filter(product => product.domestic_material_only);
            }
            setFilterSearchByProductResult(filteredSearchByProductResult);
        } else {
            setFilterSearchByProductResult([]);
        }
    }

    const filterSelectedSearchProductList = () => {
        if (shortListedSearchProductsData.length !== 0) {
            let filteredSelectedProductSearchData = [...shortListedSearchProductsData];
            if (selectedDomesticOption) {
                filteredSelectedProductSearchData = shortListedSearchProductsData.filter((selectedProduct) => selectedProduct.domestic_material_only);
            }
            setFilterShortListedSearchProductsData(filteredSelectedProductSearchData);
        } else {
            setFilterShortListedSearchProductsData([]);
        }
    }

    const handleOpenWidget = () => {
        setShowWidgetPanel(true);
        setIsShareWidget(true);
    };
    const handleCloseWidget = () => {
        setShowWidgetPanel(false);
        setIsShareWidget(false);
        setEnableShareWidget(false);
    };

    const onShareProductPricing = async (emailTo: string, emailContent: string): Promise<void> => {
        const _selectedProduct: ProductPricingModel[] = selectedProductsData.length === 0 ? shortListedSearchProductsData : selectedProductsData;
        const productList: any[] = [];
        const dataOpticsData: SearchAnalyticDataModel[] = [];
        _selectedProduct.forEach((product: ProductPricingModel) => {
            productList.push({
                "product_id": product.id,
                "product_description": product.UI_Description,
                "price_ft": product.ft_price.trim().replace("$", ""),
                "price_lb": product.lb_price.trim().replace("$", ""),
                "price_cwt": product.cwt_price.trim().replace("$", ""),
                "price_share_type": selectedPriceUnit !== 'cwt,ft' ? selectedPriceUnit : product.product_type_pipe ? "ft" : "cwt",
            });
            dataOpticsData.push({
                "session_id": sessionId,
                "line_session_id": product.line_session_id,
                "product_id": product.id,
                "description": product.UI_Description,
                "price_shared": true,
                "search_price_unit": selectedPriceUnit !== 'cwt,ft' ? selectedPriceUnit : product.product_type_pipe ? "ft" : "cwt",
            })
        });

        const dataOpticsPayload: HttpRequestPayload<SearchAnalyticDataModel[]> = {
            "data": dataOpticsData
        }
        dataOpticsApi1(dataOpticsPayload)
        const payload = {
            data: {
                "user_id": userData.data.id,
                "from_email": userData.data.email_id,
                "to_email": emailTo,
                "email_content": emailContent.length === 0 ? null : emailContent,
                "products": productList,
            }
        }
        try {
            const res = await axios.post(import.meta.env.VITE_API_SERVICE + '/user/shareProductPrice', payload)
            setSelectedProductsData([]);
            return res.data.data
        } catch (err) {
            throw new Error("Share Product Pricing Api Failure");
        }
    }

    const onShareApp = async (emailTo: string, emailContent: string): Promise<void> => {
        const payload = {
            data: {
                "user_id": userData.data.id,
                "from_email": userData.data.email_id,
                "to_email": emailTo,
                "email_content": emailContent.length === 0 ? null : emailContent
            }
        }
        try {
            const res = await axios.post(import.meta.env.VITE_API_SERVICE + '/user/shareWidgetRequest', payload)
            return res.data.data;
        } catch (err) {
            throw new Error("Share App Api Failure");
        }
    }

    const shareHandler = isShareWidget ? onShareApp : onShareProductPricing;

    const handleSharePrice = () => {
        setShowWidgetPanel(true);
    }

    return (
        <div className={styles.mainContent}>
            <SearchHeader />
            <div className={styles.innerContent}>
                <SearchLeftSection />
                <MainContent />
                <SearchRightSection />
            </div>
        </div>
    );
};

export default Home;

