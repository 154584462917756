import { useEffect, useRef, useState } from "react";
import { MinSearchDataLen } from "../../../common";
import { useDebouncedValue } from "@mantine/hooks";
import axios from "axios";
import { getValidSearchData, searchProducts, useGlobalStore } from '@bryzos/giss-ui-library';
import styles from '../home.module.scss';
import { ReactComponent as SearchIcon } from '../../../assets/New-images/Search.svg';
import { useSearchStore } from "../../../store/SearchStore";

type ProductSearchState = {
};

const ProductSearch: React.FC<ProductSearchState> = ({}) => {
    const { shortListedSearchProductsData, searchByProductResult, setSearchByProductResult, searchString, setSearchString, searchZipCode, setSearchZipCode, sessionId, enableRejectSearchAnalytic, setEnableRejectSearchAnalytic } = useSearchStore();
    const { productData }: any = useGlobalStore();
    const [debouncedSearchData] = useDebouncedValue(searchString, 400);
    const [placeholder, setPlaceholder] = useState('Search by Product');
    const searchProductInputRef = useRef<any>(null);

    const initialPlaceholder = 'Search by Product';
    const secondPlaceholder = 'Search Another Product';
    const focusPlaceholder = 'Example: Beam 12" x...';

    useEffect(()=>{
        if(searchByProductResult.length === 0 && searchProductInputRef.current){
            searchProductInputRef.current.focus();
        }
    },[searchByProductResult])

    const handleFocus = () => {
        setPlaceholder(focusPlaceholder);
    };

    const handleBlur = () => {
        setPlaceholder(shortListedSearchProductsData.length === 0 ? initialPlaceholder : secondPlaceholder);
    };

    useEffect(() => {
        if (debouncedSearchData?.length >= MinSearchDataLen) {
            searchAnalyticsApi(sessionId, null, debouncedSearchData)
        }
    }, [debouncedSearchData])

    useEffect(() => {
        if (sessionId) {
            if (searchString.length === 1) {
                setEnableRejectSearchAnalytic(true)
            }
            if (searchString.length === 0 && searchByProductResult.length === 0 && debouncedSearchData && enableRejectSearchAnalytic) {
                searchAnalyticsApi(sessionId, 'Reject', debouncedSearchData)
            } else if (debouncedSearchData) {
                searchAnalyticsApi(sessionId, 'Accept', debouncedSearchData)
            }
        }
    }, [searchString])

    const searchHandler = (text: string) => {
        setSearchString(text);
        handleProductSearch(text);
    }

    const handleProductSearch = (searchString: string) => {
        const searchStrings = getValidSearchData(searchString);
        const productSearch = searchProducts(productData, searchStrings, searchString, undefined, false);
        const filterProductSearch = productSearch.filter((product) => {
            if (shortListedSearchProductsData.length !== 0 && shortListedSearchProductsData.some((data) => data.id === product.Product_ID)) {
                return false;
            } else {
                return true;
            }
        })
        setSearchByProductResult(filterProductSearch);

    }

    const searchAnalyticsApi = (sessionId: string, status: string | null, searchKeyword: string) => {
        const payload = {
            "data": {
                "session_id": sessionId,
                "keyword": searchKeyword,
                "status": status,
                "source": 'search'
            }
        }
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/create_po_search', payload)
            .catch(err => console.error(err))
    }

    return (
        <>
            <div className={styles.searchSection}>
                <div className={styles.zipBox}>
                    <span className={styles.destZIP}>Dest. ZIP</span>
                    <span className={styles.zipCode}>
                        <input 
                            type="text" 
                            value={searchZipCode} 
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(/\D/g, '');
                                setSearchZipCode(e.target.value)
                            }}
                            maxLength={5}
                            className={styles.zipInput}
                        />
                    </span>
                </div>
                <div className={styles.searchBox}>
                    <SearchIcon />
                    <input placeholder={placeholder} onFocus={handleFocus} onBlur={handleBlur} value={searchString} onChange={event => { searchHandler(event.target.value) }} ref={searchProductInputRef} />
                </div>
            </div>

        </>
    )
}

export default ProductSearch;