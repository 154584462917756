// @ts-nocheck
import { Fade, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useState, useEffect, forwardRef, useImperativeHandle, ReactElement } from 'react';
import styles from './tooltip.module.scss'
import { routes } from '../../common';
import useSendForbiddenTooltips from '../../hooks/useSendForbiddenTooltips';
import { useGlobalStore } from '@bryzos/giss-ui-library';

type Props = {
    children?: React.ReactNode,
    title?: string;
    tooltiplabel?: string | ReactElement;
    placement?: string;
    classes?: any;
    localStorageKey?: string;
    className?: any;
    onClick?: () => void;
    open?: boolean;
    onCancelUpload?: () => void;
}

export const CommonTooltip: React.FC<Props> = forwardRef(({ title, tooltiplabel, placement, classes, localStorageKey, className, onClick, open, onCancelUpload }, ref) => {
    const [showTooltip, setShowTooltip] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [currentInFocus, setCurrentInFocus] = useState(false);

    const forbiddenTooltips = useGlobalStore(state => state.forbiddenTooltips);
    const setForbiddenTooltips = useGlobalStore(state => state.setForbiddenTooltips);
    const { mutate: sendForbiddenTooltips, data: sendForbiddenTooltipsData, isLoading: isSendForbiddenTooltipsLoading } = useSendForbiddenTooltips();

    useImperativeHandle(ref, () => {
        return {
            setCurrentInFocus
        };
    });

    useEffect(() => {
        const _forbiddenTooltipsData = Array.isArray(forbiddenTooltips) ? forbiddenTooltips : [];
        const _forbiddenTooltip = _forbiddenTooltipsData.find(key => key === localStorageKey);
        setShowTooltip(!_forbiddenTooltip);
        setIsChecked(false);
        setCurrentInFocus(false);
    }, [forbiddenTooltips]);

    useEffect(() => {
        if (!isSendForbiddenTooltipsLoading && sendForbiddenTooltipsData) {
            setForbiddenTooltips(Array.isArray(sendForbiddenTooltipsData) ? sendForbiddenTooltipsData : []);
        }
    }, [isSendForbiddenTooltipsLoading, sendForbiddenTooltipsData])

    const handleCheckboxChange = () => {
        const t = setTimeout(() => {
            clearTimeout(t);
            setShowTooltip(false);
            setIsChecked(true);
            const _forbiddenTooltips = [...forbiddenTooltips, localStorageKey]
            setForbiddenTooltips(_forbiddenTooltips);
            sendForbiddenTooltips(_forbiddenTooltips);
        }, 300);
    };

    const elementInFocus = (event) => {
        if (event.type === "click") {
            if (title === "Click here to minimize this window to your task bar") {
                setCurrentInFocus(false);
                return;
            }
        }
        if (event.type === "focus") {
            if (title === "Click here to minimize this window to your task bar") {
                setCurrentInFocus(false);
                return;
            }
        }
        setCurrentInFocus(true);
    }

    const elementNotInFocus = () => {
        if (!(location.pathname === routes.homePage ||
            location.pathname === routes.createPoPage ||
            location.pathname === routes.buyerSettingPage ||
            location.pathname === routes.sellerSettingPage ||
            location.pathname === routes.orderPage ||
            location.pathname === routes.loginPage ||
            location.pathname === routes.acceptOrderPage)) {
            setCurrentInFocus(false);
        }
    }

    return (
        <>
            {showTooltip && !isChecked && location.pathname !== routes.loginPage && location.pathname !== routes.onboardingDetails
                && location.pathname !== routes.onboardingTnc
                && location.pathname !== routes.onboardingThankYou
                && location.pathname !== routes.TnCPage && 
                location.pathname !== routes.forgotPassword ? (
                <Tooltip
                    className={className}
                    onClick={onClick}
                    title={open !== false && currentInFocus &&
                        <div onMouseLeave={(e) => e.preventDefault()} onClick={(e) => e.stopPropagation()}>
                            <div>{title}</div>
                            <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                <input type='checkbox'
                                    onChange={handleCheckboxChange} />
                                <span className={clsx(styles.checkmark, 'checkmark')} />
                                Don’t show me again
                            </label>
                        </div>
                    }
                    arrow
                    placement={placement}
                    interactive="true"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 200 }}
                    classes={classes}
                >
                    <span onFocus={elementInFocus} onBlur={elementNotInFocus} onClick={elementInFocus} onMouseOver={elementInFocus} onMouseLeave={elementNotInFocus}>{tooltiplabel}</span>
                </Tooltip>

            ) : (<span onFocus={elementNotInFocus} onBlur={elementNotInFocus} onClick={elementNotInFocus} onMouseOver={elementNotInFocus} onMouseLeave={elementNotInFocus}>{tooltiplabel}</span>)}
        </>

    );
});
