import { Autocomplete, Box, ClickAwayListener, Dialog, Fade, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import styles from './createPo.module.scss';
import { ReactComponent as QuestionIcon } from '../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { ReactComponent as QuestionBlackIcon } from '../../assets/images/setting-black-question.svg';
import { ReactComponent as QuestionBlackHoverIcon } from '../../assets/images/question-black-hover.svg';
import { ReactComponent as AddLineIcon } from '../../assets/images/Add-line.svg';
import { ReactComponent as AddLineHoverIcon } from '../../assets/images/Add-line-hover.svg';
import { ReactComponent as RefreshNet30Icon } from '../../assets/images/net30refresh.svg';
import { ReactComponent as DropdownIcon } from '../../assets/New-images/StateIconDropDpown.svg';

import { DeliveryDateTooltip, DeliveryToTooltip, JobPOTooltip, SalesTaxTooltip, chooseYourUnitTooltip } from '../../tooltip';
import clsx from 'clsx';
import { Controller, useFieldArray } from 'react-hook-form';
import { CustomMenu } from './CustomMenu';
import { useDebouncedValue } from '@mantine/hooks';
import dayjs from 'dayjs';
import useGetUserPartData from '../../hooks/useGetUserPartData';
import { useQueryClient } from '@tanstack/react-query';
import CreatePoTile from './CreatePoTile/CreatePoTile';
import { ReactComponent as WarningIcon } from '../../assets/images/warning-seller-icon.svg';
import { v4 as uuidv4 } from 'uuid';
import { CommonTooltip } from '../../component/Tooltip/tooltip';
import PdfMakePage from '../PdfMake/pdfMake';
import { InstantPurchasingWrapper,  useBuyerCheckOut, useGlobalStore, useSaveProductSearchAnaytic, useSaveUserActivity, userRole, reactQueryKeys, formatToTwoDecimalPlaces, getChannelWindow, format4DigitAmount, useGetDeliveryDate, mobileDiaglogConst, dateTimeFormat, getFloatRemainder, getValUsingUnitKey, getUnitPostScript, useBuyerSettingStore} from '@bryzos/giss-ui-library';
import { calculateBuyerTotalOrderWeight, descriptionLines, getOtherDescriptionLines } from '../../utility/pdfUtils';
import SearchHeader from '../SearchHeader';
import InputWrapper from 'src/renderer2/component/InputWrapper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomTextField from 'src/renderer2/component/CustomTextField';
import { ReactComponent as UploadBOMIcon } from '../../assets/New-images/Upload-BOM.svg';
import { ReactComponent as UploadBOMIconHover } from '../../assets/New-images/Bom-Upload-Hover.svg';
import { ReactComponent as ChooseOneIcon } from '../../assets/New-images/Choose-One.svg';
import OrderSummary from 'src/renderer2/component/OrderSummary/OrderSummary';
import { useRightWindowStore } from '../RightWindow/RightWindowStore';
import StatesList from 'src/renderer2/component/StatesList/StatesList';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import StateDropDown from 'src/renderer2/component/StateDropDown/StateDropDown';
import CreatePoRightWindow from 'src/renderer2/component/CreatePoRightWindow/CreatePoRightWindow';
import Calendar from 'src/renderer2/component/Calendar/Calendar';
import { routes } from 'src/renderer2/common';
import PseudoScroll from 'src/renderer2/component/PseudoScroller/PseudoScroll';

let cartItem = {
    descriptionObj: '',
    description: '',
    qty: '',
    qty_unit: '',
    price_unit: '',
    product_tag: '',
    domestic_material_only: false,
    qtyUnitM:[],
    priceUnitM:[],
    line_session_id:'',
}

const InstantPurchasing = (
    {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        errors,
        isValid,
        handleStateZipValidation,
        quantitySizeValidator,
        updateLineItem,
        pricePerUnitChangeHandler,
        calculateMaterialTotalPrice,
        buyingPreference,
        setBuyingPreference,
        calculateTotalPricing,
        handleSalesTax,
        paymentMethodChangeHandler,
        saveCreatePOUserActivity,
        saveUserLineActivity,
        setDefaultPaymentOption,
        paymentMethods,
        clearErrors,
        navigateToSettings
    }
) => {
    const navigate = useNavigate();
    
    const {setCreatePoData, createPoData, setShowLoader, backNavigation, setCreatePoSessionId, resetHeaderConfig, userData, setBackNavigation, bryzosPayApprovalStatus, setBryzosPayApprovalStatus, referenceData, productData} = useGlobalStore();
    const channelWindow =  getChannelWindow();
    const [products, setProducts] = useState([]);
    const [states, setStates] = useState([]);
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [showLine1Tooltip, setShowLine1Tooltip] = useState(true);
    const [showCityTooltip, setShowCityTooltip] = useState(true);
    const [showPaymentOptionTooltip, setShowPaymentOptionTooltip] = useState(undefined);
    
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sendInvoicesToEmailData, SetSendInvoicesToEmailData] = useState('');
    const [apiCallInProgress, setApiCallInProgress] = useState(false);

    const queryClient = useQueryClient();
    const { data: userPartData, isLoading: isUserPartDataLoading } = useGetUserPartData();
    const logUserActivity = useSaveUserActivity();

    const { fields, append, remove, } = useFieldArray({
        control,
        name: "cart_items"
    });

    const [debouncedMatTotal] = useDebouncedValue(watch('price'), 400);
    const [handleSubmitValidation, setHandleSubmitValidation] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [lineSessionId, setLineSessionId] = useState('');
    const [disableCloseAnalytic, setDisableCloseAnalytic] = useState(true);
    const [deliveryStateFocusEnable, setDeliveryStateFocusEnable] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchStringData, setSearchString] = useState('');
    const [todayDate, setTodayDate] = useState('');
    const [disableDeliveryDate, setDisableDeliveryDate] = useState(false);
    const [deliveryDateMap, setDeliveryDateMap] = useState({});
    const [deboucedSearchString, cancelDebouncedSearchString] = useDebouncedValue(searchStringData, 400);
    const analyticRef = useRef();
    analyticRef.current = disableCloseAnalytic && !resetHeaderConfig;
    // const getBuyingPreference = ueGetBuyingPreference();
    const { buyerSetting } = useBuyerSettingStore();
    const saveBuyerCheckout = useBuyerCheckOut();
    const saveProductSearch = useSaveProductSearchAnaytic();
    const getDeliveryDate = useGetDeliveryDate();
    const [openDeliveryToDialog, setOpenDeliveryToDialog] = useState(false);
    const [selected, setSelected] = useState("null");
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef(null);
    const { setLoadComponent , setShowVideo, setProps} = useRightWindowStore();
    const [stateInputFocus, setStateInputFocus] = useState(false);
    const [stateDropDownValue, setStateDropDownValue] = useState<any>('');
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const createPoContainerRef = useRef(null);
    const headerContainerRef = useRef<HTMLDivElement>(null);
    const [isNearBottom, setIsNearBottom] = useState(false);
    const [isCartValid, setIsCartValid] = useState(true);
    const [isAllCartDataLoaded, setIsAllCartDataLoaded] = useState(false);
    const [pricingBrackets, setPricingBrackets] = useState([]);
    const [disableBidBuyNow, setDisableBidBuyNow] = useState(false);
    const addPoLineTableRef = useRef(null);
    const[hidePoLineScroll, setHidePoLineScroll] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [maxScrollHeight, setMaxScrollHeight] = useState(800);
    const formInputGroupRef = useRef(null);
    const [isDraggingScrollState, setIsDraggingScrollState] = useState(false);

    // Track the cart items length separately
    const cartItemsLength = watch('cart_items')?.length || 0;

    const orderInfoIsFilled = useMemo(()=>{
        return !!watch('shipping_details.line1') && 
               !!watch('shipping_details.city') && 
               !!watch('shipping_details.state_id') && 
               !!watch('shipping_details.zip') &&
               !!watch('internal_po_number') &&
               !!watch('order_type') &&
               !!watch('delivery_date') &&
               !errors?.shipping_details?.zip?.message
    },[
        watch('shipping_details.line1'), 
        watch('shipping_details.city'), 
        watch('shipping_details.state_id'), 
        watch('shipping_details.zip'),
        watch('internal_po_number'),
        watch('order_type'), 
        watch('delivery_date'),
        errors?.shipping_details?.zip?.message
    ]);

    // Use the tracked length in useEffect
    useEffect(() => {
        const formInputGroup = formInputGroupRef.current;
        const addLineContainer = addPoLineTableRef.current;
        if(formInputGroup && addLineContainer){
            setMaxScrollHeight(formInputGroup.scrollHeight + addLineContainer.scrollHeight);
        }
    }, [cartItemsLength]); // Now depends on the tracked length

    // Disable place order button if any validation fails
    const disablePlaceOrderButton = !orderInfoIsFilled || 
        Object.keys(errors).length > 0 || 
        !handleSubmitValidation || 
        watch('shipping_details.validating_state_id_zip') || 
        !watch('payment_method') ||
        watch('totalWeight') < pricingBrackets?.[0]?.min_weight ||
        (getValues('payment_method') === 'bryzos_pay' && (
            buyingPreference?.bnpl_settings?.is_approved === null ||
            watch('totalPurchase') > (watch('availableCreditLimit') || 0)
        )) ||
        !isCartValid;

    // Function to handle click outside the div
    useEffect(() => {
        
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const orderSummaryProps = {
            watch,
            paymentMethods,
            control,
            setValue,
            getValues,
            navigateToSettings,
            saveUserActivity,
            disablePlaceOrderButton,
            handleSubmit,
            onSubmit,
            pricingBrackets
        };
        setProps(orderSummaryProps);

    }, [
        watch('price'),
        watch('sales_tax'),
        watch('depositAmount'), 
        watch('subscriptionAmount'),
        watch('totalPurchase'),
        watch('availableCreditLimit'),
        watch('payment_method'),
        watch('requestedCreditLimit'),
        disablePlaceOrderButton 
    ]);
    
    useEffect(()=>{
        setLoadComponent(<CreatePoRightWindow />);
    },[])

    useEffect(() => { 
        setShowVideo(true);
        const cart_items = Array(3).fill({...cartItem});
        setValue('cart_items', cart_items);
        if (productData && referenceData) {
            setProducts(productData)
            setStates(referenceData?.ref_states);
            setPricingBrackets(referenceData?.ref_weight_price_brackets);
            initializeCreatePOData();
        }
        const sessionId = uuidv4();
        setSessionId(sessionId);
        setCreatePoSessionId(sessionId)
        const payload = {
            "data": {
                "session_id": sessionId
            }
        }
        logUserActivity.mutateAsync({url:import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close' ,payload}).catch(err => console.error(err));
        return(() => {

            setLoadComponent(null)
            setBackNavigation(-1)
            cancelDebouncedSearchString()
            if(analyticRef.current  && sessionId){
                const payload = {
                    "data": {
                        "session_id": sessionId,
                        "close_status": "CANCEL"
                    }
                }
                logUserActivity.mutateAsync({url:import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close' ,payload})
                .then(() => setCreatePoSessionId(null))
                .catch(err => console.error(err))
            }
        })
    }, []);

    useEffect(() => {
        setProducts(productData);
    }, [productData]);
    
    useEffect(() => {
        if (isAllCartDataLoaded) {
            for(let i = 0; i < 17; i++){
                append({...cartItem});
            }
        }
    }, [isAllCartDataLoaded])

    useEffect(()=>{
        setHandleSubmitValidation(false);
        paymentMethodChangeHandler().then(()=>{
            calculateTotalPrice();
            setHandleSubmitValidation(true);
        });
    },[watch('payment_method')]);

    useEffect(() => {
        if(getValues('price') && getValues('shipping_details.zip') && getValues('shipping_details.state_id')){
            setHandleSubmitValidation(false);
            calculateTotalPricing(true).then(()=>{
                setHandleSubmitValidation(true);
            });
        }
        else{
            setValue("sales_tax", parseFloat(0));
            setValue("depositAmount", parseFloat(0));
        }
    }, [debouncedMatTotal])

    useEffect(() => {
        onStateZipChange()
    }, [watch('shipping_details.zip'), watch('shipping_details.state_id')])

    const onStateZipChange = async()=>{
        const isValid:Boolean = await handleStateZipValidation();
        if(isValid)
        handleSalesTax();
    }

    useEffect(() => {
        calculateTotalPrice();
        setValue('totalWeight', calculateBuyerTotalOrderWeight(watch('cart_items')));
    }, [watch('price'), watch('sales_tax'), watch('depositAmount')]);

    useEffect(() => {
        const handleScrollOutsideDiv = (event) => {
            setIsCalendarOpen(false)
        };
        createPoContainerRef?.current?.addEventListener('scroll', handleScrollOutsideDiv);
        return () => {
            createPoContainerRef?.current?.removeEventListener('scroll', handleScrollOutsideDiv);
        };
    }, []);
    const initializeCreatePOData = async ()=>{
        try{  
            const deliveryDateList = await getDeliveryDateData();
            // const  res = await getBuyingPreference.mutateAsync()
            const buyingPreference = buyerSetting;
            buyingPreference.userType = userRole.buyerUser;
            buyingPreference.stateRef = referenceData?.ref_states;
            setBuyingPreference(buyingPreference);
            const deliveryReceivingHours = buyingPreference.user_delivery_receiving_availability_details;
            if(deliveryReceivingHours?.length !== 0){
                setValue('recevingHours', deliveryReceivingHours)
            }
            const deliveryDaysAddValue = buyingPreference.delivery_days_add_value ?? referenceData?.ref_delivery_date[0]?.days_to_add;
            setValue('delivery_date_offset', deliveryDaysAddValue);
            let disableDeliveryDates = true;
            deliveryDateList.forEach(deliveryDateObj => {
                // if(deliveryDateObj.days_to_add === deliveryDaysAddValue){
                //     const deliveryDate = !deliveryDateObj.disabled ? deliveryDateObj.value : null;
                //     setValue('delivery_date', deliveryDate);
                // }
                if(!deliveryDateObj.disabled && disableDeliveryDates){
                    disableDeliveryDates = false;
                }
            });
            setDisableDeliveryDate(disableDeliveryDates)
            if (createPoData) {
                setValue('internal_po_number', createPoData.internal_po_number);
                setValue('shipping_details', createPoData.shipping_details);
                setValue('delivery_date', createPoData.delivery_date);
                setValue('cart_items', createPoData.cart_items);
                createPoData.cart_items.forEach((product: any, index: number) => {
                    if(product?.descriptionObj  && Object.keys(product.descriptionObj).length > 0){
                         resetQtyAndPricePerUnitFields(index,product.descriptionObj)
                         setDisableBidBuyNow(true)
                    };
                    updateValue(index)
                });
                setValue('payment_method', createPoData.payment_method);
                setValue('seller_price', createPoData.seller_price);
                setValue('price', createPoData.price);
                setValue('sales_tax', createPoData.sales_tax);
                setStateDropDownValue(referenceData?.ref_states?.find(state => state.id === createPoData.shipping_details.state_id)?.code || '');
                setValue('order_type', createPoData.order_type);
                setValue('delivery_date_offset',createPoData?.delivery_date_offset)
            }else{
                // setValue('shipping_details.line1', buyingPreference.delivery_address_line1)
                // setValue('shipping_details.city', buyingPreference.delivery_address_city)
                // setValue('shipping_details.state_id', buyingPreference.delivery_address_state_id)
                // setStateDropDownValue(referenceData?.ref_states?.find(state => state.id === buyingPreference?.delivery_address_state_id)?.code || '');
                // setValue('shipping_details.zip', buyingPreference.delivery_address_zip)
                SetSendInvoicesToEmailData(buyingPreference.send_invoices_to);
                if (buyingPreference.default_payment_method === 'ACH_CREDIT') {
                    setValue('payment_method', 'ach_credit')
                    setValue('depositAmount',0);
                } else if (buyingPreference.default_payment_method === 'BUY_NOW_PAY_LATER') {
                    setValue('payment_method', 'bryzos_pay')
                }
                setValue('sales_tax', 0);
            }
            if(buyingPreference?.bnpl_settings?.is_approved !== null){
                setValue('availableCreditLimit', buyingPreference?.bnpl_settings?.bryzos_available_credit_limit)
            }else{
                setValue('requestedCreditLimit', buyingPreference?.bnpl_settings?.requested_credit_limit)
            }
            setDefaultPaymentOption(buyingPreference, paymentMethods, false);
            setTodayDate(new Date());
            setShowLoader(false);
            setIsAllCartDataLoaded(true);
            
        }catch(err) {
            setOpenErrorDialog(true);
            setErrorMessage("Something went wrong. Please try again in sometime");
            setShowLoader(false);
            console.error(err)

        }
    }
    const calculateTotalPrice = ()=>{
        const materialTotal = +(watch('price') ?? 0);
        let totalPurchaseOrderPrice: number = 0;
        if (materialTotal) {
            totalPurchaseOrderPrice += materialTotal + (+(watch('sales_tax') ? watch('sales_tax') : 0)) + (+(watch('depositAmount') ?? 0))
        }
        setValue('totalPurchase',parseFloat(totalPurchaseOrderPrice));
    }

    useEffect(() => {
        if(deboucedSearchString){
            handleCreatePOSearch(searchStringData, null, lineSessionId)
        }
    }, [deboucedSearchString])

    const updateLineProduct = (index, product) => {
        setSelectedProduct(product);
        setValue(`cart_items.${index}.qty`, null)
        setValue(`cart_items.${index}.extended`, 0)
        setValue(`cart_items.${index}.seller_extended`, 0)
        setValue(`cart_items.${index}.price`, 0)
        setValue(`cart_items.${index}.seller_price`, 0);
        setValue(`cart_items.${index}.price_unit`, '')
        setValue(`cart_items.${index}.qty_unit`, '')
        clearErrors(`cart_items.${index}.qty`)
        if(product){
            // resetQtyAndPricePerUnitFields(index, product);
            if (userPartData && Object.keys(userPartData)?.length) {
                setValue(`cart_items.${index}.product_tag`, userPartData[product?.Product_ID])
            }
        }
        else{
            calculateMaterialTotalPrice();
        }
    }

    const resetQtyAndPricePerUnitFields = (index, product) => {
        const qtyUnitMData = product.QUM_Dropdown_Options.split(",")
        setValue(`cart_items.${index}.qty_um`,qtyUnitMData);
        if (!createPoData?.cart_items?.[index]?.qty_unit) {
            setValue(`cart_items.${index}.qty_unit`, qtyUnitMData[0])
        }
        resetPricePerUnitFields(index, product);
    }

    const resetPricePerUnitFields = (index, product) => {
        const priceUnitMData = product.PUM_Dropdown_Options.split(",")
        setValue(`cart_items.${index}.price_um`,priceUnitMData);
        if (!createPoData?.cart_items?.[index]?.price_unit) {
            setValue(`cart_items.${index}.price_unit`, priceUnitMData[0])
        }
        pricePerUnitChangeHandler(index, product);
    }

    const removeLineItem = (index)=>{
        remove(index);
        calculateMaterialTotalPrice();
    }

    const updateValue = (index: number)=>{
        const _selected = getValues(`cart_items.${index}`).descriptionObj;
        if(_selected && Object.keys(_selected).length > 0){
            updateLineItem(index);
            const qtyVal = getValues(`cart_items.${index}.qty`);
            const qtyUnit = getValues(`cart_items.${index}.qty_unit`);
            const priceUnit = getValues(`cart_items.${index}.price_unit`) ;
            if(!qtyUnit) {
              setValue(`cart_items.${index}.qty_um`, _selected.QUM_Dropdown_Options.split(","));
              setValue(`cart_items.${index}.qty_unit`, _selected.QUM_Dropdown_Options.split(",")[0]);
            }
            const unit = qtyUnit || (_selected.QUM_Dropdown_Options ? _selected.QUM_Dropdown_Options.split(",")[0] : '');
            const orderIncrement = getValUsingUnitKey(_selected, unit, 'Order_Increment');
            if (qtyVal > 0 && getFloatRemainder(qtyVal, orderIncrement) === 0) {
                if(!priceUnit) resetPricePerUnitFields(index, _selected)
            }else{
                if(qtyVal < 1) clearErrors(`cart_items.${index}.qty`);
                setValue(`cart_items.${index}.price_unit`, '');
                setValue(`cart_items.${index}.price`, 0);
                setValue(`cart_items.${index}.seller_price`, 0);
            }
            calculateMaterialTotalPrice();
        }
    }
    const onSubmit = (data) => {
        const date = new Date()
        if(dayjs(date).format('M/D/YYYY') === dayjs(todayDate).format('M/D/YYYY')){
            setShowLoader(true);
            const totalPurchaseValue = getValues('price');
            const totalSellerPurchaseValue = parseFloat(getValues('seller_price')).toFixed(2);
            const localDateTime = dayjs().format(dateTimeFormat.isoDateTimeWithTFormat);
            const payload = {
                "data": {
                    "internal_po_number": data.internal_po_number,
                    "shipping_details": data.shipping_details,
                    "delivery_date": data.delivery_date,
                    "payment_method": getValues('payment_method'),
                    "seller_price": totalSellerPurchaseValue, 
                    "price": String(totalPurchaseValue),
                    "sales_tax": data.sales_tax,
                    "freight_term": "Delivered",
                    "cart_items": formatCartItems(getValues('cart_items')),
                    "checkout_local_timestamp": localDateTime,
                    "delivery_date_offset": data.delivery_date_offset
                }
            };
            setDisableCloseAnalytic(false)
            saveBuyerCheckout.mutateAsync(payload)
                .then(res => {
                    if (res.data.data.error_message) {
                        setOpenErrorDialog(true);
                        setErrorMessage(res.data.data.error_message);
                        setShowLoader(false);
                        saveUserActivity(null, res.data.data.error_message)
                        setDisableCloseAnalytic(true)
                    } else {
                        queryClient.invalidateQueries([reactQueryKeys.getUserPartData]);
                        const payload = {
                            "data": {
                                "session_id": sessionId,
                                "close_status": "ACCEPT"
                            }
                        }
                        logUserActivity.mutateAsync({url:import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close' ,payload})
                        .catch(err => console.error(err))
                        navigate(routes.orderConfirmationPage, { state: { poNumber: res.data.data, jobNumber: data.internal_po_number, sendInvoicesTo: sendInvoicesToEmailData, selectedOptionPayment: data.payment_method, } })
                        setCreatePoData(null);
                        saveUserActivity(res.data.data, null);
                    }
                })
                .catch(err => {
                    saveUserActivity(null, (err?.message ?? err));
                    setDisableCloseAnalytic(true)
                    setOpenErrorDialog(true);
                        setErrorMessage("Something went wrong. Please try again in sometime");
                        setShowLoader(false);
                    console.error(err)
                })
        }else{
            setOpenErrorDialog(true);
            setErrorMessage('Selected delivery date is incorrect, please select correct delivery date.');
            setValue('delivery_date', null)
            getDeliveryDateData();
            setTodayDate(new Date());
        }

        
    }

    const addNewRow = () => {
        append({...cartItem});
        // const t = setTimeout(() => {
        //     clearTimeout(t);
        //     const element = document.getElementById(`combo-box-demo${fields.length}`);
        //     if (element) {
        //         element.focus()
        //     }
        // }, (100));
    }

    const formatCartItems = (products) => {
        return products.map((item, index) => {
            if (item.descriptionObj && Object.keys(item.descriptionObj).length > 0) {
                const cartItem = {
                    "line_id": index + 1,
                    "description": item.descriptionObj.UI_Description,
                    "qty": item.qty,
                    "qty_unit": item.qty_unit,
                    "product_tag": item.product_tag,
                    "product_tag_mapping_id": item.descriptionObj.product_tag,
                    "price": item.price,
                    "price_unit": item.price_unit,
                    "extended": item.extended,
                    "product_id": item.descriptionObj.Product_ID,
                    "reference_product_id": item.descriptionObj.id,
                    "shape": item.descriptionObj.Key2,
                    "seller_price": item.seller_price,
                    "seller_extended": item.seller_extended.toFixed(2),
                    "buyer_pricing_lb": item.descriptionObj.Buyer_Pricing_LB.replace("$", ""),
                    "domestic_material_only": item.domesticMaterialOnly ?? false,
                    "buyer_calculation_price": item.buyerPricePerUnit,
                    "seller_calculation_price": item.sellerPricePerUnit,
                };
                return cartItem
            }
            return null
        }).filter(Boolean);
    }

    const handleGetDeliveryDate = (delivery_date,recevingHours) => {
        const date = dayjs(delivery_date)
        const convertDate = date.format('dddd')
        let checkDeliveryDate = null;
        recevingHours?.forEach((recevingHour)=>{
            if(recevingHour.is_user_available === 1 && convertDate.toLowerCase() === recevingHour.day.toLowerCase()){
                checkDeliveryDate = delivery_date
            }
        })
        return checkDeliveryDate;
    }


    const saveUserActivity = (checkOutPoNumber, checkOutError) => {
        saveCreatePOUserActivity(sessionId, checkOutPoNumber, checkOutError);
    }

    const handleCreatePOSearch = (searchStringKeyword, status, descriptionLineSessionId) => {
        const payload = {
            "data": {
                "session_id": sessionId,
                "keyword" : searchStringKeyword,
                "status" : status,
                "source" : 'create-po',
                "line_session_id": descriptionLineSessionId,
            }
        }
        setSelectedProduct(null)
        saveProductSearch.mutateAsync(payload)
        .catch(err => console.error(err))
    }

    const calculateLineWeight = (data) => {
        let lineWeight = 0;
        if(data?.descriptionObj?.buyerPricing_LB){
            const updatedUnit = getUnitPostScript(data.qty_unit);
            const actualBuyingPricePerUnit = parseFloat(data.descriptionObj[`buyerPricing_${updatedUnit}`].replace(/[\$,]/g, ''));
            const actualBuyerExtended = parseFloat(actualBuyingPricePerUnit * data.qty);
            lineWeight = actualBuyerExtended / data.descriptionObj.buyerPricing_LB.replace(/[\$,]/g, "");
        }else{
            lineWeight = data.extendedValue / data?.descriptionObj?.Buyer_Pricing_LB.replace(/[\$,]/g, "");
        }
        return formatToTwoDecimalPlaces(lineWeight);
    }

    const getExportPoData = ()=>{
        return { ...getValues(), selectedOptionPayment: getValues('payment_method') };
    }

    // Validates that cart items have required fields if they have a description
    const validateCart = () => {
        const cartItems = getValues('cart_items');
        if (!cartItems?.length) return true;

        // Only validate items that have a description
        const itemsWithDescription = cartItems.filter(item => item?.descriptionObj?.UI_Description);
        if (!itemsWithDescription.length) return false;

        // Check required fields are present and valid
        return itemsWithDescription.every(item => {
            const quantity = Number(item.qty);
            return quantity > 0 && 
                   Boolean(item.qty_unit) &&
                   Boolean(item.price_unit);
        });
    };

    // Monitor cart changes and update validation state
    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name?.startsWith('cart_items')) {
                setIsCartValid(validateCart());
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const getCartItems = () =>{
        const {cart_items} = getValues();
        const formattedItems = cart_items.filter(item => item?.descriptionObj?.UI_Description).map((item, index) => ({
            description: descriptionLines(item.descriptionObj.UI_Description), 
            otherDescription: getOtherDescriptionLines(item.descriptionObj.UI_Description), 
            product_tag: item.product_tag,
            domesticMaterialOnly: item.domesticMaterialOnly ? '\nDomestic (USA) Material Only' : '',
            qty: formatToTwoDecimalPlaces(item.qty),
            qty_unit: item.qty_unit,
            price_unit: item.price_unit,
            extended: formatToTwoDecimalPlaces(item.extended),
            price: item.price_unit === 'Lb' ? format4DigitAmount(item.price) : formatToTwoDecimalPlaces(item.price),
            line_weight: calculateLineWeight(item),
            line_weight_unit: "Lb", 
            line_no: index,
            po_line: index.toString(),
            descriptionObj:item.descriptionObj,
            extendedValue:item.extendedValue
        }));
        return formattedItems
    }

    const getDeliveryDateData = async () => {
        let deliveryDateList = []; 
        const res = await getDeliveryDate.mutateAsync();
        if(res?.data?.data){
            deliveryDateList = res.data.data
        }
        const optionMap = deliveryDateList.reduce((acc, option) => {
            acc[option.value] = option;
            return acc;
        }, {});
        setDeliveryDateMap(optionMap)
        setDeliveryDates(deliveryDateList);
        return deliveryDateList
    }

    const handleDeliveryToClick = () => {
        setOpenDeliveryToDialog(true);
        setIsFocused(true);
    }

    const handleDeliveryInfoContainerClickAway = () => {
        if (errors?.shipping_details?.zip?.message || errors?.shipping_details?.state_id?.message || errors?.shipping_details?.city?.message || errors?.shipping_details?.line1?.message || errors?.shipping_details?.line2?.message) {
            setOpenDeliveryToDialog(true)
            setIsFocused(true)
        } else {
            setStateInputFocus(false)
            setOpenDeliveryToDialog(false)
            setIsFocused(false)
        }
    }

    const CustomCalendarHeader = (props) => {
        const { 
          currentMonth, 
          onMonthChange,
          reduceAnimations,
          views,
          view,
          onViewChange,
          ...other 
        } = props;
        
        const monthName = dayjs(currentMonth).format('MMMM');
        const isCurrentMonth = dayjs(currentMonth).isSame(dayjs(), 'month');
        const isNextMonth = dayjs(currentMonth).isSame(dayjs().add(1, 'month'), 'month');
        const handlePreviousMonthClick = () => {
          onMonthChange(dayjs(currentMonth).subtract(1, 'month'), 'left');
        };
        
        const handleNextMonthClick = () => {
          onMonthChange(dayjs(currentMonth).add(1, 'month'), 'right');
        };
        
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding={2}
            className={styles.customCalendarHeaderBox}
          >
            <IconButton onClick={handlePreviousMonthClick} className={styles.customCalendarHeaderIcon}  disabled={isCurrentMonth}>
              <ArrowLeftIcon sx={{color: isCurrentMonth ? '#a0a0a0' : '#dfe2f0'}}/>
            </IconButton>
            
            <Typography variant="h6" className={styles.customCalendarHeaderText}>
              {monthName.toLocaleUpperCase()}
            </Typography>
            
            <IconButton onClick={handleNextMonthClick} className={styles.customCalendarHeaderIcon} disabled={isNextMonth}>
              <ArrowRightIcon  sx={{color: isNextMonth ? '#a0a0a0' : '#dfe2f0'}}/>
            </IconButton>
          </Box>
        );
      };

    const scrollToTop = () => {
        setScrollPosition(0);
        const container = createPoContainerRef.current;
        if (container) {
            container.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };  

    const handleOpenCalendarBtn = () => {
        if (!disableDeliveryDate) {
            setIsCalendarOpen(true);
        } else {
            setOpenErrorDialog(true)
            setErrorMessage(mobileDiaglogConst.receivingHrsEmpty)
            setIsCalendarOpen(false)
            const nextElement = document.querySelector('[tabindex="15"]');
            if (nextElement instanceof HTMLElement) {
                nextElement.focus();
            }
        }
    }

    const allowedDates = useMemo(() => {
        return deliveryDates
            .filter(date => !date?.disabled)
            .map(date => new Date(date?.value));
    }, [deliveryDates]);

    const handleDateSelect = (date:any) => {
       const selectedDate = dayjs(date).format(dateTimeFormat.dateSeparateWithSlashAndDaySingleDigit);
       setValue('delivery_date_offset', deliveryDateMap?.[selectedDate]?.days_to_add);
    }

    const handleMainScroll = () => {
        // Skip if the scroll is from dragging the thumb
        // if (isDraggingScrollState) return;
        
        if (!createPoContainerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = createPoContainerRef.current;
        const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 5; // 5px threshold
        if(!isDraggingScrollState)setScrollPosition(scrollTop);
        const header = headerContainerRef.current;
        
        // Handle header opacity
        if (header) {
            let opacity = 0;
            if(scrollTop > 52){
                opacity = Math.min(scrollTop / 152, 1);
            }
            (header as HTMLElement).style.opacity = opacity.toString();
        }
        // Enable/disable the table scroll based on main scroll position
        if (addPoLineTableRef.current) {
                setHidePoLineScroll(!isAtBottom);
        }
    }

    const handleLineItemScroll = () => {
        // Skip if the scroll is from dragging the thumb
        // if (isDraggingScrollState) return;
        
        if(!addPoLineTableRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = addPoLineTableRef.current;
        if(!isDraggingScrollState)setScrollPosition(244 + scrollTop);
        const scrollThreshold = 150; // pixels from 
        const isAtBottom = 
            scrollHeight - (scrollTop + clientHeight) 
            <= scrollThreshold;
        if (isAtBottom ) {
            // Add 5 new rows
            for (let i = 0; i < 5; i++) {
                append({...cartItem});
            }
        }
    }

    const openAddLineTab = () => {
        if(!addPoLineTableRef.current) return;
        const container = addPoLineTableRef.current;
        if(container.scrollTop > 0 && orderInfoIsFilled && createPoContainerRef.current){
            createPoContainerRef.current.scrollTo({
                top: createPoContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
            container.style.overflowY = 'auto';
            setTimeout(()=>{
                setScrollPosition(container.scrollTop + 244);
            },300)
        }
    }

    const handleScrollChange = (newScrollPosition: number) => {
        if(newScrollPosition <= 244){
            if(!createPoContainerRef.current)return;
            createPoContainerRef.current.scrollTop = newScrollPosition;
        }
        else{
            if(!addPoLineTableRef.current || !orderInfoIsFilled)return;
            addPoLineTableRef.current.scrollTop = newScrollPosition - 244;
        }
        setScrollPosition(newScrollPosition);

    }

    // Use useCallback to prevent recreation of this function on every render
    const setIsDraggingScroll = useCallback((isDragging: boolean) => {
      setIsDraggingScrollState(isDragging);
    }, []);

    return (
        <div>
            <div className={clsx(styles.createPoContent, 'bgBlurContent')}>
                <div>
                    <SearchHeader />
                </div>
                <div className={styles.formInnerContent}>
                            {/* <div className={styles.headerNoteCreatePO}>
                                <span className={styles.leftIcon}><WarningIcon /></span>

                                <span className={styles.headerNoteText}>All delivered material will be new (aka "prime"), fulfilled to the defined specification,
                                    loaded/packaged for forklift and/or magnetic offloading and have mill test reports.</span>
                                <span className={clsx(styles.headerNoteText, styles.marginTop8)}>The maximum bundle weight is 5,000 pounds.</span>
                                <span className={styles.rightIcon}><WarningIcon /></span>
                            </div> */}
                            <div className={clsx(styles.tblWrapper,'w100')}>
                                <PseudoScroll 
                                    width="100%" 
                                    height="100%"
                                    scrollPosition={scrollPosition}
                                    maxScrollHeight={maxScrollHeight}
                                    onScrollChange={handleScrollChange}
                                    thumbColor={'#9da2b2'}
                                    thumbHeight={104}
                                    thumbWidth={8}
                                    setIsDraggingScroll={setIsDraggingScroll}
                                    thumbMarginTop={20}
                                    thumbMarginBottom={32}
                                >
                                    <>
                                        <div className={clsx(styles.headerContainer)} ref={headerContainerRef} onClick={scrollToTop} >
                                            <div className={styles.headerItem}>
                                                {watch('internal_po_number')?.toUpperCase() || '-'}
                                            </div>
                                            <div className={styles.headerItem}>
                                                {watch('delivery_date') ? 
                                                    `${dayjs(watch('delivery_date')).format('ddd').toUpperCase()}, ${watch('delivery_date')}` 
                                                    : '-'
                                                }
                                            </div>
                                            <div className={styles.headerItem}>
                                                {watch('order_type')?.toUpperCase() || '-'} 
                                            </div>
                                            <div className={styles.headerItem}>
                                                {(watch('shipping_details.line1')?.toUpperCase() || '') + 
                                                    (watch('shipping_details.line2')?.toUpperCase() || '') || '-'}
                                            </div>
                                        </div>
                                        <div className={styles.createPOContainer} ref={createPoContainerRef} onScroll={handleMainScroll}>
                                            <div className={clsx(styles.formInputGroup, isCalendarOpen && styles.isCalendarOpenDiabledInput )} ref={formInputGroupRef}>
                                            {isCalendarOpen && <div className={ styles.calendarOpenOverlay}></div>}

                                                <div className={styles.formInputGroup1}>
                                                    <InputWrapper>
                                                        <CustomTextField className={clsx(styles.inputfiled,styles.pOInput)} type='text' register={register("internal_po_number")}
                                                            placeholder='JOB / PO#'
                                                            onBlur={(e) => {
                                                                e.target.value = e.target.value.trim();
                                                                register("internal_po_number").onBlur(e);
                                                                saveUserActivity();
                                                            }}
                                                            maxLength={20}
                                                            tabIndex={13}
                                                            autoFocus
                                                        />
                                                    </InputWrapper>
                                                    <div className={styles.deliverByContainer}>
                                                        <Calendar allowedDates={allowedDates} 
                                                        value={watch('delivery_date')} 
                                                        setValue={setValue} 
                                                        isCalendarOpen={isCalendarOpen} 
                                                        setIsCalendarOpen={setIsCalendarOpen} 
                                                        disableDeliveryDate={disableDeliveryDate} 
                                                        handleOpenCalendar={handleOpenCalendarBtn}
                                                        saveUserActivity={saveUserActivity}
                                                        onDateSelect={handleDateSelect}
                                                        />
                                                    </div> 
                                                    <div className={styles.radioGroupContainer}>
                                                        <span className={styles.chosseOneIcon}><ChooseOneIcon /></span>
                                                        <label
                                                            tabIndex={15}
                                                            className={clsx(`${styles.radioButtonLeft} ${(watch('order_type') === "BID") ? clsx(styles.selected,styles.bidSelected) : ""}`, styles.radioButton, disableBidBuyNow && styles.disableBidBuyNowBtn)}
                                                            onClick={() => {
                                                                if(!disableBidBuyNow){
                                                                    setValue('order_type', 'BID')
                                                                }
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter' && !disableBidBuyNow) {
                                                                    setValue('order_type', 'BID');
                                                                }
                                                            }}
                                                        >
                                                            <input 
                                                                type="radio" 
                                                                {...register("order_type")} 
                                                                value="BID" 
                                                                className={styles.hiddenRadio}
                                                                onChange={(e) => {
                                                                    register('order_type').onChange(e)
                                                                    saveUserActivity()
                                                                }}
                                                                disabled={disableBidBuyNow}
                                                            />
                                                            BID
                                                        </label>
                                                        <label
                                                            tabIndex={16}
                                                            className={clsx(`${styles.radioButtonRight} ${(watch('order_type') === "BUY") ? clsx(styles.selected,styles.buySelected) : ""}`, styles.radioButton, disableBidBuyNow && styles.disableBidBuyNowBtn)}
                                                            onClick={() => {
                                                                if(!disableBidBuyNow){
                                                                    setValue('order_type', 'BUY')
                                                                }
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter' && !disableBidBuyNow) {
                                                                    setValue('order_type', 'BUY');
                                                                }
                                                            }}
                                                        >
                                                            <input 
                                                                type="radio" 
                                                                {...register("order_type")} 
                                                                value="BUY" 
                                                                className={styles.hiddenRadio}
                                                                onChange={(e) => {
                                                                    register('order_type').onChange(e)
                                                                    saveUserActivity()
                                                                }}
                                                                disabled={disableBidBuyNow}
                                                            />
                                                            BUY
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className={styles.formInputGroup1}>
                                                    <ClickAwayListener onClickAway={() => handleDeliveryInfoContainerClickAway()}>
                                                        <div 
                                                        className={`${styles.deliverToContainer} ${isFocused ? styles.boxShadow : ""}`} 
                                                        ref={containerRef} 
                                                        onClick={handleDeliveryToClick} 
                                                        tabIndex={17}
                                                        onFocus={()=>{
                                                            setOpenDeliveryToDialog(true);
                                                            setIsFocused(true)
                                                        }}
                                                        >
                                                            {openDeliveryToDialog ? 
                                                                <span className={styles.deliverToLabel}>
                                                                        <>
                                                                            <InputWrapper>
                                                                                <CustomTextField className={styles.addressInputs} type='text' register={register("shipping_details.line1")}
                                                                                    placeholder='ADDRESS 1'
                                                                                    onBlur={(e) => {
                                                                                        e.target.value = e.target.value.trim();
                                                                                        register("shipping_details.line1").onBlur(e);
                                                                                        saveUserActivity();
                                                                                    }}
                                                                                    onKeyDown={(e)=>{
                                                                                        if( e.key === 'Tab'){
                                                                                            if(e.shiftKey){
                                                                                                const nextElement = document.querySelector('[tabindex="16"]');
                                                                                                if (nextElement instanceof HTMLElement) {
                                                                                                    nextElement.focus();
                                                                                                }
                                                                                                setOpenDeliveryToDialog(false);
                                                                                                setIsFocused(false)
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    autoFocus
                                                                                />
                                                                            </InputWrapper>
                                                                            <InputWrapper>
                                                                                <CustomTextField className={styles.addressInputs} type='text' register={register("shipping_details.line2")}
                                                                                    placeholder='ADDRESS 2'
                                                                                    onBlur={(e) => {
                                                                                        e.target.value = e.target.value.trim();
                                                                                        register("shipping_details.line2").onBlur(e);
                                                                                        saveUserActivity();
                                                                                    }}
                                                                                />
                                                                            </InputWrapper>
                                                                        </>
                                                                    <span className={styles.lastAddressFiled}>
                                                                        <span className={styles.addressInputsCol1} >
                                                                        <InputWrapper>
                                                                        <CustomTextField className={styles.addressInputs} type='text'
                                                                                register={register("shipping_details.city")}
                                                                                placeholder='CITY'
                                                                                onBlur={(e) => {
                                                                                    e.target.value = e.target.value.trim();
                                                                                    register("shipping_details.city").onBlur(e);
                                                                                    saveUserActivity();
                                                                                }}
                                                                            />
                                                                        </InputWrapper>

                                                                        </span>
                                                                    
                                                                        <span className={clsx(styles.addressInputsCol2,errors?.shipping_details?.state_id?.message && styles.errorInput,
                                                                            stateInputFocus && styles.selectShade)}>
                                                                    { stateInputFocus && <>
                                                                        <div className={styles.shape1}></div>
                                                                        <div className={styles.shape2}></div>
                                                                    </>
                                                                    
                                                                        }
                                                                    <StateDropDown
                                                                        states={states}
                                                                        setValue={setValue}
                                                                        stateDropDownValue={stateDropDownValue}
                                                                        setStateDropDownValue={setStateDropDownValue}
                                                                        setStateInputFocus={setStateInputFocus}
                                                                        stateInputFocus={stateInputFocus}
                                                                    />
                                                                        </span>
                                                                        <span className={styles.addressInputsCol3}>
                                                                        <Tooltip
                                                                        title={errors?.shipping_details?.zip?.message}
                                                                        arrow
                                                                        placement={"top-end"}
                                                                        disableInteractive
                                                                        TransitionComponent={Fade}
                                                                        TransitionProps={{ timeout: 200 }}
                                                                        classes={{
                                                                            tooltip: 'stateTooltip',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <InputWrapper>
                                                                                <CustomTextField className={clsx(styles.addressInputs, errors?.shipping_details?.zip?.message && styles.errorInput)} type='text'
                                                                                    register={register("shipping_details.zip")}
                                                                                    placeholder='ZIP'
                                                                                    onChange={(e) => {
                                                                                        register("shipping_details.zip").onChange(e);
                                                                                        const zipCode = e.target.value.replace(/\D/g, '');
                                                                                        setValue("shipping_details.zip", zipCode);
                                                                                    }}
                                                                                    maxLength={5}
                                                                                    onBlur={(e) => {
                                                                                        e.target.value = e.target.value.trim();
                                                                                        register("shipping_details.zip").onBlur(e);
                                                                                        saveUserActivity();
                                                                                    }}
                                                                                    onKeyDown={(e)=>{
                                                                                        if(e.key === 'Tab'){
                                                                                            if(!e.shiftKey){
                                                                                                e.stopPropagation();
                                                                                                e.preventDefault();
                                                                                                const nextElement = document.querySelector('[tabindex="18"]');
                                                                                                if (nextElement instanceof HTMLElement) {
                                                                                                    nextElement.focus();
                                                                                                }
                                                                                                handleDeliveryInfoContainerClickAway()
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    errorInput={errors?.shipping_details?.zip?.message}
                                                                                />
                                                                            </InputWrapper>
                                                                        </div>
                                                                    </Tooltip>
                                                                        </span>
                                                                    
                                                                    </span>
                                                                </span>
                                                            : 
                                                            <span className={styles.deliverToLabel}>
                                                                {
                                                                    (watch('shipping_details.line1') || watch('shipping_details.line2') || (watch('shipping_details.city') || stateDropDownValue || watch('shipping_details.zip'))) ? (  <>
                                                                            <p className={clsx(styles.addressInputs,styles.hideInputBackground)}>{watch('shipping_details.line1') ? `${watch('shipping_details.line1')}` : ''}</p>
                                                                            <p className={clsx(styles.addressInputs,styles.hideInputBackground)}>{watch('shipping_details.line2') ? `${watch('shipping_details.line2')}` : ''}</p>
                                                                            <span className={styles.lastAddressFiled}>
                                                                                <p className={clsx(styles.addressInputsCol1,styles.hideInputBackground)}>{watch('shipping_details.city') ? `${watch('shipping_details.city')}` : ''}</p>
                                                                                <p className={clsx(styles.addressInputsCol2,styles.hideInputBackground)}>{stateDropDownValue ? stateDropDownValue : ''}</p>
                                                                                <p className={clsx(styles.addressInputsCol3,styles.hideInputBackground)}>{watch('shipping_details.zip') ? `${watch('shipping_details.zip')}` : ''}</p>
                                                                            </span>
                                                                    </>):(<span>DELIVER TO</span>)
                                                                }
                                                            
                                                            </span>
                                                            }
                                                                
                                                        </div>
                                                    </ClickAwayListener>
                                                    <div className={styles.uploadBillContainer} tabIndex={18}
                                                        id="upload-bill-of-material"
                                                        onFocus={() => {
                                                            scrollToTop();
                                                        }}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Tab') {
                                                                if (!e.shiftKey) {
                                                                    if (orderInfoIsFilled) {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        const descriptionInput = document.getElementById('combo-box-demo0');//document.querySelector('textarea[id="combo-box-demo"]');
                                                                        if (descriptionInput instanceof HTMLElement) {
                                                                            descriptionInput.focus();
                                                                        }
                                                                    }
                                                                } else {
                                                                    setOpenDeliveryToDialog(true);
                                                                    setIsFocused(true)
                                                                    setTimeout(() => {
                                                                        const descriptionInput = document.querySelector('input[name="shipping_details.line1"]');
                                                                        if (descriptionInput instanceof HTMLElement) {
                                                                            descriptionInput.focus();
                                                                        }
                                                                    }, 100)
                                                                }
                                                            }
                                                            if(e.key === 'Enter' || e.key === ' '){
                                                                navigate(routes.bomUpload);
                                                            }
                                                        }}
                                                        onClick={() => {
                                                           navigate(routes.bomUpload);
                                                        }}
                                                    >
                                                        <div className={styles.uploadIcon}>
                                                            <UploadBOMIcon className={styles.uploadIcon1}/>
                                                            <UploadBOMIconHover className={styles.uploadIcon2}/>
                                                        </div>
                                                        <span className={styles.uploadLabel}>
                                                            UPLOAD BILL<br/>OF MATERIAL
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{overflowY: (!hidePoLineScroll && orderInfoIsFilled) ? 'auto' : 'hidden'}} className={styles.addPoLineTable} onScroll={handleLineItemScroll} ref={addPoLineTableRef} onClick={() => {openAddLineTab()}}>
                                                <table >
                                                    <thead>
                                                        <tr>
                                                            <th><span>LN</span></th>
                                                            <th><span>DESCRIPTION</span></th>
                                                            <th><span>QTY</span></th>
                                                            <th>    
                                                            
                                                                <span>
                                                                    $/UNIT
                                                                    {/* <Tooltip
                                                                        title={chooseYourUnitTooltip()}
                                                                        arrow
                                                                        placement={'bottom'}
                                                                        disableInteractive
                                                                        TransitionComponent={Fade}
                                                                        TransitionProps={{ timeout: 600 }}
                                                                        classes={{
                                                                            tooltip: 'chooseYourUnitTooltip',
                                                                        }}
                                                                    >
                                                                        <label>
                                                                            <QuestionIcon className='questionIcon1' />
                                                                            <QuestionHoverIcon className='questionIcon2' />
                                                                        </label>
                                                                        </Tooltip> */}
                                                                        </span>
                                                                
                                                            </th>
                                                            <th colSpan={2}><span>EXT ($)</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            fields.map((item, index) => (
                                                                <CreatePoTile key={item.id}
                                                                    index={index}
                                                                    register={register}
                                                                    fields={fields}
                                                                    updateValue={updateValue}
                                                                    updateLineProduct={updateLineProduct}
                                                                    products={products}
                                                                    setValue={setValue}
                                                                    watch={watch}
                                                                    errors={errors}
                                                                    control={control}
                                                                    getValues={getValues}
                                                                    pricePerUnitChangeHandler={pricePerUnitChangeHandler}
                                                                    removeLineItem={removeLineItem}
                                                                    item={item}
                                                                    userPartData={userPartData}
                                                                    sessionId={sessionId}
                                                                    selectedProduct={selectedProduct}
                                                                    searchStringData={searchStringData}
                                                                    setSearchString={setSearchString}
                                                                    setLineSessionId={setLineSessionId}
                                                                    lineSessionId={lineSessionId}
                                                                    handleCreatePOSearch={handleCreatePOSearch}
                                                                    setHandleSubmitValidation={setHandleSubmitValidation}
                                                                    handleSalesTax={handleSalesTax}
                                                                    apiCallInProgress={apiCallInProgress}
                                                                    quantitySizeValidator={quantitySizeValidator}
                                                                    saveUserLineActivity={saveUserLineActivity}
                                                                    orderInfoIsFilled={orderInfoIsFilled}
                                                                    setDisableBidBuyNow={setDisableBidBuyNow}
                                                                    openAddLineTab={openAddLineTab}
                                                                    setOpenDeliveryToDialog={setOpenDeliveryToDialog}
                                                                    hidePoLineScroll={hidePoLineScroll}
                                                                    setHidePoLineScroll={setHidePoLineScroll}
                                                                    scrollToTop={scrollToTop}
                                                                />
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            
                                            {/* <CommonTooltip
                                                    title={'Click this + to add more products to your purchase order'}
                                                    tooltiplabel={<>
                                                        <div className={styles.addMoreLine}>
                                                            <button onClick={() => { addNewRow(); }}>
                                                                <AddLineIcon className={styles.addLine} />
                                                                <AddLineHoverIcon className={styles.addLineHover} />
                                                            </button>
                                                            <span></span>
                                                        </div>
                                                    
                                                    </>
                                                        
                                                    }
                                                    placement={'bottom-start'}
                                                    classes={{
                                                        popper: 'tooltipPopper',
                                                        tooltip: 'tooltipMain tooltipSearch tooltipAddRow',
                                                        arrow: 'tooltipArrow'
                                                    }}
                                                    localStorageKey="addMoreLineTooltip"
                                                /> */}
                                            {/* <div className={styles.totalAmt}>
                                                <div className={styles.featureActions}>
                                                    <div>
                                                        {(channelWindow?.fetchPdf || channelWindow?.generatePdf) &&  <PdfMakePage getExportPoData={getExportPoData} buyingPreferenceData={buyingPreference} disabled={disablePlaceOrderButton} getCartItems={getCartItems} />}
                                                    </div>
                                                    {buyingPreference?.bnpl_settings?.is_approved === null && <button className={styles.refreshNetTerm} onClick={initializeCreatePOData}><RefreshNet30Icon /><span>Click to check status (Net 30 Terms)</span></button>}
                                                </div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td><span className={styles.saleTax}>Material Total</span></td>
                                                                <td><span className={styles.saleTax}>$</span></td>
                                                            <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`price`))}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className={styles.saleTax}>
                                                                Sales Tax
                                                                <Tooltip
                                                                    title={SalesTaxTooltip()}
                                                                    arrow
                                                                    placement={'top-end'}
                                                                    disableInteractive
                                                                    TransitionComponent={Fade}
                                                                    TransitionProps={{ timeout: 200 }}
                                                                    classes={{
                                                                        tooltip: 'inputTooltip',
                                                                    }}
                                                                >
                                                                    <span className={styles.questionIcon}>
                                                                        <QuestionIcon className={styles.questionIcon1} />
                                                                        <QuestionHoverIcon className={styles.questionIcon2} />
                                                                    </span>

                                                                </Tooltip>

                                                            </span>
                                                            </td>
                                                            <td><span className={styles.saleTax}>$</span></td>
                                                            <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`sales_tax`))}</span></td>
                                                        </tr>
                                                        <tr className={clsx(watch('payment_method') !== 'ach_credit' && 'displayNone')}>
                                                            <td><span className={styles.saleTax}>Deposit</span></td>
                                                            <td><span className={styles.saleTax}>$</span></td>
                                                            <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`depositAmount`))}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className={styles.totalPurchase}>Total Purchase</span></td>
                                                            <td><span className={styles.totalPurchase}>$</span></td>
                                                            <td><span className={styles.totalPurchase}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`totalPurchase`))}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                            {/* <div className={styles.btnOfCreatePo}>
                                                <div className={styles.selectedpayment}>

                                                <CommonTooltip
                                                    title={'Click this drop down box to select what method of payment you want to use for this order.'}
                                                    tooltiplabel={
                                                        <CustomMenu
                                                            control={control}
                                                            name={'payment_method'}
                                                            placeholder={'Method of Payment'}
                                                            className={styles.selectPaymentMethod}
                                                            MenuProps={{
                                                                classes: {
                                                                    paper: styles.selectPaymentMethodPaper,
                                                                },
                                                            }}
                                                            onOpen={() => setShowPaymentOptionTooltip(false)}
                                                            onClose={() => setShowPaymentOptionTooltip(undefined)}
                                                            items={paymentMethods}
                                                            IconComponent={DropdownIcon}
                                                            onChange={() => saveUserActivity()}
                                                        />
                                                    }
                                                    open={showPaymentOptionTooltip}
                                                    placement={'bottom-start'}
                                                    classes={{
                                                        popper: 'tooltipPopper',
                                                        tooltip: 'tooltipMain tooltipSearch tooltipPayment',
                                                        arrow: 'tooltipArrow'
                                                    }}
                                                    localStorageKey="selectPaymentTooltip"
                                                />

                                                </div>
                                                <button
                                                    onClick={handleSubmit(onSubmit)}
                                                    disabled={disablePlaceOrderButton}
                                                    className={styles.placeOrder}>Place Order</button>
                                            </div> */}
                                        </div>
                                    </>
                                </PseudoScroll>
                                {/* <div className={styles.textOfCreatePo}>
                                    <p>After clicking "Place Order," the next screen will be your order confirmation.</p>
                                    <p>Additionally, you will receive an email confirmation with full order details.</p>
                                </div> */}
                                <div className={styles.backBtnMain}>
                                    <button className={styles.cancelPOGoBack} onClick={() => { setCreatePoData(null); navigate( backNavigation) }} >CANCEL</button>
                                    {(channelWindow?.fetchPdf || channelWindow?.generatePdf) &&  
                                        <PdfMakePage getExportPoData={getExportPoData} buyingPreferenceData={buyingPreference} disabled={disablePlaceOrderButton} getCartItems={getCartItems} />
                                    }
                                    <button className={styles.savePOGoBack} onClick={() => { setCreatePoData(null); navigate(backNavigation) }} disabled>SAVE PO / BUY LATER</button>
                                </div>
                            </div>
                        <Dialog
                            open={openErrorDialog}
                            onClose={(event) => setOpenErrorDialog(false)}
                            transitionDuration={200}
                            hideBackdrop
                            classes={{
                                root: styles.ErrorDialog,
                                paper: styles.dialogContent
                            }}

                        >
                            <p>{errorMessage}</p>
                            <button className={styles.submitBtn} onClick={(event) => {setOpenErrorDialog(false);}}>Ok</button>
                        </Dialog>

                </div>
            </div>
        </div>
    );
}


const CreatePo = () => {
    const navigate = useNavigate();

    const navigateToSettings = () => {
        navigate(routes.buyerSettingPage);
    }

    return (
        <InstantPurchasingWrapper
            navigateToSettings = {navigateToSettings}
        >
            <InstantPurchasing navigateToSettings={navigateToSettings} />
        </InstantPurchasingWrapper>
    )
}
export default CreatePo;
