import React, { useRef, useEffect, useState } from 'react';
import planck from 'planck-js';
import styles from './BallGame.module.scss';
import { ReactComponent as NewBryzos1Logo } from "../../assets/New-images/screen-1-logo.svg";
import { ReactComponent as BIcon } from '../../assets/New-images/B.svg';
import usePostGameScore from 'src/renderer2/hooks/usePostGameScore';

const SCALE = 30; // pixels per meter
const BALL_RADIUS = 0.5; // meters
const BASKET_WIDTH = 1.5;
const BASKET_HEIGHT = 2.2;

export default function Game({ gameScore, bomUploadId }: { gameScore: any, bomUploadId: string }) {


    //const dropSound = new Audio('/sounds/drop.mp3');
    //const shatterSound = new Audio('/sounds/shatter.mp3');

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const worldRef = useRef<planck.World>();
  const basketRef = useRef<planck.Body>();
  const animationRef = useRef<number>();
  const isGameReady = useRef(false);

  const ballImage = new Image();
  ballImage.src = '/asset/circle.png';

  const animBasketImage = new Image();
  animBasketImage.src = '/asset/basket2.png';

  const basketImage = new Image();
  basketImage.src = '/asset/B.svg';



  const [score, setScore] = useState(0);
  const [life, setLife] = useState(3);
  const [showCanvas, setShowCanvas] = useState(false);
  const gameStates = {
      initial: 'initial',
      playing: 'playing',
      gameOver: 'gameOver',
      paused: 'paused'
    }
    const [gameState, setGameState] = useState(gameStates.initial);
    const [highScore, setHighScore] = useState(getLocal('highScore', 0));
    const [highestConsecutiveScore, setHighestConsecutiveScore] = useState(getLocal('highestConsecutiveScore', 0));


    const {
      mutate: saveGameScore,
      data: saveGameScoreData,
      isLoading: isSaveGameScoreLoading,
  } = usePostGameScore();
    const [showBlackBox, setShowBlackBox] = useState(false);
    
    const consecutiveCatchCountRef = useRef(0);
    consecutiveCatchCountRef.current = highestConsecutiveScore;
    const lifeRef = useRef(3);
    lifeRef.current = life;
    const scoreRef = useRef(0);
    const lastBasketPositionRef = useRef<planck.Vec2 | null>(null);
    scoreRef.current = score;

    // useEffect(() => {
    //   if(gameScore){
    //     setHighScore(gameScore.user_highest_score.score);
    //     setHighestConsecutiveScore(gameScore.user_highest_consecutive_catches.consecutive_catches ?? 0);
    //   }
    // }, [gameScore])

    // useEffect(() => {
    //   if(gameState === gameStates.gameOver){
    //     console.log('highScore', highScore)
    //     console.log('highestConsecutiveScore', highestConsecutiveScore)
    //     const payload = {
    //       "data": {
    //           "bom_upload_id": bomUploadId,
    //           "score": highScore,
    //           "consecutive_catches": highestConsecutiveScore
    //       }
    //   }
    //     saveGameScore(payload)
    //   }
    // }, [gameState])

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let gamePaused = false;
    let ballCount = 0;
    let consecutiveCatchCount = 0;
    const blastParticles:any[] = [];
    const meltingBalls:any[] = [];
    const bodiesToRemove = new Set<planck.Body>();
    const width = canvas.width;
    const height = canvas.height;
    const world = planck.World({ gravity: planck.Vec2(0, 9.8) });
    worldRef.current = world;

    // Ground (invisible)
    const ground = world.createBody();
    ground.createFixture(planck.Edge(
        planck.Vec2(0, 0),
        planck.Vec2(0, height / SCALE)
      ),{restitution: 1});
      // Right
      ground.createFixture(planck.Edge(
        planck.Vec2(width / SCALE, 0),
        planck.Vec2(width / SCALE, height / SCALE)
      ),{restitution: 1});
    const groundFixture = ground.createFixture(planck.Edge(planck.Vec2(0, height / SCALE), planck.Vec2(width / SCALE, height / SCALE)),{restitution: 1});
    groundFixture.setUserData('ground');
    let basket:planck.Body;

    const createBasket = (x:number) => {
        // Basket
        const angle = 18 * Math.PI / 180;
        basket = world.createBody({
            type: 'kinematic',
            position: planck.Vec2(x, 26), // adjust to desired position
        });
        
        // Bottom
        const bottomFixture = basket.createFixture(planck.Box(BASKET_WIDTH/2, 0.1, planck.Vec2(0, 0), 0));
        bottomFixture.setUserData('basket-bottom');

        //outer bottom
        const outerBottomFixture = basket.createFixture(planck.Box(.7 + BASKET_WIDTH/2, 0.1, planck.Vec2(0, .45), 0));
        outerBottomFixture.setUserData('basket-wall');

        //oputer left wall
        const outerLeftFixture = basket.createFixture(planck.Box(0.01, 1.3, planck.Vec2(-1.6, -BASKET_HEIGHT/2 + .5), 0));
        outerLeftFixture.setUserData('basket-wall');

        //outer right wall
        const outerRightFixture = basket.createFixture(planck.Box(0.01, 1.3, planck.Vec2(1.6, -BASKET_HEIGHT/2 + .5), 0));
        outerRightFixture.setUserData('basket-wall');

        // Left wall
        const leftFixture = basket.createFixture(planck.Box(0.01, BASKET_HEIGHT/2, planck.Vec2(-BASKET_WIDTH/2-0.2, -BASKET_HEIGHT/2), -angle));
        leftFixture.setUserData('basket-wall');
        // Right wall
        const rightFixture = basket.createFixture(planck.Box(0.01, BASKET_HEIGHT/2, planck.Vec2(BASKET_HEIGHT/2 , -BASKET_HEIGHT/2), angle));
        rightFixture.setUserData('basket-wall');
        
        basketRef.current = basket;
        isGameReady.current = true;
        dropBall();
    }
    //createBasket()

    // Input
    const keys: Record<string, boolean> = {};
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);
    
    function keyUpHandler(e: KeyboardEvent) {
        keys[e.key] = false;
    }
    
    function keyDownHandler(e: KeyboardEvent) {
        keys[e.key] = true; 
        if(e.key === 'Escape') {
            //pauseGame();
            gamePaused = !gamePaused;
            if(gamePaused) {
                pauseGame();
            }else{
                startGame();
            }
        }
    }

    function randomBetween(min: number, max: number): number {
        return Math.random() * (max - min) + min;
    }

    let animBall:planck.Body;
    const createBall = () => {
        animBall = world.createDynamicBody(planck.Vec2(width / SCALE / 2-1.2, 11.2));
        animBall.setGravityScale(-2);
        //animBall.setLinearVelocity(planck.Vec2(randomBetween(-.5, .5), 1).mul(ballSpeed));
        const ballFixture = animBall.createFixture(planck.Circle(BALL_RADIUS*3), { density: 1, restitution: 0.5 });
        ballFixture.setUserData('anim-ball');
    }
    let animBasket:planck.Body;
    let basketWidth:number;
    let basketHeight:number;
    let basketPosition:planck.Vec2;
    const createAnimBasket = () => {
        animBasket = world.createDynamicBody(planck.Vec2(width / SCALE / 2-1.2, 9));
        animBasket.setGravityScale(1);
        animBasket.createFixture(planck.Box(2.5,1, planck.Vec2(0, -.62), 0),{density: 1, restitution:0});
        animBasket.createFixture(planck.Box(2.5,1, planck.Vec2(0, 5.12), 0),{density: 1, restitution: 0});
        animBasket.createFixture(planck.Box(1,2.5, planck.Vec2(2.8, 2.5), 0),{density: 1, restitution: 0});
    }
    if(!isGameReady.current){
      createAnimBasket();
      createBall();
    }

    world.on('pre-solve', (contact) => {
        if(!isGameReady.current){
            contact.setRestitution(0);
        }
      });
    
    let ballSpeed = 6;
    // Drop balls
    const dropBall = () => {
        ballCount++;

        if(ballCount === 5){
            ballSpeed = ballSpeed * 1.1;
            ballCount = 0;
            showBallSpeedMessage();
        }
      const x = Math.random() * (width / SCALE);
      const ball = world.createDynamicBody(planck.Vec2(x, 0));
      ball.setGravityScale(0);
      ball.setLinearVelocity(planck.Vec2(randomBetween(-.5, .5), 1).mul(ballSpeed));
      const ballFixture = ball.createFixture(planck.Circle(BALL_RADIUS), { density: 1, restitution: 0.5 });
      ballFixture.setUserData('ball');
    };

    const looseLife = () => {
        if(consecutiveCatchCount > consecutiveCatchCountRef.current){
            setHighestConsecutiveScore(consecutiveCatchCount);
            setLocal('highestConsecutiveScore', consecutiveCatchCount);
            consecutiveCatchCountRef.current = consecutiveCatchCount;
        }
        consecutiveCatchCount = 0;
      setLife(prevLife => {
        const newLife = prevLife - 1;
        if (basket) {
          lastBasketPositionRef.current = basket.getPosition().clone();
        }
        if(newLife <= 0){
          gameOver();
        }
        return newLife;
      });
    }

    if(lastBasketPositionRef.current){
      createBasket(lastBasketPositionRef.current.x);
      lastBasketPositionRef.current = null;
    }

    // Game loop
    const loop = () => {
      if(gamePaused) {
        animationRef.current = requestAnimationFrame(loop);
        return;
      }
      if(isGameReady.current){
        // Move basket
        const pos = basket.getPosition();
        if (keys['ArrowLeft'] && pos.x - BASKET_WIDTH / 2 - .8 > 0) {
            basket.setLinearVelocity(planck.Vec2(-10, 0));
        } else if (keys['ArrowRight'] && pos.x + BASKET_WIDTH / 2 + .8 < width / SCALE) {
            basket.setLinearVelocity(planck.Vec2(10, 0));
        } else {
          basket.setLinearVelocity(planck.Vec2(0, 0));
        }
      }

      world.step(1 / 60);
      bodiesToRemove.forEach(body =>{ 
        dropBall();
        world.destroyBody(body)
      });
      bodiesToRemove.clear();

      if(!isGameReady.current){
        const ballPos = animBall.getPosition();
        if(ballPos.y  < 0){
          createBasket(animBall.getPosition().x + 11);
            world.destroyBody(animBall);
            world.destroyBody(animBasket);
            setShowBlackBox(true);
        }
      }


      // Check for out-of-bounds balls
    for (let body = world.getBodyList(); body; body = body.getNext()) {
        for (let fixture = body.getFixtureList(); fixture; fixture = fixture.getNext()) {
            if (fixture.getUserData() === 'ball') {
                const pos = body.getPosition();
                if (pos.y * SCALE > canvas.height + 100 || pos.x * SCALE < -100 || pos.x * SCALE > canvas.width + 100) {
                    bodiesToRemove.add(body);
                    looseLife();
                }
            }
        }
    }

      ctx.clearRect(0, 0, width, height);
      // Draw and update blast particles
    for (let i = blastParticles.length - 1; i >= 0; i--) {
        const p = blastParticles[i];
        p.x += p.speedX;
        p.y += p.speedY;
        p.speedY += p.gravity;
        p.rotation += p.rotationSpeed;
        p.lifetime++;
    
        ctx.save();
        ctx.translate(p.x, p.y);
        ctx.rotate(p.rotation);
        ctx.fillStyle = p.color;
        ctx.fillRect(-p.size / 2, -p.size / 2, p.size, p.size);
        ctx.restore();
    
        if (p.lifetime > p.maxLifetime) {
        blastParticles.splice(i, 1); // Remove expired particles
        }
    }

    meltingBalls.forEach(ball => {
        ctx.save();
        ctx.globalAlpha = ball.opacity;
        ctx.translate(ball.x, ball.y);
        ctx.scale(ball.scale, ball.scale);
        const size = BALL_RADIUS * 2 * SCALE;
        ctx.drawImage(
          ballImage,
          -size / 2,
          -size / 2,
          size,
          size
        );
        ctx.restore();
      });
  

      // Draw all bodies
      for (let body = world.getBodyList(); body; body = body.getNext()) {
        for (let fixture = body.getFixtureList(); fixture; fixture = fixture.getNext()) {
          const shape = fixture.getShape();
          const pos = body.getPosition();

          ctx.save();
          ctx.translate(pos.x * SCALE, pos.y * SCALE);
          ctx.rotate(body.getAngle());

          if (shape.getType() === 'circle') {
            // const r = (shape as planck.Circle).getRadius() * SCALE;
            // ctx.beginPath();
            // ctx.arc(0, 0, r, 0, Math.PI * 2);
            // ctx.fillStyle = 'red';
            // ctx.fill();
            const r = (shape as planck.Circle).getRadius() * SCALE;
            const size = r * 2;

            ctx.drawImage(
                ballImage,
                -r, -r,    // center the image
                size, size // match size to radius
            );
          } else if (shape.getType() === 'polygon'&& body === basketRef.current) {
            const width = BASKET_WIDTH * SCALE;
            const height = BASKET_HEIGHT * SCALE;
            if (basketImage.complete) {
                ctx.drawImage(
                basketImage,
                -basketImage.width / 2, -basketImage.height / 2 -24,
                basketImage.width,
                basketImage.height
                );
            } else {
                basketImage.onload = () => {
                ctx.drawImage(
                    basketImage,
                    -basketImage.width / 2, -basketImage.height / 2,
                    basketImage.width,
                    basketImage.height
                );
                };
            }
            
            //debug draw
            // const vertices = (shape as planck.Polygon).m_vertices;
            // ctx.beginPath();
            // vertices.forEach((v, i) => {
            //     const x = v.x * SCALE;
            //     const y = v.y * SCALE;
            //     i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
            // });
            // ctx.closePath();
            // ctx.fillStyle = 'blue';
            // ctx.fill();
            // console.log("sdf");
          }else if(shape.getType() === 'polygon' && body === animBasket){

            if(animBasketImage.complete){
              basketWidth = (basketWidth)?basketWidth*.9985:animBasketImage.width*.9985;
              basketHeight = (basketHeight)?basketHeight*.9985:animBasketImage.height*.9985;  
                ctx.drawImage(
                    animBasketImage,
                    -animBasketImage.width/2 + 60, -animBasketImage.height/2 + 85,
                    basketWidth/1.2,
                    basketHeight/1.2
                );
            } else {
                animBasketImage.onload = () => {
                    ctx.drawImage(
                        animBasketImage,
                        -animBasketImage.width/2 + 60, -animBasketImage.height/2 + 85,
                        animBasketImage.width/1.2,
                        animBasketImage.height/1.2
                    );
                };
            }

            // const vertices = (shape as planck.Polygon).m_vertices;
            // ctx.beginPath();
            // vertices.forEach((v, i) => {
            //     const x = v.x * SCALE;
            //     const y = v.y * SCALE;
            //     i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
            // });
            // ctx.closePath();
            // ctx.fillStyle = 'green';
            // ctx.fill();

          }

          ctx.restore();
        }
      }
      updateMeltingBalls();

      animationRef.current = requestAnimationFrame(loop);
    };
    loop();
    world.on('begin-contact', contact => {
        const fA = contact.getFixtureA();
        const fB = contact.getFixtureB();
        
        const aData = fA.getUserData();
        const bData = fB.getUserData();
        
        if ((aData === 'basket-bottom' && bData === 'ball') ||
            (bData === 'basket-bottom' && aData === 'ball')) {
            const ballBody = aData === 'ball' ? fA.getBody() : fB.getBody();
            bodiesToRemove.add(ballBody);
            const pos = ballBody.getPosition();
            meltingBalls.push({
                x: pos.x * SCALE,
                y: pos.y * SCALE,
                scale: 1,
                opacity: 1,
                meltProgress: 0
            });
            //dropSound.currentTime = 0;
            //dropSound.play();
            consecutiveCatchCount++;
            setScore(prevScore => {
              const newScore = prevScore + 1;
              scoreRef.current = newScore;
              return newScore;
            });
            //dropBall();
        }else if((aData === 'ground' && bData === 'ball') ||
            (bData === 'ground' && aData === 'ball')) {
            const ballBody = aData === 'ball' ? fA.getBody() : fB.getBody();
            createBlastEffect(ballBody);
            bodiesToRemove.add(ballBody);
            looseLife();
            //shatterSound.currentTime = 0;
            //shatterSound.play();
            //dropBall();
        }

        if((aData === 'basket-wall' && bData === 'ball') ||
            (bData === 'basket-wall' && aData === 'ball')){
            const ballBody = aData === 'ball' ? fA.getBody() : fB.getBody();
            ballBody.setGravityScale(1); // activate gravity
          }
    });
      
    //dropBall();
    function updateMeltingBalls() {
        for (let i = meltingBalls.length - 1; i >= 0; i--) {
          const ball = meltingBalls[i];
      
          ball.meltProgress += 0.05;
          ball.opacity = 1 - ball.meltProgress;
          ball.scale = 1 - ball.meltProgress * 0.5;
          ball.y += 2;
      
          if (ball.meltProgress >= 1) {
            meltingBalls.splice(i, 1);
          }
        }
      }
      
    function createBlastEffect(ball: planck.Body) {
        const position = ball.getPosition();
        const canvasY = position.y * SCALE;
      
        const numParticles = 25 + Math.floor(Math.random() * 15);
      
        for (let i = 0; i < numParticles; i++) {
          let angle;
          if (Math.random() > 0.7) {
            angle = (Math.PI * 2 * i) / numParticles;
          } else {
            angle = Math.PI + (Math.random() * Math.PI - Math.PI / 2);
          }
      
          const speed = 3 + Math.random() * 7;
          const size = 2 + Math.random() * 5;
      
          const isHotSpark = Math.random() > 0.8;
          const colors = isHotSpark
            ? ['#ffff88', '#ffffff', '#ffdd44']
            : ['#666666', '#888888', '#aaaaaa', '#cccccc'];
          const color = colors[Math.floor(Math.random() * colors.length)];
      
          const particle = {
            x: position.x * SCALE + (Math.random() - 0.5) * 10,
            y: canvasY - 2,
            size,
            color,
            speedX: Math.cos(angle) * speed * (Math.random() * 0.5 + 0.75),
            speedY: Math.sin(angle) * speed - (isHotSpark ? 6 : 4),
            gravity: 0.3,
            lifetime: 0,
            maxLifetime: isHotSpark
              ? 10 + Math.random() * 10
              : 20 + Math.random() * 25,
            rotation: Math.random() * Math.PI * 2,
            rotationSpeed: (Math.random() - 0.5) * 0.3,
            isHotSpark,
          };
      
          blastParticles.push(particle);
        }
      }
      
    const handleBlur = () => {
        // Pause game here
        gamePaused = true;
        pauseGame();
    };
    window.addEventListener('blur', handleBlur);

    return () => {
        window.removeEventListener('keydown', keyDownHandler);
        window.removeEventListener('keyup', keyUpHandler);
        window.removeEventListener('blur', handleBlur);
        if(animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
    };
  }, [showCanvas]);

  const startGame = () => {
    if(gameState === gameStates.initial){
        setScore(0);
        setLife(3);
    }
    setShowCanvas(true);
    setGameState(gameStates.playing)
  }

  const restartGame = () => {
    isGameReady.current = true;
    setScore(0);
    setLife(3);
    setShowCanvas(true);
    setGameState(gameStates.playing)
  }
  const pauseGame = () => {
    setShowCanvas(true);
    setGameState(gameStates.paused)
  }

  const gameOver = () => {
    setShowCanvas(false);
    setGameState(gameStates.gameOver)
    const currentScore = scoreRef.current;
    if(highScore < currentScore){
      setHighScore(currentScore);
      setLocal('highScore', currentScore);
      showMessage('You have beaten your high score!', 3000);
    }
    setTimeout(() => {
      restartGame();
    }, 0);
  }
  const [showMessageFlag, setShowMessageFlag] = useState(false);
  const [message, setMessage] = useState('');


  const showBallSpeedMessage = () => {
    showMessage('Ball speed increased!', 3000);
  }

  const showMessage = (message: string, duration: number = 2000) => {
    setMessage(message);
    setTimeout(() => {
      setMessage('');
    }, duration);
  }

  function setLocal(key: string, value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn('Could not store value in localStorage', e);
    }
  }
  
  function getLocal<T = any>(key: string, defaultValue: T): T {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (e) {
      console.warn('Could not read value from localStorage', e);
      return defaultValue;
    }
  }
  

  return (
    <div>
      {showCanvas ? (
        <>
          <canvas ref={canvasRef} width={800} height={880}/>
          {gameState === gameStates.paused && (
            <div className={styles.initialPage} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              <span className={styles.textTop}>GAME PAUSED, PRESS ESC TO RESUME</span>
            </div>
          )}
          {showMessageFlag && (
            <div className={styles.initialPage} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              <span className={styles.textTop}>{message}</span>
            </div>
          )}
          {showBlackBox && <div className={styles.lastblackBox}></div>}
        </>
      ) : (
        <div>
          {gameState === gameStates.initial &&
            <div className={styles.initialPage}>
            <span className={styles.textTop}>HOW ABOUT A GAME OF <br />
              B-BALL WHILE YOU WAIT ?</span>
            
            <span className={styles.imgCenter} onDoubleClick={startGame}><NewBryzos1Logo /></span>
            <span className={styles.textBottom}>SCORE TO BEAT: {highScore}<br />
              {highestConsecutiveScore} CONSECUTIVE CATCHES</span>
            <button onClick={startGame}>START</button>
          </div>
          }
          {/* {gameState === gameStates.gameOver && 
          <div className={styles.initialPage}>
            <span className={styles.textTop}>GAME OVER </span>
            
            <span className={styles.imgCenter} onDoubleClick={startGame}><NewBryzos1Logo /></span>
            <span className={styles.textBottom}>SCORE TO BEAT: {highScore}<br />
              {highestConsecutiveScore} CONSECUTIVE CATCHES</span>
            <button onClick={startGame}>START AGAIN</button>
        </div>} */}
        </div>
      )}
      
      <div className={styles.scoreContainer}>
      <span className={styles.score}>
        Score: <span>{score}</span>
      </span>
      <span>|</span>
      <span className={styles.live} style={{right: 24*(3 -life) + 20}}>Lives:{' '}
        <span>
            {Array.from({ length: life }).map((_, i) => (
                <BIcon key={i} className="my-icon" style={{ width: '20px', height: '20px', marginRight: '4px' }} />
            ))}
        </span>
      </span>
      </div>
    </div>
  );
}
