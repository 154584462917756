import React, { useEffect, useState } from 'react';
import './OrderSizeFilter.scss';
import { Slider } from '@mui/material';
import { useSearchStore } from '../../store/SearchStore';
import { formatCurrencyWithComma } from '@bryzos/giss-ui-library';


const OrderSizeFilter = ({setOrderSizeSaveDefault}) => {
  const { setOrderSizeSliderValue } = useSearchStore();
  const majorPoints = [500, 1500, 2500, 5000, 10000, 20000];
  const minorLinesCount = 3; // Three minor lines between each major point
  const [pointValue, setPointValue] = useState<number[]>([]);
  const [allMarks, setAllMarks] = useState<{ value: number; label?: string; type: string }[]>([]);
  const [sliderSteps, setSliderSteps] = useState(0);

  useEffect(() => {
    const _pointValue = [majorPoints[0]];
    const _allMark: { value: number; label?: string; type: string }[] = [
      { value: 0, label: `${majorPoints[0]}`, type: 'major' }
    ];

    for (let index = 1; index < majorPoints.length; index++) {
      let previousPointer = majorPoints[index - 1];
      const currentPointer = majorPoints[index];
      const differenceBetweenMajorPoints = currentPointer - previousPointer;

      for (let j = 1; j <= minorLinesCount; j++) {
        previousPointer += differenceBetweenMajorPoints / (minorLinesCount + 1);
        _pointValue.push(previousPointer);

        // The middle minor line should be slightly larger
        const markType = j === Math.ceil(minorLinesCount / 2) ? 'middle-minor' : 'minor';
        _allMark.push({ value: _pointValue.length - 1, type: markType });
      }

      _pointValue.push(currentPointer);
      _allMark.push({ value: _pointValue.length - 1, label: `${formatCurrencyWithComma(String(currentPointer))}`, type: 'major' });
    }

    setPointValue(_pointValue);
    setAllMarks(_allMark);
  }, []);

  useEffect(() => {
    setOrderSizeSliderValue(pointValue[sliderSteps]);
  }, [sliderSteps, setOrderSizeSliderValue, pointValue]);

  return (
    <div className="vertical-ruler-container">
      {/* Ruler Line */}
      <div className="ruler-line"></div>

      {/* Ruler Marks */}
      <div className="ruler-marks">
        {allMarks.map((mark, index) => (
          <div key={index} className={`ruler-mark ${mark.type}-mark`}>
            <div className={`mark-line ${mark.type}-line ${sliderSteps === index ? 'active-mark' : ''}`}></div>
            {mark.label && <span className={`mark-label ${sliderSteps === index ? 'active-mark-label' : ''}`}>{mark.label}</span>}
          </div>
        ))}
      </div>

      <Slider
        value={sliderSteps}
        onChange={(e, newValue) => {setSliderSteps(newValue as number); setOrderSizeSaveDefault(true)}}
        min={0}
        max={pointValue.length - 1}
        step={1}
        className="vertical-slider"
        orientation="vertical"
        marks={allMarks.map(mark => ({
          value: mark.value,
          label: mark.type === 'major' ? mark.label : undefined, 
        }))}
        valueLabelFormat={(value) => `${pointValue[value]}`}
        sx={{
          '& .MuiSlider-thumb': {
            transition: 'top 0.3s ease-in-out',  // Smooth movement
          },
          '& .MuiSlider-track': {
            transition: 'top 0.3s ease-in-out',
          },
          '& .MuiSlider-mark': {
            transition: 'opacity 0.3s ease-in-out',
          },
        }}
      />
    </div>
  );
};

export default OrderSizeFilter;
