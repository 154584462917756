import React, { useState, useEffect } from 'react';
import styles from './Calendar.module.scss'
import dayjs from 'dayjs';
import { ClickAwayListener } from '@mui/material';
import { dateTimeFormat } from '@bryzos/giss-ui-library';

interface CalendarProps {
    onDateSelect?: (date: Date) => void;
    allowedDates?: Date[]; // Array of allowed dates
    setSelectedDate?: (date: string) => void;
    selectedDate?: Date | string;
}

const Calendar: any = ({ onDateSelect, allowedDates = [], setValue, value , isCalendarOpen, setIsCalendarOpen, disableDeliveryDate, handleOpenCalendar, saveUserActivity }: any) => {
    const [displayDate, setDisplayDate] = useState(new Date());
    const [currentIndex, setCurrentIndex] = useState<number>(-1);

    const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const ROWS = 5;
    const COLS = 7;
    const TOTAL_CELLS = ROWS * COLS;

    // Initialize with first allowed date
    useEffect(() => {
        if (allowedDates.length > 0) {
            const days = getCalendarDays();
            const firstAllowedIndex = days.findIndex(date => isDateAllowed(date));
            if (firstAllowedIndex !== -1) {
                setCurrentIndex(firstAllowedIndex);
            }
        }
    }, [allowedDates]);

    const getCalendarDays = () => {
        const today = new Date();
        const currentWeekDay = today.getDay();
        const result: Date[] = [];

        // Start from the beginning of current week
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - currentWeekDay);

        // Add current week (7 days)
        for (let i = 0; i < 7; i++) {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() + i);
            result.push(date);
        }

        // Get the first day after current week
        const nextWeekStart = new Date(startDate);
        nextWeekStart.setDate(startDate.getDate() + 7);

        // Calculate remaining days needed (4 more rows = 28 days)
        const remainingDays = 28;

        for (let i = 0; i < remainingDays; i++) {
            const date = new Date(nextWeekStart);
            date.setDate(nextWeekStart.getDate() + i);
            result.push(date);
        }

        return result;
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const days = getCalendarDays();
        let newIndex = currentIndex;

        switch (e.key) {
            case 'ArrowLeft':
                if (currentIndex === -1) {
                    newIndex = days.findIndex(date => isDateAllowed(date));
                } else {
                    do {
                        newIndex = (newIndex - 1 + TOTAL_CELLS) % TOTAL_CELLS;
                    } while (!isDateAllowed(days[newIndex]) && newIndex !== currentIndex);
                }
                break;

            case 'ArrowRight':
                if (currentIndex === -1) {
                    newIndex = days.findIndex(date => isDateAllowed(date));
                } else {
                    do {
                        newIndex = (newIndex + 1) % TOTAL_CELLS;
                    } while (!isDateAllowed(days[newIndex]) && newIndex !== currentIndex);
                }
                break;

            case 'ArrowUp':
                if (currentIndex === -1) {
                    newIndex = days.findIndex(date => isDateAllowed(date));
                } else {
                    do {
                        newIndex = (newIndex - COLS + TOTAL_CELLS) % TOTAL_CELLS;
                    } while (!isDateAllowed(days[newIndex]) && newIndex !== currentIndex);
                }
                break;

            case 'ArrowDown':
                if (currentIndex === -1) {
                    newIndex = days.findIndex(date => isDateAllowed(date));
                } else {
                    do {
                        newIndex = (newIndex + COLS) % TOTAL_CELLS;
                    } while (!isDateAllowed(days[newIndex]) && newIndex !== currentIndex);
                }
                break;

            case 'Enter':
            case ' ':
            case 'Tab':
                if (currentIndex !== -1 && isDateAllowed(days[currentIndex])) {
                    handleDateSelect(e, days[currentIndex]);
                }
                return;

            default:
                return;
        }

        if (newIndex !== currentIndex && isDateAllowed(days[newIndex])) {
            setCurrentIndex(newIndex);
            setIsCalendarOpen(true);
        }
    };

    const handleDateSelect = (e = null, date: Date) => {
        if (isDateAllowed(date)) {
            // Format date as M/D/YY before setting
            const formattedDate = dayjs(date).format(dateTimeFormat.dateSeparateWithSlashAndDaySingleDigit);
            setValue('delivery_date', formattedDate);
            setDisplayDate(date);
            if (onDateSelect) {
                onDateSelect(date);
            }
            // Reset currentIndex to match the selected date's index
            const days = getCalendarDays();
            const selectedIndex = days.findIndex(d =>
                d.getDate() === date.getDate() &&
                d.getMonth() === date.getMonth() &&
                d.getFullYear() === date.getFullYear()
            );
            setCurrentIndex(selectedIndex);
            if ((e?.key === 'Tab' || e?.key === 'Enter') && isCalendarOpen) {
                e?.preventDefault();
                setIsCalendarOpen(false);
                const nextElement = document.querySelector('[tabindex="15"]');
                if (nextElement instanceof HTMLElement) {
                    nextElement.focus();
                }
                if(e?.shiftKey){
                    const nextElement = document.querySelector('[tabindex="13"]');
                    if (nextElement instanceof HTMLElement) {
                        nextElement.focus();
                    }
                }
            }
            setIsCalendarOpen(false);
            saveUserActivity()
        }
    };
    const isDateAllowed = (date: Date) => {
        if (allowedDates.length === 0) return true;

        return allowedDates.some(allowedDate =>
            allowedDate.getDate() === date.getDate() &&
            allowedDate.getMonth() === date.getMonth() &&
            allowedDate.getFullYear() === date.getFullYear()
        );
    };

    const isToday = (date: Date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const isCurrentMonth = (date: Date) => {
        const today = new Date();
        return date.getMonth() === today.getMonth();
    };

    const isNextMonth = (date: Date) => {
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        return date.getMonth() === nextMonth.getMonth();
    };

    const isDateSelected = (date: Date) => {
        if (!value) return false;

        // Handle string date format 'M/D/YY'
        const selectedDate = typeof value === 'string'
            ? dayjs(value, dateTimeFormat.dateSeparateWithSlashAndDaySingleDigit).toDate()
            : value;

        return date.getDate() === selectedDate.getDate() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getFullYear() === selectedDate.getFullYear();
    };

    const handleClickAway = () => {
        if (value) {
            // Reset currentIndex to match the value prop
            const days = getCalendarDays();
            const selectedDate = typeof value === 'string'
                ? dayjs(value, dateTimeFormat.dateSeparateWithSlashAndDaySingleDigit).toDate()
                : value;

            const selectedIndex = days.findIndex(date =>
                date.getDate() === selectedDate.getDate() &&
                date.getMonth() === selectedDate.getMonth() &&
                date.getFullYear() === selectedDate.getFullYear()
            );

            if (selectedIndex !== -1) {
                setCurrentIndex(selectedIndex);
            }
        } else if (allowedDates.length > 0) {
            // If no value, reset to first allowed date
            const days = getCalendarDays();
            const firstAllowedIndex = days.findIndex(date => isDateAllowed(date));
            if (firstAllowedIndex !== -1) {
                setCurrentIndex(firstAllowedIndex);
            }
        }
        setIsCalendarOpen(false)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.calendarWrapper} onFocus={() => handleOpenCalendar()}>
                {value ? (
                    <button
                        type="button"
                        className={styles.deliverByButton1}
                        onClick={() => handleOpenCalendar()}
                        onKeyDown={handleKeyDown}
                        tabIndex={14}
                    >
                        <span className={styles.leftSideSpan}>
                            {dayjs(value).format('ddd')}
                        </span>
                        <span className={styles.rightSideSpan}>
                            {dayjs(value).format('MMMM DD, YYYY')}
                        </span>
                    </button>
                ) : (
                    <button
                        type="button"
                        className={styles.deliverByButton2}
                        onClick={() => handleOpenCalendar()}
                        onKeyDown={handleKeyDown}
                        tabIndex={14}
                    >
                        DELIVER BY
                    </button>
                )}

                {(isCalendarOpen && !disableDeliveryDate) && (
                    <div className={styles.calendarContainer}>
                        <div className={styles.calendarHeader}>
                            <h2 className={styles.monthYear}>
                                {displayDate.toLocaleDateString('en-US', { month: 'long' })}
                            </h2>
                        </div>

                        <div className={styles.weekdaysContainer}>
                            {weekDays.map((day,index) => (
                                <div key={index} className={styles.weekday}>{day}</div>
                            ))}
                        </div>

                        <div className={styles.daysGrid}>
                            {getCalendarDays().map((date, index) => (
                                <button
                                    key={index}
                                    className={`${styles.dayCell} 
                                        ${!isCurrentMonth(date) && !isNextMonth(date) ? styles.otherMonth : ''}
                                        ${!isDateAllowed(date) ? styles.disabled : ''}
                                        ${index === currentIndex  ? styles.focused : ''}
                                        ${isDateSelected(date) ? styles.selected : ''}
                                        ${isToday(date) ? styles.today : ''}
                                        `}
                                    onClick={() => handleDateSelect(null,date)}
                                    disabled={!isDateAllowed(date)}
                                >
                                    {date.getDate()}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>

    );
};

export default Calendar; 