import { getChannelWindow } from '@bryzos/giss-ui-library';
import { useElementSize } from '@mantine/hooks';
import { useEffect } from 'react';
import { routes } from 'src/renderer2/common';
export const useHeightListener = () => {
    const { ref, height, width } = useElementSize();
    const channelWindow:any =  getChannelWindow() ;
    const minHeight = 102;
    const minWidth = 800;
    useEffect(() => {
        const newHeight = ref?.current.offsetHeight <= minHeight ? minHeight : ref?.current.offsetHeight;
        const newWidth = ref?.current.offsetWidth !== 0 ? ref?.current.offsetWidth : minWidth;
        if(channelWindow?.updateHeight && location.pathname !== routes.newUpdate)
            (window as any).electron.send({ channel: channelWindow.updateHeight, data: {height: newHeight, width: newWidth}})
    }, [height, width]);
    return ref
}
