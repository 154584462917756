import { useRef, useState } from "react";
import { ReactComponent as ShowPassIcon } from '../../assets/New-images/icon-eye-show.svg';
import { ReactComponent as ShowPassIconHover } from '../../assets/New-images/icon-eye-show-hover.svg';
import { ReactComponent as HidePassIcon } from '../../assets/New-images/icon-eye-hide.svg';
import { ReactComponent as HidePassIconHover } from '../../assets/New-images/icon-eye-hide-hover.svg';
import styles from './CustomPasswordField.module.scss'
import clsx from "clsx";
import { ClickAwayListener } from "@mui/material";
import CustomTextField from "../CustomTextField";
import { ClassNames } from "@emotion/react";


type CustomPasswordFieldProps = {
    register: any,
    onFocus?: Function,
    onChange?: Function,
    placeholder: string,
    onBlur?: Function,
    isConfirmPassword?: boolean,
    targetText?: string,
    currentText?: string,
    errorInput?:any,
    className?:any
}


const CustomPasswordField: React.FC<CustomPasswordFieldProps>  = ({ register = {}, placeholder, onChange, isConfirmPassword = false, targetText = '', currentText = '', onBlur, onFocus , className, errorInput}) => {
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [showPasswordMatch, setShowPasswordMatch] = useState(false);
    const inputPasswordRef = useRef(null);
    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };


    const handlePasswordMatchClick = () => {
        setShowPasswordMatch(false)
        setTimeout(() => {
            inputPasswordRef.current.focus();
        }, 100);

    }
    const handleClickAway = () => {
        if (isConfirmPassword && currentText.length) {
            setShowPasswordMatch(true);
        }
    }

    const handleOnFocus = (e) => {
        setShowPasswordMatch(false); 
        if(onFocus) onFocus(e);
    }

    const handleOnChange = (e) => {
        if(onChange) onChange(e)
    }

    const handleOnBlur = (e) => {
        if(onBlur) onBlur(e)
    }

    const handleInputRef = (e) => {
        inputPasswordRef.current = e; register.ref(e);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={clsx(styles.togglePassWrapper, styles.togglePassWrapperMain, errorInput && styles.inputErrorPass )}>
                    {(!showPasswordMatch || !passwordVisibility) ?
                        <>
                            <CustomTextField
                                type={passwordVisibility ? 'password' : 'text'}
                                register={register}
                                placeholder={placeholder}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                onFocus={handleOnFocus}
                                inputRef={handleInputRef}
                                className={styles.inputPass}
                            />
                        </>
                        :
                        <>
                            <div className={styles.passwordMatchContainer} onClick={() => { handlePasswordMatchClick() }}>
                                {(currentText.split(''))?.map((char, i) => {
                                    return (
                                        <div key={i} className={clsx(styles.passwordMatch, (!targetText || i > targetText.length - 1) ? styles.extraChar : char === targetText[i] ? styles.match : styles.mismatch)} />
                                    );
                                })}
                            </div>
                        </>
                    }
                    <button className={styles.showPassBtn} onClick={togglePasswordVisibility}>
                        {passwordVisibility ? 
                            <span className={styles.eyeIcon}><ShowPassIcon className={styles.img1} /> <ShowPassIconHover className={styles.img2} /></span>
                        : 
                           <span className={styles.eyeIcon}><HidePassIcon className={styles.img1} /> <HidePassIconHover className={styles.img2} /></span>
                        }
                    </button>
                </div>
        </ClickAwayListener>
    )
}

export default CustomPasswordField;