// @ts-nocheck
import { encryptData, getChannelWindow, snackbarSeverityType, useGlobalStore } from "@bryzos/giss-ui-library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import axios from "axios";
import { AuthError, reactQueryKeys } from "src/renderer2/common";
import useSaveLoginActivityAnalytics from "./useSaveLoginActivityAnalytics";
import { handleConsoleError, getUserAppData, stringifyError } from "../helper";
import useSnackbarStore from "../component/Snackbar/snackbarStore";

const authSignIn = async(payload) => {
  const store = useGlobalStore.getState();
  try{
    return await Auth.signIn(payload?.email, payload?.password)
  }catch (error) {
    if (error.code === 'ERR_NETWORK_CHANGED' || error.message.includes('Network')) {
      store.setApiFailureDueToNoInternet(true);
    } else {
      throw error;
    }

  }
}

const useOnSubmitLogin = (setError) => {
  const store = useGlobalStore.getState();
  const saveLoginActivityAnalytics = useSaveLoginActivityAnalytics()
  const query = useQueryClient()
  const {showToastSnackbar, resetSnackbarStore} = useSnackbarStore();
  return useMutation(async (payload) => {
    try {
      const channelWindow = getChannelWindow();
      const user = await authSignIn(payload);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(user, payload?.password);
      }
      if (channelWindow?.setLoginCredential) {
        const _encryptCredential = await encryptData(JSON.stringify(payload), store.decryptionEntity.decryption_key);
        window.electron.send({ channel: channelWindow.setLoginCredential, data: _encryptCredential });
      }
      store.setIsUserLoggedIn(true);
      store.setCurrentAccessToken(user?.signInUserSession.accessToken.jwtToken);
      store.setStartLoginProcess(true);
      query.invalidateQueries([reactQueryKeys.cognitoUser]);
    } catch (error) {
      const userAppData = getUserAppData()
      const data = {
      ...userAppData, reason:{error:stringifyError(error), isManualLogin:store.isManualLogin}, email:payload.email
      }
      saveLoginActivityAnalytics.mutate(data)
      console.error('useOnSubmitLogin ', error)
      if (error.name === 'NotAuthorizedException'){
        if(error?.message === "Password attempts exceeded"){
          showToastSnackbar("Password attempts exceeded", snackbarSeverityType.alert, null, resetSnackbarStore, 3000);
        }else{
          setError(AuthError.PasswordMismatch)
        }
      }
      store.setShowLoader(false);
    } finally {
      store.setSignupUser(null);
    }
  },
    {
      retry: 4,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 0
    }
  );
};

export default useOnSubmitLogin;
