const getQuantityGroup = (qty:number):string=>{
    if (qty >= 1 && qty <  1500) return "qtyGrp0";
    if (qty >= 1500 && qty < 2500) return "qtyGrp1";
    if (qty >= 2500 && qty < 5000) return "qtyGrp2";
    if (qty >= 5000 && qty < 10000) return "qtyGrp3";
    return "qtyGrp4";
  }


  const getRegionByZip = (zipCode: number):string => {
    if (zipCode >= 10000 && zipCode < 20000) return "reg0";
    if (zipCode >= 20000 && zipCode < 30000) return "reg1";
    if (zipCode >= 30000 && zipCode < 40000) return "reg2";
    if (zipCode >= 40000 && zipCode < 50000) return "reg3";
    if (zipCode >= 50000 && zipCode < 60000) return "reg4";
    if (zipCode >= 60000 && zipCode < 70000) return "reg5";
    if (zipCode >= 70000 && zipCode < 80000) return "reg6";
    if (zipCode >= 80000 && zipCode < 90000) return "reg7";
    if (zipCode >= 90000 && zipCode < 95000) return "reg8";
    return "reg9"; // Default for ZIPs 95000 and above
  }

  export {
    getRegionByZip,
    getQuantityGroup
  }