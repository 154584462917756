import { keyDown, keyEnter, keyUp, MinSearchDataLen, ReferenceDataProduct, useGlobalStore, dataOpticsApi1, referenceProductItem, userRole } from '@bryzos/giss-ui-library';
import React, { useEffect, useRef, useState } from 'react'
import ProductDescription from '../../../component/ProductDescription/ProductDescription';
import { useSearchStore } from '../../../store/SearchStore';

import styles from '../home.module.scss'
import clsx from 'clsx';
import { HttpRequestPayload, ProductPricingModel, SearchAnalyticDataModel } from 'src/renderer2/types/Search'
import { v4 as uuidv4 } from 'uuid';

const SearchResultPanel: React.FC = () => {
    const { productData }: any = useGlobalStore();
    const { searchByProductResult, filterSearchByProductResult, searchString } = useSearchStore();
    const containerRef = useRef<HTMLDivElement>(null);
    const selectedProductRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const { sessionId, selectedDomesticOption, shortListedSearchProductsData, selectedPriceUnit,  setEnableRejectSearchAnalytic, setSearchByProductResult, setSearchString, setShortListedSearchProductsData,setFilterShortListedSearchProductsData } = useSearchStore();


    const selectProductFromSearch = (product: ReferenceDataProduct) => {
        setEnableRejectSearchAnalytic(false)
        selectProduct(product, sessionId);
        setSearchByProductResult([]);
        setSearchString('');
    }
    
    const selectProduct = (
        product: ReferenceDataProduct,
        sessionId: string
    ) => {
        // 
        const isDuplicate = shortListedSearchProductsData.some((data) => data.id === product.Product_ID);
        if (!isDuplicate) {
            const store: any = useGlobalStore.getState();
            const { userData } = store;
            const productWithPrice = getFormattedProductPricing(product, userData.data.type);
            const _selectedProducts = [productWithPrice, ...shortListedSearchProductsData];
            setShortListedSearchProductsData(_selectedProducts);

            let filteredSelectedProductSearchData = [..._selectedProducts];
            if (selectedDomesticOption) {
                filteredSelectedProductSearchData = _selectedProducts.filter((selectedProduct) => selectedProduct.domestic_material_only);
            }
            setFilterShortListedSearchProductsData(filteredSelectedProductSearchData);

            const dataOpticsPayload: HttpRequestPayload<SearchAnalyticDataModel[]> = {
                "data": [{
                    "session_id": sessionId,
                    "line_session_id": productWithPrice.line_session_id,
                    "product_id": productWithPrice.id,
                    "description": productWithPrice.UI_Description,
                    "price_shared": false,
                    "price_share_unit": selectedPriceUnit,
                    "search_price_unit": selectedPriceUnit
                }]
            }
            dataOpticsApi1(dataOpticsPayload);
        }
    }


    const getFormattedProductPricing = (product: ReferenceDataProduct, _userRole: string) => {
        const lineSessionId = uuidv4();
        let cwt_price = product.Buyer_Pricing_CWT;
        let lb_price = product.Buyer_Pricing_LB;
        let ft_price = product.Buyer_Pricing_Ft;
        let ea_price = product.Buyer_Pricing_Ea;
        let net_ton_price = product.Buyer_Pricing_Net_Ton;
        if (_userRole === userRole.sellerUser) {
            cwt_price = product.Seller_Pricing_CWT;
            lb_price = product.Seller_Pricing_LB;
            ft_price = product.Seller_Pricing_Ft;
            ea_price = product.Seller_Pricing_Ea;
            net_ton_price = product.Seller_Pricing_Net_Ton;
        } else if (_userRole === userRole.neutralUser) {
            cwt_price = product.Neutral_Pricing_CWT;
            lb_price = product.Neutral_Pricing_LB;
            ft_price = product.Neutral_Pricing_Ft;
            ea_price = product.Neutral_Pricing_Ea;
            net_ton_price = product.Neutral_Pricing_Net_Ton;
        }
        const productWithPrice: ProductPricingModel = {
            "id": product.Product_ID,
            "UI_Description": product.UI_Description,
            "cwt_price": cwt_price,
            "ft_price": ft_price,
            "lb_price": lb_price,
            "ea_price": ea_price,
            "net_ton_price": net_ton_price,
            "product_type_pipe": product?.Key2 ? product.Key2 === referenceProductItem.pipe : false,
            "line_session_id": lineSessionId,
            "is_safe_product_code": product.is_safe_product_code,
            "domestic_material_only": product.domestic_material_only
        }
        return productWithPrice;
    }

    useEffect(() => {
        const handleSearchKeyDown = (event: KeyboardEvent) => {
            const startIndex = 0;
            const endIndex = searchByProductResult.length - 1;
            if (event.key === keyUp && selectedIndex > startIndex) {
                setSelectedIndex(selectedIndex - 1)
            } else if (event.key === keyDown && selectedIndex < endIndex) {
                setSelectedIndex(selectedIndex + 1)
            } else if ((event.key === keyEnter || event.key === 'Tab') && selectedIndex >= startIndex) {
                setSelectedIndex(-1);
                const element: any = selectedProductRef.current;
                const selectedProductId = element.getAttribute('data-value');
                selectProductFromSearch(productData[selectedProductId - 1]);
            }
        }
        document.addEventListener('keydown', handleSearchKeyDown);
        return () => {
            document.removeEventListener('keydown', handleSearchKeyDown);
        };
    }, [selectedIndex, searchByProductResult])

    useEffect(() => {
        const container = containerRef.current;
        const selectedItem: any = selectedProductRef.current;
        if (container && selectedProductRef && selectedItem) {
            const itemTop = selectedItem.offsetTop - 140;
            const itemHeight = selectedItem.offsetHeight + 40;
            const containerHeight = container.offsetHeight;
            const scrollOffset = container.scrollTop;
            if (itemTop < scrollOffset) {
                container.scrollTop = itemTop - 50;
            } else if (itemTop + itemHeight > scrollOffset + containerHeight) {
                container.scrollTop = itemTop + itemHeight - containerHeight - 90;
            }
        }
    }, [selectedIndex]);


    useEffect(() => {
        animateProductSearchContainer();
    }, [filterSearchByProductResult])

    const animateProductSearchContainer = () => {
        const container = document.getElementById('search-container');
        const padding = 16;
        if (container) {
            if (searchString.length < MinSearchDataLen) {
                container.style.maxHeight = '0px';
                return;
            }
            let result;
            if (filterSearchByProductResult.length > 0) {
                result = document.getElementById('search-result');
            }
            else {
                result = document.getElementById('no-result-search');
            }
            if (result) {
                const resultHeight = Number(result.offsetHeight);
                container.style.maxHeight = resultHeight > 542 ? `${542}px` : `${resultHeight + padding}px`;
            }
        }
    }

    return (
        <>
            {searchString.length > 1 &&
                <div className={clsx(styles.searchProductDescMain, ( selectedProductRef ) &&  styles.padding0)} ref={containerRef} id='search-container'>
                    {searchString.length > 0 && <div id='search-result' className={styles.searchResult}>
                        {(filterSearchByProductResult.length > 0) ?
                            filterSearchByProductResult.map((product: ReferenceDataProduct, index: number) => (
                                <div key={product.id} className={clsx(styles.searchProductDescription, index === selectedIndex && styles.highlightSearchProductDescription)}
                                    ref={index === selectedIndex ? selectedProductRef : null}
                                    data-value={product.id}
                                    onClick={() => selectProductFromSearch(product)}>
                                    <ProductDescription product={product} />
                                </div>
                            ))
                            :
                            <div className={styles.NoResultsToDisplayText1} id="no-result-search">
                                <p className={styles.marginBottom10}>NO RESULTS TO DISPLAY</p>
                            </div>
                        }
                    </div>}
                </div>
            }
        </>
    )
}

export default SearchResultPanel